import { Description, Image, MusicNote, Movie, InsertDriveFile } from "@mui/icons-material";


const getFileIcon = (filename: string) => {
    const fileExtension = filename.split(".").pop()?.toLowerCase();

    switch (fileExtension) {
        case "jpg":
        case "jpeg":
        case "png":
        case "gif":
            return <Image />;
        case "mp3":
        case "wav":
        case "ogg":
            return <MusicNote />;
        case "mp4":
        case "avi":
        case "mkv":
            return <Movie />;
        case "pdf":
        case "doc":
        case "docx":
        case "xls":
        case "xlsx":
            return <Description />;
        default:
            return <InsertDriveFile />;
    }
};

export default getFileIcon;
