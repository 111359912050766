import {
    GridColumnVisibilityModel,
    GridFooterContainer,
    GridSelectedRowCount,
    GridSelectionModel, GridValidRowModel,
} from '@mui/x-data-grid';
import React, { useRef, useState } from "react";
import { TableColumn, TableGroupAction } from './table.model';
import { ClickAwayListener, Popper } from "@mui/material";
import useTranslation from '../../../../hooks/translation.hook';
import { resetQueryFn } from '../../../../query/query.utils';
import { QueryKey, useQueryClient } from 'react-query';
import { ErrorHandler } from '../../../../service/errorHandler';
import { MDBDropdownItem, MDBDropdownLink } from 'mdb-react-ui-kit';
import { TablePagination } from "./table.pagination";
import AppAsyncSelect from "../../../select/appAsyncSelect";

interface IProps<T extends GridValidRowModel> {
    queryKey: QueryKey;
    selectionModel: GridSelectionModel;
    groupActions: TableGroupAction[];
    columns: TableColumn<T>[];
    columnVisibilityModel: GridColumnVisibilityModel;
    columnVisibilityChange: (newVisibilityModel: GridColumnVisibilityModel) => void;
}

export function TableFooter<T extends GridValidRowModel>(props: IProps<T>) {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [action, setAction] = useState<TableGroupAction | null>(null);
    const [columnsOpen, setColumnsOpen] = useState(false);
    const columnsAnchor = useRef<HTMLButtonElement>(null);

    const doGroupAction = async () => {
        if (!action) return;

        try {
            await action.action(props.selectionModel);
            if (action.refreshTableAfterAction ?? true) {
                await queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, ...new Array<QueryKey>().concat(props.queryKey)) });
            }
        } catch (e) {
            ErrorHandler.handle(action.id, e);
        }
    };

    return (
        <>
            <ClickAwayListener onClickAway={() => setColumnsOpen(false)}>
            <GridFooterContainer className="ps-3 py-1 table-on-page mt-6">
                <Popper open={columnsOpen}
                        anchorEl={columnsAnchor.current}
                        placement="top-end"
                        className="visible-columns"
                        modifiers={[{
                            name: 'offset',
                            // options: { offset: [0, -36] }
                        }]}
                        onMouseLeave={() => setColumnsOpen(false)}>
                    <ul className="dropdown-menu show visible-columns d-block">
                        {props.columns.map((column) => (
                          <MDBDropdownItem key={column.field}>
                              <MDBDropdownLink
                                tag="button"
                                type="button"
                                className={props.columnVisibilityModel[column.field] ? 'active' : ''}
                                onClick={() =>
                                  props.columnVisibilityChange({
                                      ...props.columnVisibilityModel,
                                      [column.field]: !props.columnVisibilityModel[column.field],
                                  })
                                }
                              >
                                  {column.headerName}
                              </MDBDropdownLink>
                          </MDBDropdownItem>
                        ))}
                    </ul>
                </Popper>
                <TablePagination />
                <button className="ripple ripple-surface ripple-surface-dark btn btn-light dropdown-toggle shadow-0 ms-0 me-2"
                        onClick={() => setColumnsOpen(true)}
                        ref={columnsAnchor}>
                    <i className="fas fa-cog" />
                </button>
            </GridFooterContainer>
            </ClickAwayListener>
            {(props.selectionModel?.length || false) && (
              <GridFooterContainer className="justify-content-start py-3">
                  <GridSelectedRowCount selectedRowCount={props.selectionModel?.length ?? 0} />
                  <div style={{width: '200px' }}>
                      <AppAsyncSelect value={action} className='w-100'
                                      onChange={(value: TableGroupAction | null) => {
                                          setAction(value);
                                      }}
                                      options={props.groupActions}
                                      menuPlacement="top"/>
                  </div>
                  {action != null && props.selectionModel.length > 0 && (
                    <button type="button" className="btn btn-success ms-2" onClick={doGroupAction}>
                        {t('main:btn-apply')}
                    </button>
                  )}
              </GridFooterContainer>
            )}
        </>
    );
}
