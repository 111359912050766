import React, { FC, Suspense, useContext, useMemo, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { useHistory, useParams } from 'react-router-dom';
import BottomActions from '../../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../../component/actions/bottomActionsContainer';
import Loader from '../../../../component/loader';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import { MailingService } from '../../../../eenApi/mailing/service';
import { ToastManager } from '../../../../service/toastManager';
import { MailingRoutesPath } from '../../routes';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TopActions from '../../../../component/actions/topActions';
import TopActionsContainer from '../../../../component/actions/topActionsContainer';
import MainEditTab from '../../../../component/mailing/MainEditTab';
import TabControls from '../../../../component/tabs/tabControls';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import TabItem from '../../../../component/tabs/tabItem';
import { MailingEditValidator } from '../../../../utils/validators';
import MailingDto from '../../../../eenApi/mailing/dto/mailing.dto';
import { isRoutePathCreate } from '../../../../utils/check.route.path';
import RecepientEditTab from '../../../../component/mailing/RecipientEditTab';
import AttachmentEditTab from '../../../../component/mailing/AttachmentEditTab';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import TabPanel from '../../../../component/tabs/tabPanel';
import { MDBBtn } from 'mdb-react-ui-kit';
import BaseModal from '../../../../component/modal/baseModal';
import { ActionItem } from '../../../../models/actionItem';
import ModalHeader from '../../../../component/modal/modalHeader';
import { UserContext } from '../../../../context/user-context';
import MailingStatusEnum from '../../../../eenApi/mailing/enum/mailing.status.enum';
import Rules from '../../../../rules';

const EditMailing: FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const { id } = useParams<{ id?: string }>();
    const currentUser = useCurrentUser();
    const { checkAccess } = useContext(UserContext);

    const [validateOn, setValidateOn] = useState(false);
    const [tabs, setTabs] = useState<string[]>([]);

    const { data: entity, isFetching } = useQuery<MailingDto>(['mailing', id, currentUser.data!.language], {
        onSuccess: async (data) => {
            setData(new MailingDto(data, currentUser.data!.language));
            setActiveTab('mailing');
        },
        enabled: !!id,
    });

    const [data, setData] = useState<MailingDto>(new MailingDto(entity, currentUser.data!.language));

    const [activeTab, setActiveTab] = useState(!id || data?.id ? 'mailing' : 'loading');

    const tabItems = useMemo(() => {
        return [
            new TabItem('mailing', t('mailing:mailings.edit.release')),
            new TabItem('recipient', t('mailing:mailings.edit.recipient')),
            new TabItem('attachment', t('mailing:mailings.edit.attachment')),
        ];
    }, [i18n.language]);

    const createPath = MailingRoutesPath.Add;

    const updateMutation = useMutation((_: string) => MailingService.save({
        ...data!,
        files: data.filesId as any
    }), {
        onSuccess: async (result, tab) => {
            ToastManager.success({
                title: 'main:msg-success',
                message: 'main:msg-changes-saved',
            });
            data.id = result;
            await queryClient.resetQueries({
                predicate: (query) => query.queryKey.includes('mailing'),
            });
            history.replace(MailingRoutesPath.Edit.replace(':id', result));
            setActiveTab(tab);
        },
        onSettled: (data, error, tab) => {
            if (error) {
                setActiveTab(tab);
            }
        },
    });

    const forceSendMailing = useMutation((_: string) => MailingService.forceSending({
        ...data!,
        files: data.filesId as any
    }, true), {
        onSuccess: async (result, tab) => {
            ToastManager.success({
                title: 'main:msg-success',
                message: 'main:msg-mailing-send',
            });
            data.id = result;
            await queryClient.resetQueries({
                predicate: (query) => query.queryKey.includes('mailing'),
            });
            history.replace(MailingRoutesPath.List);
            setActiveTab(tab);
        },
        onSettled: (data, error, tab) => {
            if (error) {
                setActiveTab(tab);
            }
        },
    });

    const deleteMutation = useMutation((_: string) => MailingService.remove(id!), {
        onSuccess: async () => {
            ToastManager.success({
                title: 'main:msg-success',
                message: 'main:msg-entity-deleted',
            });
            await queryClient.removeQueries({
                predicate: (query) => query.queryKey.includes('mailing'),
            });
            history.push(MailingRoutesPath.List);
        },
        onSettled: (data, error, tab) => {
            if (error) {
                setActiveTab(tab);
            }
        },
    });

    const save = () => {
        setValidateOn(true);
        window.scrollTo(0, 0);
        const erroredTabs = MailingEditValidator.validate(data!, tabs, entity!);
        setTabs(erroredTabs);

        if (erroredTabs.length === 0) {
            updateMutation.mutate(activeTab);
            setActiveTab('loading');
        }
    };

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const closeDelete = () => setIsDeleteOpen(false);
    const remove = () => {
        closeDelete();
        window.scrollTo(0, 0);
        deleteMutation.mutate(activeTab);
        setActiveTab('loading');
    };

    const actions = (() => {
        const items: ActionItem[] = [
            {
                name: t('main:btn-save'),
                onClick: () => save(),
            },
            {
                name: t('main:btn-cancel'),
                onClick: async () => {
                    if (isRoutePathCreate(history.location.pathname, createPath)) {
                        await queryClient.invalidateQueries(['mailing']);
                        history.goBack();
                    } else {
                        await queryClient.invalidateQueries(['mailing', id]);
                        history.push(MailingRoutesPath.Edit.replace(':id', id!));
                    }
                },
                color: '#8E9FA7',
            },
        ];

        if (id && checkAccess(Rules.Mailing.Delete)) {
            items.push({
                name: t('main:btn-delete'),
                onClick: async () => setIsDeleteOpen(true),
            });
        }
        if (id && checkAccess(Rules.Mailing.Send) && entity?.status !== MailingStatusEnum.Issued) {
            items.push({
                name: t('main:btn-send'),
                onClick: () => forceSendMailing.mutate(activeTab),
            });
        }
        return items;
    })();

    const updateData = (field: keyof MailingDto, value: any) => {
        let newValue = value?.value ?? value;
        setData((prev) => ({
            ...prev,
            [field]: Array.isArray(newValue) ? [...newValue] : newValue,
        }));
    };

    return (
        <MainContainer>
            <PageTitle text={t(data.id ? 'mailing:mailings.edit.title' : 'mailing:mailings.create.title')} backLink={MailingRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <TabPanel activeId={activeTab} id="loading" />
                {data?.language && isFetching ? (
                    <Loader />
                ) : (
                    <>
                        <MainEditTab activeId={activeTab} id="mailing" entity={entity} data={data} updateData={updateData} />
                        <Suspense fallback={<Loader />}>
                            <RecepientEditTab activeId={activeTab} id="recipient" entity={entity} data={data} updateData={updateData} />
                        </Suspense>
                        <Suspense fallback={<Loader />}>
                            <AttachmentEditTab activeId={activeTab} id="attachment" entity={entity} data={data} updateData={updateData} />
                        </Suspense>
                        <BottomActionsContainer>
                            <BottomActions actions={actions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
            <BaseModal isOpen={isDeleteOpen} onRequestClose={closeDelete} modalSize="s">
                <ModalHeader title={t('main:msg-entity-delete-confirm')} onRequestClose={closeDelete} />
                <div className="text-center p-3">
                    <MDBBtn onClick={() => remove()}>{t('main:yes')}</MDBBtn>
                </div>
            </BaseModal>
        </MainContainer>
    );
};

export default EditMailing;
