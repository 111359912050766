import { useContext, useMemo, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import { MailingRoutesPath } from '../../routes';
import { Link } from 'react-router-dom';
import { MailingTable } from './table';
import FilterItemText from '../../../../component/filter/types/filterItem/items/text';
import FilterItemAsyncSelect from '../../../../component/filter/types/filterItem/items/asyncSelect';
import Filter from '../../../../component/filter';
import { CenterService } from '../../../../eenApi/center/service';
import MailingStatusEnum from '../../../../eenApi/mailing/enum/mailing.status.enum';
import FilterItemSelect from '../../../../component/filter/types/filterItem/items/select';
import { useOptions } from '../../../../hooks/select.options.hook';
import FilterItemDateRange from '../../../../component/filter/types/filterItem/items/date';
import Rules from '../../../../rules';
import { UserContext } from '../../../../context/user-context';

export function MailingList() {
    const { t, i18n } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
    const { checkAccess } = useContext(UserContext);

    const statusOptions = useOptions<MailingStatusEnum>(MailingStatusEnum, 'mailing:mailings.fields.status.');

    const filterItems = useMemo(() => [
        new FilterItemSelect('status', t('mailing:filter.status'), {
            isDefault: 'Y',
            options: statusOptions,
        }),
        new FilterItemDateRange('created', t('mailing:filter.creation-data'), { isDefault: 'Y' }),
        new FilterItemText('subject', t('mailing:filter.subject'), {
            isDefault: 'Y',
        }),
        new FilterItemAsyncSelect('centerId', t('mailing:filter.center'), {
            isDefault: 'Y',
            loadOptions: CenterService.selectLoadOptions,
        }),
    ], [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`mailing:mailings.list.title`)} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {checkAccess(Rules.Mailing.Add) && (
                        <Link className="btn btn-success me-2" to={MailingRoutesPath.Add}>
                            {t('main:btn-add')}
                        </Link>
                    )}
                </div>
                <Filter id="filter-mailing" items={filterItems} onFind={setFilterValues} query={{}} />
            </div>
            <MailingTable filterValues={filterValues} />
        </MainContainer>
    );
}
