import { FC, useContext, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { Link, useHistory } from 'react-router-dom';
import PageTitle from '../../../component/pageTitle';
import Filter from '../../../component/filter';
import MainContainer from '../../../component/mainContainer';
import Rules from '../../../rules';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import FilterItemSelect from '../../../component/filter/types/filterItem/items/select';
import FilterItemDateRange from '../../../component/filter/types/filterItem/items/date';
import { UserContext } from '../../../context/user-context';
import { useOptions } from '../../../hooks/select.options.hook';
import NewsAdminTable from './tables/adminTable';
import { NewsStatusType } from '../../../enums/NewsStatusType';
import FilterItemAsyncSelect from '../../../component/filter/types/filterItem/items/asyncSelect';
import { CenterService } from '../../../eenApi/center/service';
import { NewsRoutesPath } from '../routes';
import NewsCenterTable from './tables/centerTable';
import { UserService } from '../../../eenApi/user/service';

const NewsList: FC = () => {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
    const history = useHistory();

    const statusOptions = useOptions(NewsStatusType, 'news:status.');

    const pathName = history.location.pathname;

    const filterItems = useMemo(
        () => [
            new FilterItemText('title', t('news:filter.title'), { isDefault: 'Y' }),
            new FilterItemSelect('status', t('news:filter.status'), { options: statusOptions, isDefault: 'Y' }),
            new FilterItemDateRange('created', t('news:filter.creation-data'), { isDefault: 'Y' }),
            pathName === NewsRoutesPath.AdminList ? (new FilterItemAsyncSelect('centerId', t('news:filter.center'), {
                isDefault: 'Y',
                loadOptions: CenterService.selectLoadOptions,
            })) : (new FilterItemAsyncSelect('createdBy', 'main:field-full-name', {
                isDefault: 'Y',
                loadOptions: UserService.selectUserOptions
            })),
        ],
        [i18n.language],
    );

    return (
        <MainContainer>
            <PageTitle text={t('news:list-title')} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {checkAccess(Rules.News.Add) && (
                        <Link className="btn btn-success me-2" to={NewsRoutesPath.Create}>
                            {t('main:btn-add')}
                        </Link>
                    )}
                </div>

                <Filter id="filter-news" items={filterItems} onFind={setFilterValues} query={{}} />
            </div>

            {pathName === NewsRoutesPath.AdminList ? <NewsAdminTable filterValues={filterValues} /> : null}
            {pathName === NewsRoutesPath.CenterList ? <NewsCenterTable filterValues={filterValues} /> : null}
        </MainContainer>
    );
};

export default NewsList;
