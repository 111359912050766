import React, { FC, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { useHistory } from 'react-router-dom';
import { arrayMoveImmutable } from 'array-move';
import { MDBDropdown, MDBDropdownItem, MDBDropdownLink, MDBDropdownMenu, MDBDropdownToggle } from 'mdb-react-ui-kit';
import './style.css';
import { ErrorHandler } from '../../../service/errorHandler';
import { EenApi } from '../../../eenApi';
import MenuItemView from './menuItemView';
import { MenuOrder } from '../../../eenApi/menu/menuOrder';
import { MenuItem } from '../../../eenApi/menu/menuItem';
import { MenuService } from '../../../eenApi/menu/service';
import { useCookies } from 'react-cookie';

interface IProps {
    menu: MenuItem[];
    reloadItems: () => void;
}

const LeftMenu: FC<IProps> = props => {
    const { t } = useTranslation();
    const history = useHistory();
    const [visible, setVisible] = useState<number[]>([]);
    const [editable, setEditable] = useState(false);
    const [menuItems] = useState(() => props.menu.filter(x => x.parentId === 0 && !x.isAction));
    const [action] = useState(() => props.menu.find(x => x.parentId === 0 && x.isAction));
    const [cookies, setCookie] = useCookies(['menu-collapsed']);

    function getFavoriteId(): number {
        if (menuItems.length > 0) {
            return menuItems[0].id;
        }
        return 0;
    }

    function getMenuOrder() {
        var arData: MenuOrder[] = [];
        menuItems.forEach((item, i) => {
            arData = arData.concat(MenuService.itemToArray(item, i));
        });
        return arData;
    }

    const [menuOrder, setMenuOrder] = useState(() => {
        return getMenuOrder();
    });

    function clickOnButton(e: React.MouseEvent, menuItem: MenuItem, query: Record<string, any> = { }) {
        if (e) {
            e.preventDefault();
        }
        if (menuItem.link && menuItem.link.length > 0) {
            if (e.ctrlKey || e.metaKey || e.button === 1) {
                const newTab = window.open(menuItem.link, '_blank');
                if (newTab)
                    newTab.history.pushState({ query }, 'query')
            } else {
                history.push(menuItem.link, { query });
            }
            return;
        }
        if (menuItem.children.length > 0 && cookies['menu-collapsed'] !== 'true') {
            let v = visible.slice();
            if (v.includes(menuItem.id)) {
                v = v.filter(x => x !== menuItem.id);
            } else {
                v.push(menuItem.id);
            }
            setVisible(v);
        }
    }

    async function clickSave() {
        try {
            if (!menuOrder) {
                setEditable(false);
                return;
            }
            const rd = menuOrder.filter(x => x.changed);
            if (rd.length < 1) {
                setEditable(false);
                return;
            }
            await EenApi.putRequest("menu/user", { items: rd });
            props.reloadItems();
        } catch (err) {
            ErrorHandler.handle("save user menu settings", err);
        } finally {
            //setEditable(false);
        }
    }

    async function clickRestore() {
        try {
            await EenApi.putRequest("menu/restore");
            props.reloadItems();
        } catch (err) {
            ErrorHandler.handle("restore default user menu settings", err);
        } finally {
            setEditable(false);
        }
    }

    async function clickCancel() {
        try {
            props.reloadItems();
        } catch (err) {
            ErrorHandler.handle("cancel user menu settings", err);
        } finally {
            setEditable(false);
        }
    }

    function onSortEnd(e: any) {
        const id = parseInt(e.item.dataset.id);
        const parent = parseInt(e.to.dataset.parent);
        if (menuOrder) {
            const item = menuOrder.find(x => x.id === id && x.parentId === parent);
            if (item) {
                if (item.sort === e.newIndex) {
                    return;
                }
                const sortedItems = menuOrder.filter(x => x.parentId === parent).sort((a, b) => a.sort - b.sort);
                arrayMoveImmutable(sortedItems, e.oldIndex, e.newIndex).forEach((orderItem, index) => {
                    if (orderItem.sort !== index) {
                        orderItem.changed = true;
                        orderItem.sort = index;
                    }
                });
            }
        }
    }

    function clickShow(id: number, parentId: number) {
        const order = menuOrder.slice();
        const index = order.findIndex(x => x.id === id && x.parentId === parentId);
        if (index > -1) {
            order[index].changed = true;
            order[index].hidden = "N";
            setMenuOrder(order);
        }
    }

    function clickHide(id: number, parentId: number) {
        const order = menuOrder.slice();
        const index = order.findIndex(x => x.id === id && x.parentId === parentId);
        if (index > -1) {
            order[index].changed = true;
            order[index].hidden = "Y";
            setMenuOrder(order);
        }
    }

    async function clickAddToFavorite(menuItem: MenuItem) {
        const favoriteId = getFavoriteId();
        const index = menuOrder.findIndex(x => x.id === menuItem.id && x.parentId === favoriteId);
        if (index > -1) {
            return;
        }
        try {
            const copy: MenuItem = { ...menuItem, parentId: favoriteId }; //new MenuItem();
            await EenApi.putRequest("menu/user", { items: [copy] });
            props.reloadItems();
        } catch (err) {
            ErrorHandler.handle("menu add to favorite", err);
        }
    }

    async function clickRemoveFromFavorite(menuItem: MenuItem) {
        try {
            await EenApi.delete(`menu/deleteuser/${menuItem.userItemId}`);
            props.reloadItems();
        } catch (err) {
            ErrorHandler.handle("menu remove from favorite", err);
        }
    }

    return (
        <div className="d-none d-lg-block">
            {action && action.children.length > 0 && (
                <div className="mb-2 px-3">
                    <MDBDropdown>
                        <MDBDropdownToggle className="btn btn-primary btn-block">{action.name}</MDBDropdownToggle>
                        <MDBDropdownMenu>
                            {action.children.map((item, i) => (
                                <MDBDropdownItem key={i}>
                                    <MDBDropdownLink key={item.id} tag="button" onClick={(e: React.MouseEvent) => clickOnButton(e, item)}>
                                        {item.icon && item.icon.length > 0 && (
                                            <i className={`${item.icon} my-auto me-2`}
                                               style={{ color: item.color && item.color.length > 0 ? item.color : undefined }} />
                                        )}
                                        <span className="flex-grow-1">{item.name}</span>
                                    </MDBDropdownLink>
                                </MDBDropdownItem>
                            ))}
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
            )}
            <div className={`left-menu ${cookies['menu-collapsed'] === 'true' ? 'menu-collapsed' : ''}`}>
                {menuItems.map((menuItem, index) => (<MenuItemView
                            key={menuItem.id}
                            index={index}
                            editable={editable}
                            item={menuItem}
                            visible={visible.includes(menuItem.id)}
                            menuOrder={menuOrder}
                            clickOnButton={clickOnButton}
                            clickSettings={() => setEditable(true)}
                            onSortEnd={onSortEnd}
                            clickShow={clickShow}
                            clickHide={clickHide}
                            clickAddToFavorite={clickAddToFavorite}
                            clickRemoveFromFavorite={clickRemoveFromFavorite}
                            favoriteId={getFavoriteId()}
                        />))}
                <div>
                    <button
                        className={'btn-left-menu top collapse-toggle'}
                        onClick={() => {
                            setCookie('menu-collapsed', !(cookies['menu-collapsed'] === 'true'), { path: '/' });
                        }}
                    >
                        {cookies['menu-collapsed'] === 'true' && <i className="fas fa-angle-double-right my-auto"/>}
                        {cookies['menu-collapsed'] !== 'true' && <i className="fas fa-angle-double-left my-auto"/>}
                        <span className="flex-grow-1 fw-bolder">&nbsp;</span>
                    </button>
                </div>
            </div>
            {editable && (
                <div className="mt-2 px-3">
                    <button className="btn btn-primary btn-block" onClick={clickSave}>
                        {t('main:btn-save')}
                    </button>
                    <button className="btn btn-primary btn-block" onClick={clickCancel}>
                        {t('main:btn-cancel')}
                    </button>
                    <button className="btn btn-primary btn-block" onClick={clickRestore}>
                        {t('main:btn-restore-defaults')}
                    </button>
                </div>
            )}
        </div>
    );
};

export default LeftMenu;
