import ModelValidator from "../model.validator";
import EmailSendConfigurationDto from "../../../eenApi/configuration/email.send.configuration.dto";

class Validator extends ModelValidator<EmailSendConfigurationDto, EmailSendConfigurationDto> {
  validate(dto: EmailSendConfigurationDto): string[] {
    const erroredTabs: string[] = [];

    this.checkRequired(dto.providerId, erroredTabs, 'main');

    return erroredTabs
  }

}

export const EmailSendConfigurationValidator = new Validator()