export class CustomFilterItemDto {
  columnName: string;
  value: string;
  metaInfo?: string;

  constructor(columnName: string, value?: any, metaInfo?: any) {
    this.columnName = columnName;
    this.value = JSON.stringify(value);
    this.metaInfo = JSON.stringify(metaInfo);
  }
}