import { FC, useContext } from "react";
import useTranslation from "../../../hooks/translation.hook";
import { DefaultTheme, makeStyles } from "@material-ui/styles";
import { MDBDropdown, MDBDropdownItem, MDBDropdownLink, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
import { UserContext } from "../../../context/user-context";
import Childs from "./childs";
import SortableContainer from '../../sortableContainer';
import Rules from "../../../rules";
import { MenuItem } from "../../../eenApi/menu/menuItem";
import { MenuOrder } from "../../../eenApi/menu/menuOrder";
import { Badge, BadgeProps } from "@mui/material";
import styled from "@emotion/styled";


interface IProps {
    item: MenuItem;
    clickOnButton: any;
    visible: boolean,
    index?: number,
    clickSettings: () => void,
    editable: boolean,
    onSortEnd: (e: any) => void,
    menuOrder: MenuOrder[],
    clickShow: (id: number, parentId: number) => void,
    clickHide: (id: number, parentId: number) => void,
    clickAddToFavorite: (menuItem: MenuItem) => void,
    clickRemoveFromFavorite: (menuItem: MenuItem) => void
    favoriteId: number
}

const useStyles = makeStyles<DefaultTheme, any>({
    top: {
        borderColor: props => props.color,
        '&:hover': {
            backgroundColor: props => props.color,
            fill: 'white',
            color: 'white!important',
            '& i': {
                backgroundColor: props => props.color,
                fill: 'white',
                color: 'white!important'
            }
        }
    },
    child: {
        borderColor: props => props.color
    },
    firstIcon: {
        color: props => props.color
    }
});

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
    '& .MuiBadge-badge': {
        right: -10,
        top: 10,
    },
    '&.MuiBadge-root': {
        textOverflow: 'ellipsis',
        maxWidth: '100%'
    }
}));

const MenuItemView: FC<IProps> = props => {
    const context = useContext(UserContext);
    const { t } = useTranslation();
    const menuItem = props.item;
    const styleProps = { color: menuItem.color };
    const classes = useStyles(styleProps);
    const childItems = props.editable ? menuItem.children.filter(x => x.isAction !== "Y").sort(x => x.sort) : menuItem.children.filter(x => x.isAction !== "Y" && x.hiddenByUser !== 'Y').sort(x => x.sort);
    const isOpen = props.editable ? props.editable : (props.favoriteId === menuItem.id ? true : props.visible);

    if (!menuItem.link?.length && !menuItem.children.length) {
        return null;
    }

    const unviewedItemsCount = childItems.reduce((acc, item) => acc + item.unviewedItemsCount, 0);

    const handleBadgeClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>, menuItem: MenuItem) => {
        if ((event.target as HTMLElement).className.includes('MuiBadge-badge')) {
            event.stopPropagation();
            props.clickOnButton(event, menuItem, {
                isNotViewed: [{
                        value: 'Y',
                        label: t(`main:yes`),
                    }],
            })
        }
    };

    return (
        <div>
            <button className={`btn-left-menu top ${classes.top}`} onClick={(e) => props.editable ? false : props.clickOnButton(e, menuItem)}>
                <i className={`${menuItem.icon} my-auto menu-icon ${classes.firstIcon}`}/>
                <span className="flex-grow-1 fw-bolder">
                    <StyledBadge badgeContent={unviewedItemsCount} color="error" onClick={(e) => handleBadgeClick(e, menuItem)}>
                        {menuItem.name}
                    </StyledBadge>
                </span>
                {childItems.length > 0 && !props.editable && props.favoriteId !== menuItem.id &&
                    <i className={`${props.visible ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} my-auto text-black-50`}/>
                }
                {props.index === 0 && !props.editable && context.checkAccess(Rules.Menu.UserEdit) &&
                    <i className="fas fa-cog my-auto text-black-50" onClick={props.clickSettings}/>
                }
            </button>
            {childItems.length > 0 &&
                <>
                    {props.editable
                        ? <Childs isOpen={true} count={childItems.length}>
                            <SortableContainer group={`menu-${menuItem.id}`} parentId={menuItem.id} onEnd={props.onSortEnd}>
                                {childItems.map((menuItemChild) => {
                                    const hidden = props.menuOrder.find(x => x.id === menuItemChild.id)?.hidden === 'Y';
                                    return (
                                        <div key={menuItemChild.id} data-id={menuItemChild.id} className={`btn-left-menu ${classes.child}`}>
                                            <i className="fas fa-bars text-black-50 cursor-move my-auto me-2 px-1"/>
                                            {menuItemChild.icon && menuItemChild.icon.length > 0 &&
                                                <i className={`${menuItemChild.icon} my-auto`} style={{ color: menuItemChild.color && menuItemChild.color.length > 0 ? menuItemChild.color : undefined }}/>
                                            }
                                            <span title={menuItemChild.name} className={`flex-grow-1 ${hidden ? 'text-decoration-line-through' : ''}`}>{menuItemChild.name}</span>
                                            <MDBDropdown>
                                                <MDBDropdownToggle tag="a" role="button" className="dropdown-icon-none"><i className="fas fa-pen text-black-50"/></MDBDropdownToggle>
                                                <MDBDropdownMenu>
                                                    {props.favoriteId !== menuItem.id && menuItemChild.link && menuItemChild.link.length > 0 &&
                                                        <MDBDropdownItem>
                                                            <MDBDropdownLink tag="button" className="py-0 px-2" onClick={() => props.clickAddToFavorite(menuItemChild)}>{t('menu:btn-add-to-favorites')}</MDBDropdownLink>
                                                        </MDBDropdownItem>
                                                    }
                                                    {props.favoriteId === menuItem.id && menuItemChild.hasUserItem === 'Y' &&
                                                        <MDBDropdownItem>
                                                            <MDBDropdownLink tag="button" className="py-0 px-2" onClick={() => props.clickRemoveFromFavorite(menuItemChild)}>{t('menu:btn-remove-from-favorites')}</MDBDropdownLink>
                                                        </MDBDropdownItem>
                                                    }
                                                    {!hidden &&
                                                        <MDBDropdownItem>
                                                            <MDBDropdownLink tag="button" className="py-0 px-2" onClick={() => props.clickHide(menuItemChild.id, menuItem.id)}>{t('menu:btn-hide')}</MDBDropdownLink>
                                                        </MDBDropdownItem>
                                                    }
                                                    {hidden &&
                                                        <MDBDropdownItem>
                                                            <MDBDropdownLink tag="button" className="py-0 px-2" onClick={() => props.clickShow(menuItemChild.id, menuItem.id)}>{t('menu:btn-show')}</MDBDropdownLink>
                                                        </MDBDropdownItem>
                                                    }
                                                </MDBDropdownMenu>
                                            </MDBDropdown>
                                        </div>
                                    );
                                })}
                            </SortableContainer>
                        </Childs>
                        : <Childs isOpen={isOpen} count={childItems.length}>
                            {childItems.map((menuItemChild: any) => (
                                <button key={menuItemChild.id} className={`btn-left-menu ${classes.child}`} onClick={(e) => props.editable ? false : props.clickOnButton(e, menuItemChild)}>
                                    {menuItemChild.icon && menuItemChild.icon.length > 0 &&
                                        <i className={`${menuItemChild.icon} my-auto`} style={{ color: menuItemChild.color && menuItemChild.color.length > 0 ? menuItemChild.color : undefined }}/>
                                    }
                                    <div title={menuItemChild.name} className="w-100">
                                        <StyledBadge badgeContent={menuItemChild.unviewedItemsCount} color="error"
                                                     title={menuItemChild.name} className="flex-grow-1"
                                                     onClick={(e) => handleBadgeClick(e, menuItemChild)}>
                                            <div className="menu-name">{menuItemChild.name}</div>
                                    </StyledBadge>
                                    </div>
                                </button>
                            ))}
                        </Childs>
                    }
                </>
            }
        </div>
    );//href={menuItemChild.link && menuItemChild.link.length > 0 ? menuItemChild.link : '#'}
}

export default MenuItemView;
