import React, { useContext, useMemo } from 'react';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { CenterRoutesPath } from '../routes';
import TabItem from '../../../component/tabs/tabItem';
import Rules from '../../../rules';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import TabControls from '../../../component/tabs/tabControls';
import { MainEditTab } from '../../../component/center/MainEditTab';
import { CenterEditValidator } from '../../../utils/validators/center/edit.validator';
import { CenterDto } from '../../../eenApi/center/dto/center.dto';
import { CenterService } from '../../../eenApi/center/service';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';
import { DeleteModal } from '../../../component/common/ui';
import { UserContext } from '../../../context/user-context';
import { useParams } from "react-router-dom";
import { LoaderTab } from "../../../component/common/layout";

export function CenterEdit() {
    const { data: currentUser } = useCurrentUser();
    const { checkAccess } = useContext(UserContext);
    const { id } = useParams<{ id: string }>();
    const { t, i18n, data, entity, activeTab, setActiveTab, updateData, validateOn, tabs, setTabs, saveActions, isDeleteOpen, closeDelete, remove } =
        useEditPageWithDelete<CenterDto>({
            dataFactory: (entity, language) => new CenterDto(entity, language, currentUser?.rules),
            queryKey: 'center',
            handler: (data) => CenterService.save(data),
            validator: CenterEditValidator,
            applyRoute: CenterService.getEditRoute,
            saveRoute: CenterService.getDetailsRoute,
            cancelRoute: [CenterRoutesPath.List, CenterService.getDetailsRoute],
            canDelete: (checkAccess(Rules.Center.Delete) || checkAccess(Rules.Center.DeleteOwn, [id])),
            deleteHandler: (id) => CenterService.remove(id),
        });

    const tabItems = useMemo(() => [new TabItem('main', t('center:tab.main'))], [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`center:title.${entity?.id ? 'edit' : 'create'}`)} backLink={CenterRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={saveActions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <LoaderTab activeId={activeTab}/>
                <MainEditTab activeId={activeTab} id="main" data={data} updateData={updateData} />
                <BottomActionsContainer>
                    <BottomActions actions={saveActions} />
                </BottomActionsContainer>
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}