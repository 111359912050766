import { FC, useState } from 'react';
import useTranslation from '../../../../../hooks/translation.hook';
import { useParams } from 'react-router-dom';
import DefaultBlock from '../../../../../component/defaultBlock';
import MainContainer from '../../../../../component/mainContainer';
import PageTitle from '../../../../../component/pageTitle';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useCurrentUser } from '../../../../../hooks/useCurrentUser';
import BottomActions from '../../../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../../../component/actions/bottomActionsContainer';
import { ToastManager } from '../../../../../service/toastManager';
import { SettingsRoutesPath } from '../../../../settings/routes';
import MailingUnsubscriptionDto from '../../../../../eenApi/mailing/dto/mailing.unsubscription.dto';
import UnsubscribeEmailEdit from '../../../../../component/mailing/UnsubscribeEmailEdit';
import MailingUnsubscriptionValidator from '../../../../../utils/validators/mailing/edit.unsubscribe.validator';
import { MailingService } from '../../../../../eenApi/mailing/service';

const EditMailingUnsubscribe: FC = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const currentUser = useCurrentUser();
    const currentUserEmail = useParams<{ email: string }>().email ?? currentUser.data?.user?.email;
    const [, setValidateOn] = useState(false);
    const [tabs, setTabs] = useState<string[]>([]);

    const { data: entity } = useQuery<MailingUnsubscriptionDto>([`mailing/getUnsubscribing`, currentUserEmail], () => MailingService.getUnsubscribing(currentUserEmail!), {
        onSuccess: async (data) => {
            setData(new MailingUnsubscriptionDto(data));
        },
        onError: (error: any) => {
            error = error?.response ? error.response : error;
            if (!error || error.status !== 404) {
                queryClient.defaultQueryOptions().onError!(error)
            }
        },
        enabled: !!currentUserEmail,
    });

    const [data, setData] = useState<MailingUnsubscriptionDto>(new MailingUnsubscriptionDto(entity));
    const [activeTab, setActiveTab] = useState(data?.id ? 'main' : 'loading');

    const updateData = (field: keyof MailingUnsubscriptionDto, value: any) => {
        let newValue = value?.value ?? value;
        setData({
            ...data!,
            [field]: Array.isArray(newValue) ? [...newValue] : newValue,
        });
    };
    const actions = [
        {
            name: t('main:btn-save'),
            onClick: () => {
                saveAction()
            }
        },
    ];

    const updateMutation = useMutation((_: string) => MailingService.unsubscribe({ ...data, email: currentUserEmail }), {
        onSuccess: async (result, tab) => {
            ToastManager.success({
                title: 'main:msg-success',
                message: 'main:msg-changes-saved',
            });
            await queryClient.resetQueries({
                predicate: (query) => query.queryKey.includes('mailing'),
            });
            setActiveTab(tab);
        },
        onSettled: (data, error, tab) => {
            if (error) {
                setActiveTab(tab);
            }
        },
    });

    const setUserEmail = (value: any) => {
        updateData('email', value);
    };


    const saveAction = () => {
        setValidateOn(true);
        window.scrollTo(0, 0);
        const erroredTabs = MailingUnsubscriptionValidator.validate(data!, tabs, entity!);
        setTabs(erroredTabs);
        setUserEmail(currentUserEmail);
        if (erroredTabs.length === 0) {
            updateMutation.mutate(activeTab);
            setActiveTab('loading');
        }
    };

    return (
        <MainContainer>
            <PageTitle text={t('mailing:unsubscribe.titleUnsubscribe', { currentUserEmail })} backLink={SettingsRoutesPath.Prefix} />
            <DefaultBlock>
                <UnsubscribeEmailEdit id="main" data={data} updateData={updateData} activeId={'main'} />
                <BottomActionsContainer>
                    <BottomActions actions={actions} />
                </BottomActionsContainer>
            </DefaultBlock>
        </MainContainer>
    );
};

export default EditMailingUnsubscribe;
