import { useEffect, useState } from 'react';
import LeftMenu from './leftMenu';
import BottomMenu from './bottomMenu';
import { ErrorHandler } from '../../service/errorHandler';
import { MenuService } from '../../eenApi/menu/service';
import { MenuItem } from '../../eenApi/menu/menuItem';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { useHub } from "../../hooks/UseHub";
import { flatMap } from "lodash";
import { matchEntity } from "../../eenApi/menu/enum/unviewd.item.type";
import { resetQueryFn } from "../../query/query.utils";
import { useQueryClient } from "react-query";

export default function Menu() {
    const currentUser = useCurrentUser();
    const [loaded, setLoaded] = useState<string>();
    const [menu, setMenu] = useState<MenuItem[]>([]);
    const queryClient = useQueryClient();

    const loadItems = async () => {
        if (!currentUser?.data?.language) return;
        try {
            const menu = await MenuService.getUserMenu({ language: currentUser?.data?.language });
            setMenu(menu);
            setLoaded(currentUser?.data?.language);
        } catch (err) {
            ErrorHandler.handle('load user menu', err);
        }
    };

    useHub({
        method: 'DecUnviewedCount',
        callback: (type) => {
            flatMap<MenuItem, MenuItem>(menu, item => item.children).forEach(item => {
                if (item.unviewedItemType === type) {
                    item.unviewedItemsCount = Math.max(item.unviewedItemsCount - 1, 0);
                    return queryClient.resetQueries({ predicate: q => resetQueryFn(q, matchEntity(type))})
                }
            })
            setMenu([...menu])
        }
    })
    useHub({
        method: 'UnviewedCount',
        callback: (key, count) => {
            flatMap<MenuItem, MenuItem>(menu, item => item.children).forEach(item => {
                if (item.unviewedItemType === key) {
                    item.unviewedItemsCount = count
                }
            })
            setMenu([...menu])
        }
    })

    const reloadItems = () => {
        setLoaded(undefined);
    };

    useEffect(() => {
        if (loaded === currentUser?.data?.language) return;
        loadItems();
    }, [loaded, currentUser?.data?.language]);

    if (!loaded) {
        return <></>;
    }

    return (
        <>
            <LeftMenu menu={menu} reloadItems={reloadItems} key={loaded}/>
            <BottomMenu menu={menu} />
        </>
    );
}
