import TabPanel from '../../tabs/tabPanel';
import useTranslation from '../../../hooks/translation.hook';
import { Field } from '../../common/layout';
import { formatDate } from '../../../utils/date.utils';
import IDetailsPanelProps from '../details.panel.props';

export default function UserDetailsTab({ entity, ...rest }: IDetailsPanelProps) {
    const { t } = useTranslation();

    return (
        <TabPanel {...rest}>
            {entity.photo?.source && <Field labelKey='user:fields.photo' optional>
                <div><img src={'/' + entity.photo.source} alt={t('user:fields.photo')} style={{ maxHeight: '200px' }} /></div>
            </Field>}
            <Field labelKey="user:fields.fullName" value={entity.fullName} />
            <Field labelKey="user:fields.active" optional value={entity.active ? t('user:boolean.yes') : t('user:boolean.no')} />
            <Field labelKey="user:fields.isEmailVerified" optional value={entity.isEmailVerified ? t('user:boolean.verified') : t('user:boolean.not_verified')} />
            <Field labelKey="user:fields.gender" optional value={entity.gender ? t('user:boolean.male') : t('user:boolean.female')} />
            <Field labelKey="user:fields.email" optional value={entity.email} />
            <Field labelKey="user:fields.phone" optional value={entity.phone} />
            <Field labelKey="user:fields.linkedIn" optional value={entity.linkedIn} />
            <Field labelKey="user:fields.telegram" optional value={entity.telegram} />
            <Field labelKey="user:fields.dateOfBirth" optional value={formatDate(entity.dateOfBirth)} />
            <Field labelKey="user:fields.country" optional value={entity.memberCountry?.name} />
        </TabPanel>
    );
}
