import React, { useCallback, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../../context/user-context';
import Rules from '../../../../rules';
import { ProfileRoutesPath } from '../../routes';
import useTranslation from '../../../../hooks/translation.hook';
import { InterestDto } from '../../../../eenApi/profile/dto/interest.dto';
import { ClientRoutesPath } from '../../../client/routes';
import ProfileService from '../../../../eenApi/profile/service';
import { useCanViewClient } from '../../../client/access.hook';
import { AccessActionEnum } from '../../../../models/access.dto';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';
import { useGroupDeleteAction, useRowActions } from "../../../../component/common/layout/DataTable/table.utils";

interface IProps {
    filterValues?: { [key: string]: any };
    list: 'my-interest' | 'interest';
}

export function InterestTable(props: IProps) {
    const { checkAccess } = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const profileAccess = useCallback(
        (objectId: number | undefined) =>
            checkAccess(Rules.Profile.ViewBasic) ||
            checkAccess(Rules.Profile.ViewBasicOwn, [String(objectId)]) ||
            checkAccess(Rules.Profile.ViewContact) ||
            checkAccess(Rules.Profile.ViewContactOwn, [String(objectId)]) ||
            checkAccess(Rules.Profile.ViewValidation) ||
            checkAccess(Rules.Profile.ViewValidationOwn, [String(objectId)]),
        [],
    );
    const clientAccess = useCanViewClient();

    const columns = useMemo<TableColumn<InterestDto>[]>(
        () => [
            new TableColumn({
                headerName: t('main:field-id'),
                field: 'id',
                sortable: true,
                width: 65,
                renderCell: ({ row }) =>
                    row.accessDto[AccessActionEnum.View] ? (
                        <Link to={`${ProfileRoutesPath.InterestView.replace(':id', row.profileInterestId!.toString())}`}>{row.profileInterestId}</Link>
                    ) : (
                        row.profileInterestId
                    ),
            }),
            new TableColumn({
                headerName: t('profile:fields.title'),
                field: 'title',
                renderCell: ({ row }) =>
                    profileAccess(row.center?.id) ? (
                        <Link to={`${ProfileRoutesPath.View.replace(':id', row.id?.toString() ?? '')}`}>{row?.title}</Link>
                    ) : (
                        row.title
                    ),
            }),
            new TableColumn({
                headerName: t('profile:fields.id'),
                field: 'profileId',
                width: 100,
                valueGetter: (params) => params.row.id,
            }),
            new TableColumn({
                headerName: t('profile:fields.clientId'),
                field: 'clientId',
                renderCell: ({ row }) =>
                    clientAccess(row?.client) ? (
                        <Link to={`${ClientRoutesPath.View.replace(':id', row.client?.id?.toString() ?? '')}`}>{row.client?.name}</Link>
                    ) : (
                        row.client?.name
                    ),
            }),
            new TableColumn({
                headerName: t('profile:fields.status'),
                field: 'status',
                width: 100,
                valueGetter: ({ row }) => t(`profile:interestStatus.${row.interestStatus}`),
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: (string|number)[]) => ProfileService.removeInterest(ids.map((s) => Number(s)));

    const rowActions = useRowActions<InterestDto>({
        edit: row => ProfileRoutesPath.InterestEdit.replace(':id', String(row.id)),
        hideEdit: row => !row.accessDto[AccessActionEnum.Update],
        remove: row => clickDelete([row.id!]),
        hideRemove: row => !row.accessDto[AccessActionEnum.Delete]
    });
    const groupActions = useGroupDeleteAction({ remove: clickDelete });

    return (
        <DataTable
            id="interest-table-component"
            queryKey={[EntityQueryKey.ProfileInterest, props.list]}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => ProfileService.listInterest(props.list, requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
            itemId="profileInterestId"
        />
    );
}
