import React, { FC, useContext, useMemo } from "react";
import { Log } from '../../../eenApi/log/log';
import { LogService } from '../../../eenApi/log/service';
import useTranslation from '../../../hooks/translation.hook';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { formatDateTime } from '../../../utils/date.utils';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { useIdColumn } from "../../../component/common/layout/DataTable/table.utils";
import { UserContext } from "../../../context/user-context";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { ObjectType } from "../../../enums/objectType";
import Rules from "../../../rules";
import { ClientService } from "../../../eenApi/client/service";
import { CenterService } from "../../../eenApi/center/service";
import { DriveService } from "../../../eenApi/drive/service";
import ProfileService from "../../../eenApi/profile/service";
import { SpecialistService } from "../../../eenApi/specialist/service";
import { NewsService } from "../../../eenApi/news/service";
import { Link } from "react-router-dom";
import { RoleService } from "../../../eenApi/role/service";
import { MenuService } from "../../../eenApi/menu/service";
import {EventRoutesPath} from "../../event/routes";
import {NotificationService} from "../../../eenApi/notification/service";
import {MailingRoutesPath} from "../../mailing/routes";

interface IProps {
    filterValues?: { [key: string]: any };
    language: string;
}

const LogTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);

    const renderObjectTypeCell = ( {row }: GridRenderCellParams<any, Log>) => {
      if (row.type.toLowerCase().includes('delete')) {
        return t(`main:object-type-${row.objectType}`)
      }

      let rule;
      let route;
      switch(row.objectType) {
        case ObjectType.Client:
          rule = Rules.Client.ViewBasic || Rules.Client.ViewContacts;
          route = ClientService.getViewLink(Number(row.objectId));
          break;
        case ObjectType.Center:
          rule = Rules.Center.View;
          route = CenterService.getDetailsRoute(row.objectId);
          break;
        case ObjectType.DriveFolder:
          rule = Rules.Drive.Public;
          route = DriveService.getViewFolderLink(row.objectId, 'public');
          break;
        case ObjectType.Profile:
          rule = Rules.Profile.ViewBasic || Rules.Profile.ViewContact || Rules.Profile.ViewValidation;
          route = ProfileService.getDetailsRoute(row.objectId);
          break;
        case ObjectType.Specialist:
          rule = Rules.Specialist.ViewBasic || Rules.Specialist.ViewContacts || Rules.Specialist.ViewValidations;
          route = SpecialistService.getDetailsRoute(row.objectId);
          break;
        case ObjectType.News:
          rule = Rules.News.View;
          route = NewsService.getDetailsRoute(row.objectId);
          break;
        case ObjectType.Role:
          rule = Rules.Role.Edit;
          route = RoleService.getEditLink(Number(row.objectId));
          break;
        case ObjectType.Menu:
          rule = Rules.Menu.Edit;
          route = MenuService.getEditLink(Number(row.objectId));
          break;
        case ObjectType.Event:
          rule = Rules.Event.View;
          route = EventRoutesPath.View.replace(':id', row.objectId);
          break;
        case ObjectType.Notification:
          rule = Rules.Notification.Edit;
          route = NotificationService.getEditLink(Number(row.objectId));
          break;
        case ObjectType.Mailing:
          rule = Rules.Mailing.Edit;
          route = MailingRoutesPath.Edit.replace(':id', row.objectId);
          break;
        case ObjectType.DriveFile:
        default:
          return t(`main:object-type-${row.objectType}`)
      }

      return (!rule || !route || !checkAccess(rule))
        ? t(`main:object-type-${row.objectType}`)
        : <Link to={route}>{t(`main:object-type-${row.objectType}`)}</Link>;
    }

    const idColumn = useIdColumn<Log>();
    const columns = useMemo<TableColumn<Log>[]>(
        () => [
          idColumn,
            new TableColumn({
                headerName: t('log:field-type'),
                field: 'type',
                hideable: false,
                valueFormatter: ({ value }) => t(`log:type-${value}`),
            }),
            new TableColumn({
                headerName: t('log:field-object-type'),
                field: 'objectType',
                hideable: false,
                renderCell: renderObjectTypeCell,
                width: 120,
            }),
            new TableColumn({
                headerName: t('log:field-object-id'),
                field: 'objectId',
            }),
            new TableColumn({
                headerName: t('main:field-user'),
                field: 'createdBy',
                valueGetter: ({ row }) => row.user?.fullName || row.user?.email,
            }),
            new TableColumn({
                headerName: t('main:field-description'),
                field: 'description',
                width: 120
            }),
            new TableColumn({
                headerName: t('main:field-ip'),
                field: 'ip',
                width: 130
            }),
            new TableColumn({
                headerName: t('main:field-date-and-time'),
                field: 'created',
                valueFormatter: ({ value }) => formatDateTime(value),
                width: 150,
            }),
        ],
        [i18n.language],
    );

    return (
        <DataTable
            id="admin-log-table-component"
            queryKey={EntityQueryKey.Log}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => LogService.list(requestData)}
        />
    );
};

export default LogTable;
