import { Route, Switch } from "react-router-dom";
import NotFound from "../../home/notFound";
import ProtectedRoute from "../../../component/common/ProtectedRoute";
import Rules from "../../../rules";
import UserList from "../list";
import UserDetail from "../details";
import UserEdit from "../edit";

export default function UserRoutes() {
    return (
        <Switch>
            <ProtectedRoute path={UserRoutesPath.List} exact component={UserList} rule={Rules.User.List} />
            <ProtectedRoute path={UserRoutesPath.View} exact component={UserDetail} rule={Rules.User.List} />
            <Route path={UserRoutesPath.Edit} exact component={UserEdit} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

export const UserRoutesPath = {
    Prefix: `/user`,
    List: `/user/list`,
    View: `/user/view/:id`,
    Create: `/user/edit/0`,
    Edit: `/user/edit/:id`
};
