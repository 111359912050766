import {
  GridCellClassNamePropType,
  GridColDef, GridColumnHeaderParams,
  GridRenderCellParams,
  GridSelectionModel, GridValidRowModel, GridValueFormatterParams, GridValueGetterParams
} from "@mui/x-data-grid";
import { ReactNode } from "react";

export class TableSettings {
  onPage: number;
  visibleColumns: string[];
  sortBy: string;
  sortOrder: 'asc'|'desc';

  constructor(json?: any, onPage?: number, sortBy?: string, sortOrder?: string) {
    this.onPage = json?.onPage ?? onPage ?? 10;
    this.visibleColumns = Array.isArray(json?.visibleColumns) ? json.visibleColumns : [];
    this.sortBy = json?.sortBy ?? sortBy ?? 'id';
    this.sortOrder = json?.sortOrder ?? sortOrder ?? 'asc';
  }
}

interface ITableColumn<T extends GridValidRowModel> extends GridColDef<T> {
  headerName: string
}

export class TableColumn<T extends GridValidRowModel> {
  field: string;
  headerName: string;
  sortable: boolean;
  renderCell?: ((params: GridRenderCellParams<any, T, any>) => ReactNode);
  valueFormatter?: (params: GridValueFormatterParams<any>) => any;
  valueGetter?: (params: GridValueGetterParams<any, T>) => any;
  width?: number;
  flex?: number;
  hideable?: boolean;
  cellClassName?: GridCellClassNamePropType;
  renderHeader?: ((params: GridColumnHeaderParams<any, T, any>) => ReactNode);
  type?: string;

  constructor(column: ITableColumn<T>) {
    this.field = column.field;
    this.headerName = column.headerName;
    this.sortable = column.sortable ?? false;
    this.renderCell = column.renderCell;
    this.valueFormatter = column.valueFormatter;
    this.valueGetter = column.valueGetter;
    this.width = column.width;
    if (!this.width) {
      this.flex = column.flex ?? 1
    }
    this.hideable = column.hideable ?? true;
    this.cellClassName = column.cellClassName;
    this.renderHeader = column.renderHeader;
    this.type = column.type
  }
}

interface TableAction {
  id: string;
  label: string;
  /**
   * По умолчанию всегда обновляется
   */
  refreshTableAfterAction?: boolean;
}

export interface TableGroupAction extends TableAction {
  action: (ids: GridSelectionModel) => Promise<void|any>;
}

export interface TableRowAction<T> extends TableAction {
  action: (item: T) => Promise<void|any>|void;
  isHide?: (item: T) => boolean;
}