import { ObjectType } from "../../enums/objectType";
import { UnviewedItemTypeEnum } from "./enum/unviewd.item.type";

export class MenuTemEditDto {
  id = 0;
  name = '';
  active = true;
  isAction = false;
  color = '#BBBBBB';
  helpHintId = '';
  icon = '';
  link = '';
  rule = '';
  objectTypes: ObjectType[] = [];
  unviewedItemType: UnviewedItemTypeEnum|'' = '';

  constructor(entity?: Partial<MenuTemEditDto>) {
    if (entity) {
      Object.assign(this, entity)
    }
    this.objectTypes = entity?.objectTypes ?? [];
  }
}