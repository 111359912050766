import { Box } from "@mui/material";
import { FC } from "react";
import CommentForm from "../CommentFormComp";

interface ReplyFormProps {
  parentId: number;
  objectId: number;
  objectType: string;
  handleCloseReplyForm: () => void;

}

const CommentReplyForm: FC<ReplyFormProps> = ({ parentId, objectId, objectType, handleCloseReplyForm }) => {
  return (
    <Box className="chat-commentReplyForm">
      <CommentForm
        objectId={objectId}
        objectType={objectType}
        parentId={parentId}
        handleCloseForm={handleCloseReplyForm}
      />
    </Box>
  );
};

export default CommentReplyForm;
