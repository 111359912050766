import React, { FC, useContext, useState } from "react";
import { MDBSwitch } from "mdb-react-ui-kit";
import { NotificationService } from "../../../../eenApi/notification/service";
import { ErrorHandler } from "../../../../service/errorHandler";
import { UserContext } from "../../../../context/user-context";
import Rules from "../../../../rules";
import {useCurrentUser} from "../../../../hooks/useCurrentUser";

interface IProps {
    userId: number;
    channel: string;
    channelAvailable: boolean;
    isSubscribed: boolean;
    notificationId: number;
}

const NotificationSubscriptionState: FC<IProps> = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, setState] = useState(() => {
        if (!props.channelAvailable) return false;
        return props.isSubscribed;
    });
    const { checkAccess } = useContext(UserContext);
    const { data: currentUser } = useCurrentUser();

    const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        try {
            const result = await NotificationService.subscribe({
                userId: props.userId,
                channel: props.channel,
                state: e.target.checked,
                notificationId: props.notificationId,
            });
            setState(result);
        } catch (err) {
            ErrorHandler.handle("change notification subscription", err);
        } finally {
            setIsLoading(false);
        }
    };

    const isDisabled = () => isLoading || !props.channelAvailable || (currentUser?.user?.id !== props.userId && !checkAccess(Rules.User.EditSubscriptions))

    return (
        <div className="d-flex">
            <div className={isLoading ? "subscription-state" : "subscription-state"}>
                <MDBSwitch checked={state} disabled={isDisabled()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)} />
            </div>
        </div>
    );
};

export default NotificationSubscriptionState;
