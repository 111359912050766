import React, { Suspense, useMemo } from 'react';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { Client } from '../../../eenApi/client/client';
import { ClientService } from '../../../eenApi/client/service';
import { ClientRoutesPath } from '../routes';
import TabItem from '../../../component/tabs/tabItem';
import Rules from '../../../rules';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import TabControls from '../../../component/tabs/tabControls';
import MainEditTab from '../../../component/client/MainEditTab';
import { ClientEditValidator } from '../../../utils/validators/client/edit.validator';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { DeleteModal } from '../../../component/common/ui';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';
import { LoaderTab } from "../../../component/common/layout";

const ContactsEditTab = React.lazy(() => import('../../../component/client/ContactsEditTab'));

export function ClientEdit() {
    const { data: currentUser } = useCurrentUser();
    const { t, i18n, data, entity, activeTab, setActiveTab, updateData, validateOn, tabs, setTabs, saveActions, isDeleteOpen, closeDelete, remove } =
        useEditPageWithDelete<Client>({
            dataFactory: (entity, language) => new Client(entity, language, currentUser?.rules),
            queryKey: 'client',
            handler: (data) => ClientService.addOrUpdate(data),
            validator: ClientEditValidator,
            applyRoute: ClientService.getEditLink,
            saveRoute: ClientService.getViewLink,
            cancelRoute: [ClientRoutesPath.List, ClientService.getViewLink],
            canDelete: Rules.Client.Delete,
            deleteHandler: (id) => ClientService.delete([Number(id)]),
        });

    const tabItems = useMemo(() => {
        return [new TabItem('main', t('client:tabs.main')), new TabItem('contacts', t('client:tabs.contacts'))];
    }, [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`client:${entity?.id ? 'edit-title' : 'create-title'}`)} backLink={ClientRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={saveActions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <LoaderTab activeId={activeTab}/>
                <MainEditTab activeId={activeTab} id="main" entity={entity} data={data} updateData={updateData} />
                <Suspense fallback={<></>}>
                    <ContactsEditTab activeId={activeTab} id="contacts" entity={entity} data={data} updateData={updateData} />
                </Suspense>
                <BottomActionsContainer>
                    <BottomActions actions={saveActions} />
                </BottomActionsContainer>
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}