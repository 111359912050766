import { EenApi } from '..';
import { CrudService } from '../common/service/crud.service';
import ChatEditDto from './dto/chat.edit.dto';

class ChatServiceImpl extends CrudService<ChatEditDto> {
    constructor() {
        super('chat');
    }

    fetchCommentList(requestData: any): Promise<ChatEditDto[]> {
        return EenApi.commentList<ChatEditDto>('chat/tree', requestData);
    }

    addComment(parentId: number | null, objectId: number, objectType: string, comment: string, fileIds: string[]): Promise<any> {
        return EenApi.post('chat/add', { parentId, objectId, objectType, comment, fileIds });
    }

    addReply(parentId: number, objectId: number, objectType: string, replyText: string): Promise<any> {
        return EenApi.post('chat/add', { parentId, objectId, objectType, comment: replyText });
    }

    save(data: ChatEditDto, ...args: any): Promise<any> {
        return EenApi.put<any>(`chat/edit`, data);
    }

    removeFile(fileIds: string, data: any): Promise<any> {
        return EenApi.put<any>(`chat/edit`, { ...data, fileIds });
    }

    updateComment(data: any, ...args: any): Promise<void> {
        return EenApi.put<any>(`chat/edit`, data);
    }
}

export const ChatService = new ChatServiceImpl();
