export default {
    List: 'news.list',
    View: 'news.view',
    Add: 'news.add',
    Edit: 'news.edit',
    Delete: 'news.delete',
    Validate: 'news.validate',
    NetworkList: 'news.networklist',
    OrganizationList: 'news.organizationlist',
};
