import React, { Suspense, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../component/actions/bottomActions';
import { ActionItem } from '../../../models/actionItem';
import { NewsService } from '../../../eenApi/news/service';
import { NewsEntity } from '../../../eenApi/news/news';
import { NewsRoutesPath } from '../routes';
import Rules from '../../../rules';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TabItem from '../../../component/tabs/tabItem';
import TabControls from '../../../component/tabs/tabControls';
import TopActions from '../../../component/actions/topActions';
import { DeleteModal } from '../../../component/common/ui';
import { useDeleteModal } from '../../../hooks/UseDeleteModal';
import { useDetailsStates } from '../../../hooks/UseDetailsStates';
import { Field, InfoTab, LoaderTab } from '../../../component/common/layout';
import NewsStatusEnum from '../../../eenApi/news/enum/news.status.enum';
import { useChangeStatusMutation } from '../../../hooks/UseChangeStatusMutation';
import { ObjectType } from '../../../enums/objectType';
import Loader from '../../../component/loader';

export function NewsView() {
    const { i18n, t, history, id, entity, activeTab, setActiveTab, checkAccess, queryClient } = useDetailsStates<NewsEntity>({
        queryKey: 'news',
    });

    const canEditNews = (checkAccess(Rules.News.Edit) && (NewsStatusEnum.Draft === entity?.status || NewsStatusEnum.Remarks === entity?.status));
    const canEditValidation = NewsStatusEnum.Validation === entity?.status && checkAccess(Rules.News.Validate);
    const isValidate = NewsStatusEnum.Validation === entity?.status || NewsStatusEnum.Remarks === entity?.status;
    const tabItems = useMemo(() => {
        return [
            new TabItem('main', t('news:tabs.news')),
            new TabItem('announcement', t('news:tabs.announcement')),
            new TabItem('detail', t('news:tabs.detail')),
        ];
    }, [i18n.language, entity?.id]);

    const { isDeleteOpen, closeDelete, remove, deleteButton } = useDeleteModal({
        queryKey: 'news',
        handler: (id) => NewsService.delete(id),
        successPath: NewsRoutesPath.CenterList,
    });

    const actions = useMemo<ActionItem[]>(() => {
        const items: ActionItem[] = [];
        if (canEditNews) {
            items.push({
                name: t('main:btn-edit'),
                icon: 'fas fa-pen',
                onClick: async () => {
                    await queryClient.invalidateQueries(['news', id]);
                    history.push(`${NewsRoutesPath.Edit.replace(':id', id.toString())}`);
                },

            });
        }
        if (canEditValidation) {
            items.push({
                name: t('main:btn-validate'),
                onClick: async () => {
                    await changeStatusMutation.mutate(NewsStatusEnum.Validation)
                    await queryClient.invalidateQueries(['news', id]);
                    history.push(`${NewsRoutesPath.Validate.replace(':id', id.toString())}`);
                },
                icon: 'far fa-check-circle',
            });
        }
        if (checkAccess(Rules.News.Delete)) {
            items.push(deleteButton);
        }
        return items;
    }, [id, i18n.language, entity]);

    const changeStatusMutation = useChangeStatusMutation<NewsStatusEnum>(
        (validationStatus) => NewsService.validate(id, validationStatus), 'news');

    return (
        <MainContainer>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
            <PageTitle text={t('news:view-title')} backLink={NewsRoutesPath.CenterList} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <LoaderTab activeId={activeTab} />
            {entity?.id &&
                <InfoTab
                    activeId={activeTab}
                    id="main"
                    entity={entity}
                    namespace="news"
                    fields={[
                        {
                            render: (entity) =>
                                <Field labelKey='news:field.title'
                                    html={true}
                                    value={entity.title}
                                    isValidate={isValidate && entity.status === NewsStatusEnum.Remarks}
                                    objectId={entity.id}
                                    objectType={ObjectType.News}
                                    fieldName={'title'}
                                    editable={canEditValidation && entity.status === NewsStatusEnum.Remarks}
                                />
                        },
                        {
                            render: (entity) =>
                                <Field labelKey='news:field.status'
                                    html={true}
                                    value={t(`news:status.${entity.status}`)}
                                />
                        },
                    ]}
                />
            }
            <Suspense fallback={<Loader />}>
                {entity?.id && entity?.detailDescription?.length ?
                    < InfoTab
                        activeId={activeTab}
                        id="detail"
                        entity={entity}
                        namespace="news"
                        fields={[
                            {
                                render: (entity) =>
                                    entity.detailFile?.source &&
                                    <Field key="detailFileId" labelKey="news:detail-picture" optional>
                                        <img src={`/${entity.detailFile.source}`} style={{ maxHeight: '200px' }} alt={t('news:detail-picture')} />
                                    </Field>

                            },
                            {
                                render: (entity) =>
                                    <Field labelKey='news:detail-description'
                                        html={true}
                                        value={entity.detailDescription}
                                        isValidate={isValidate}
                                        objectId={entity.id}
                                        objectType={ObjectType.News}
                                        fieldName={'detailDescription'}
                                        editable={canEditValidation}
                                    />,
                            },
                        ]}
                    /> : ""
                }
                {entity?.id && entity?.announcementDescription?.length ?
                    (<InfoTab
                        activeId={activeTab}
                        id="announcement"
                        entity={entity}
                        namespace="news"
                        fields={[
                            {
                                render: (entity) =>
                                    entity.announcementFile?.source && (
                                        <Field key="announcementFileId" labelKey="news:announcement-picture" optional>
                                            <img src={`/${entity.announcementFile.source}`} style={{ maxHeight: '200px' }} alt={t('news:detail-picture')} />
                                        </Field>
                                    )
                            },
                            {
                                render: (entity) =>
                                    <Field labelKey='news:announcement-description'
                                        html={true}
                                        value={entity.announcementDescription}
                                        isValidate={isValidate}
                                        objectId={entity.id}
                                        objectType={ObjectType.News}
                                        fieldName={'announcementDescription'}
                                        editable={canEditValidation}
                                    />
                            },
                        ]}
                    />) : ""
                }
            </Suspense>
            <BottomActionsContainer>
                <BottomActions actions={actions} />
            </BottomActionsContainer>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
};

export default NewsView;
