import EventDto from '../../../eenApi/event/dto/event.dto';
import ModelValidator from '../model.validator';

class EditValidator extends ModelValidator<EventDto, EventDto> {
    validate(dto: EventDto, tabs: string[], entity: EventDto) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.status, erroredTabs, 'main');
        this.checkRequired(dto.type, erroredTabs, 'main');
        this.checkRequired(dto.title, erroredTabs, 'main');
        this.checkRequired(dto.description, erroredTabs, 'main');
        this.checkRequired(dto.organizationHosting, erroredTabs, 'main');

        this.checkLength(dto.title, erroredTabs, 100, 'main');
        this.checkLength(dto.website, erroredTabs, 100, 'main');
        this.checkLength(dto.coOrganisers, erroredTabs, 100, 'main');
        this.checkLength(dto.otherPartners, erroredTabs, 100, 'main');
        this.checkLength(dto.organizationHosting, erroredTabs, 100, 'main');
        this.checkLength(dto.description, erroredTabs, 255, 'main');

        this.checkRequired(dto.city, erroredTabs, 'location');
        this.checkLength(dto.city, erroredTabs, 100, 'location');
        this.checkLength(dto.locationName, erroredTabs, 100, 'location');
        this.checkLength(dto.locationPhone, erroredTabs, 100, 'location');

        return erroredTabs;
    }
}
const EventEditValidator = new EditValidator();
export default EventEditValidator;
