import { Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import Rules from '../../../rules';
import NotFound from '../../home/notFound';
import EventDetails from '../details';
import EditEvent from '../edit';
import { EventList } from '../list';

export default function EventRoutes() {
    return (
        <Switch>
            <ProtectedRoute path={EventRoutesPath.List} component={EventList} rule={Rules.Event.List} />
            <ProtectedRoute path={EventRoutesPath.View} component={EventDetails} rule={Rules.Event.View} />
            <ProtectedRoute path={EventRoutesPath.Edit} component={EditEvent} rule={[Rules.Event.Edit, Rules.Event.EditActive]} />
            <ProtectedRoute path={EventRoutesPath.Add} component={EditEvent} rule={Rules.Event.Add} />
            <ProtectedRoute path={EventRoutesPath.Validate} exact render={(props) => <EventList {...props} validation={true} />} rule={Rules.Event.ListValidate} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

const prefix = '/event';
export const EventRoutesPath = {
    Prefix: `${prefix}`,
    Add: `${prefix}/create`,
    List: `${prefix}/list`,
    View: `${prefix}/:id/view`,
    Edit: `${prefix}/:id/edit`,
    Validate: `${prefix}/validation`,
};
