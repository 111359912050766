import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Filter from '../../../component/filter';
import FilterItemAsyncSelect from '../../../component/filter/types/filterItem/items/asyncSelect';
import FilterItemDateRange from '../../../component/filter/types/filterItem/items/date';
import FilterItemSelect from '../../../component/filter/types/filterItem/items/select';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { UserContext } from '../../../context/user-context';
import EventTypeEnum from '../../../eenApi/event/enum/event.type.enum';
import { MemberCountryService } from '../../../eenApi/membercountry/member.country.service';
import { useOptions } from '../../../hooks/select.options.hook';
import useTranslation from '../../../hooks/translation.hook';
import Rules from '../../../rules';
import { EventRoutesPath } from '../routes';
import { EventTable } from './table';

interface IProps {
    validation: boolean;
}

export function EventList({ validation = false }: IProps) {
    const { t, i18n } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
    const { checkAccess } = useContext(UserContext);

    const typeOptions = useOptions<EventTypeEnum>(EventTypeEnum, 'event:fields.type.');

    const filterItems = useMemo(() => {
        const items = [
            new FilterItemSelect('type', t('event:filter.type'), {
                isDefault: 'Y',
                options: typeOptions,
            }),
            new FilterItemDateRange('created', t('event:filter.creation-data'), { isDefault: 'Y' }),
            new FilterItemText('title', t('event:filter.title'), {
                isDefault: 'Y',
            }),
            new FilterItemAsyncSelect('memberCountryId', t('event:filter.country'), {
                isDefault: 'Y',
                loadOptions: MemberCountryService.selectLoadOptions,
            }),
        ];
        return items;
    }, [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={!validation ? t(`event:list.title`) : t(`event:validation.title`)} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                    {checkAccess(Rules.Event.Add) && (
                        <Link className="btn btn-success me-2" to={EventRoutesPath.Add}>
                            {t('main:btn-add')}
                        </Link>
                    )}
                </div>
                <Filter id="filter-event" items={filterItems} onFind={setFilterValues} query={{}} />
            </div>
            <EventTable validation={validation} filterValues={filterValues} />
        </MainContainer>
    );
}
