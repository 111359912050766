import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import { ActionItem } from '../../../models/actionItem';
import Rules from '../../../rules';
import { useDetailsStates } from '../../../hooks/UseDetailsStates';
import { InnovationProjectDto } from '../../../eenApi/innovationproject/dto/innovation.project.dto';
import { InnovationProjectService } from '../../../eenApi/innovationproject/service';
import { InnovationProjectRoutesPath } from '../routes';
import { InfoTab } from '../../../component/common/layout';
import { Field } from '../../../component/common/layout';
import { LinkField } from '../../../component/common/layout';
import { Link } from 'react-router-dom';
import { MemberCountryRoutesPath } from '../../membercountry/routes';
import { CenterRoutesPath } from '../../center/routes';
import { ProfilesTab } from '../../../component/innovationproject/ProfilesTab';
import { useCountryWarning } from '../../../hooks/UseCountryWarning';
import { useDeleteModal } from '../../../hooks/UseDeleteModal';
import { DeleteModal } from '../../../component/common/ui';
import { LoaderTab } from '../../../component/common/layout';

export function InnovationProjectDetails() {
    const { t, i18n, history, id, entity, activeTab, setActiveTab, checkAccess, checkAccessUser } = useDetailsStates<InnovationProjectDto>({
        queryKey: 'innovationproject',
    });

    const { isDeleteOpen, closeDelete, remove, deleteButton } = useDeleteModal({
        queryKey: 'innovationproject',
        handler: (id) => InnovationProjectService.remove(id),
        successPath: InnovationProjectRoutesPath.List,
    });

    useCountryWarning(entity, entity?.memberCountryDto, ...(entity?.partnerCountries ?? []));

    const tabItems = useMemo(() => {
        const items = [new TabItem('main', t('innovationproject:tab.main'))];
        if (entity?.partnerCountries?.length || entity?.partnerCenterDto?.id) {
            items.push(new TabItem('partners', t('innovationproject:tab.partners')));
        }
        items.push(new TabItem('profiles', t('innovationproject:tab.profiles')));
        return items;
    }, [entity, i18n.language]);

    const actions = useMemo(() => {
        const items: ActionItem[] = [];
        if (checkAccessUser(entity?.createdBy, Rules.InnovationProject, 'Edit')) {
            items.push({
                name: t('main:btn-edit'),
                onClick: async () => {
                    history.push(InnovationProjectRoutesPath.Edit.replace(':id', id));
                },
            });
        }

        if (checkAccessUser(entity?.createdBy, Rules.InnovationProject, 'Delete')) {
            items.push(deleteButton);
        }

        return items;
    }, [id, i18n.language, entity?.createdBy]);

    return (
        <MainContainer>
            <PageTitle text={t('innovationproject:title.details')} backLink={InnovationProjectRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <LoaderTab activeId={activeTab} />
            {entity?.id && (
                <>
                    <InfoTab
                        activeId={activeTab}
                        id="main"
                        entity={entity}
                        namespace="innovationproject"
                        fields={[
                            {
                                render: (entity) =>
                                    entity.logo?.source ? (
                                        <Field key="logoFileId" labelKey="innovationproject:field.logoFileId" optional>
                                            <img src={`/${entity.logo.source}`} style={{ maxHeight: '200px' }} alt={t('innovationproject:field.logoFileId')} />
                                        </Field>
                                    ) : null,
                            },
                            'name',
                            {
                                render: (entity) => (
                                    <LinkField key="webLink" labelKey="innovationproject:field.webLink" optional href={entity.webLink}>
                                        {entity.webLink}
                                    </LinkField>
                                ),
                            },
                            {
                                name: 'memberCountryId',
                                children: checkAccess(Rules.MemberCountry.View) ? (
                                    <Link to={`${MemberCountryRoutesPath.View.replace(':id', String(entity?.memberCountryDto?.id))}`}>
                                        {entity?.memberCountryDto?.name}
                                    </Link>
                                ) : (
                                    <>{entity?.memberCountryDto?.name}</>
                                ),
                            },
                            {
                                name: 'centerId',
                                children:
                                    checkAccess(Rules.Center.View) || checkAccess(Rules.Center.ViewOwn, [String(entity?.centerDto?.id)]) ? (
                                        <Link to={`${CenterRoutesPath.Details.replace(':id', String(entity?.centerDto?.id))}`}>{entity?.centerDto?.name}</Link>
                                    ) : (
                                        <>{entity?.centerDto?.name}</>
                                    ),
                            },
                            'description',
                        ]}
                    />
                    {(entity.partnerCountries?.length || entity?.partnerCenterDto?.id) && (
                        <InfoTab
                            activeId={activeTab}
                            id="partners"
                            entity={entity}
                            namespace="innovationproject"
                            fields={[
                                {
                                    name: 'partnerCountryIds',
                                    children: checkAccess(Rules.MemberCountry.View) ? (
                                        <>
                                            {entity.partnerCountries?.map((c) => (
                                                <Link key={c.id} to={`${MemberCountryRoutesPath.View.replace(':id', String(c.id))}`}>
                                                    {c.name}
                                                </Link>
                                            ))}
                                        </>
                                    ) : undefined,
                                    value: () => (!checkAccess(Rules.MemberCountry.View) ? entity.partnerCountries?.map((c) => c.name) : undefined),
                                },
                                {
                                    name: 'partnerCenterId',
                                    children:
                                        checkAccess(Rules.Center.View) || checkAccess(Rules.Center.ViewOwn, [String(entity?.centerDto?.id)]) ? (
                                            <Link to={`${CenterRoutesPath.Details.replace(':id', String(entity?.partnerCenterDto?.id))}`}>
                                                {entity?.partnerCenterDto?.name}
                                            </Link>
                                        ) : (
                                            <>{entity?.partnerCenterDto?.name}</>
                                        ),
                                },
                            ]}
                        />
                    )}
                    <ProfilesTab activeId={activeTab} id="profiles" innovationProjectId={entity.id} />
                    <BottomActionsContainer>
                        <BottomActions actions={actions} />
                    </BottomActionsContainer>
                </>
            )}
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}