import { useContext, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { UserContext } from '../../../context/user-context';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Rules from '../../../rules';
import { Link } from 'react-router-dom';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import FilterItemAsyncSelect from '../../../component/filter/types/filterItem/items/asyncSelect';
import Filter from '../../../component/filter';
import { MemberCountryService } from '../../../eenApi/membercountry/member.country.service';
import FilterItemSelect from '../../../component/filter/types/filterItem/items/select';
import { InnovationProjectStatusEnum } from '../../../eenApi/innovationproject/enum/innovation.project.status.enum';
import { useOptions } from '../../../hooks/select.options.hook';
import { InnovationProjectRoutesPath } from '../routes';
import { InnovationProjectTable } from './table';


export function InnovationProjectList() {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const statusOptions = useOptions(InnovationProjectStatusEnum, 'innovationproject:status.');
    const filterItems = useMemo(() => [
            new FilterItemText('name', t('innovationproject:field.name'), {isDefault: "Y"}),
            new FilterItemAsyncSelect('memberCountryId', t('innovationproject:field.memberCountryId'), { isDefault: "Y", loadOptions: MemberCountryService.selectLoadOptions }),
            new FilterItemSelect('status', t('innovationproject:field.status'), { options: statusOptions, isDefault: "Y" })
        ], [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t('innovationproject:title.list')} backLink='/' />
            <div className='d-xl-flex mb-1 mb-xl-3'>
                <div className='app-filter-actions mb-2 mb-xl-0'>
                    {checkAccess(Rules.InnovationProject.Add) &&
                        <Link className='btn btn-success me-2'
                              to={InnovationProjectRoutesPath.Create}>{t('main:btn-add')}</Link>
                    }
                </div>
                <Filter id="filter-innovation-project"
                        items={filterItems}
                        onFind={setFilterValues}
                        query={{}}
                />
            </div>
            <InnovationProjectTable filterValues={filterValues}/>
        </MainContainer>
    );
}