export enum ObjectType {
    Client = 'client',
    Center = 'center',
    DriveFolder = 'drivefolder',
    DriveFile = 'drivefile',
    SuccessStory = 'success-story',
    Profile = 'profile',
    Specialist = 'specialist',
    News = 'news',
    Role = 'role',
    Menu = 'menu',
    Event = 'event',
    Notification = 'notification',
    Mailing = 'mailing-message',
    Support = 'support',
    Interest = 'interest',
}
