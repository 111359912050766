import React, { FC, useContext, useMemo } from "react";
import { Link } from 'react-router-dom';
import useTranslation from '../../../../hooks/translation.hook';
import { NewsEntity } from '../../../../eenApi/news/news';
import { NewsService } from '../../../../eenApi/news/service';
import { NewsRoutesPath } from '../../routes';
import { useCurrentUser } from '../../../../hooks/useCurrentUser';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import { useGroupDeleteAction, useIdColumn, useRowActions } from '../../../../component/common/layout/DataTable/table.utils';
import { formatDate } from '../../../../utils/date.utils';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';
import { GridSelectionModel } from "@mui/x-data-grid";
import { UserContext } from "../../../../context/user-context";
import Rules from "../../../../rules";

interface IProps {
    filterValues?: { [key: string]: any };
}

const NewsCenterTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { data: currentUser } = useCurrentUser();
    const { checkAccess } = useContext(UserContext);

    const idColumn = useIdColumn<NewsEntity>();
    const columns = useMemo<TableColumn<NewsEntity>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('news:field.title'),
                field: 'title',
                sortable: true,
                renderCell: ({ id, value }) =>
                    checkAccess(Rules.News.View) ?
                        <Link to={`${NewsRoutesPath.View.replace(':id', id!.toString())}`}>{value}</Link> :
                        value
            }),
            new TableColumn({
                headerName: t('news:field.status'),
                field: 'status',
                valueFormatter: ({ value }) => t(`news:status.${value}`),
            }),
            new TableColumn({
                headerName: t('news:field.creation-data'),
                field: 'created',
                sortable: true,
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('news:field.change-data'),
                field: 'lastModified',
                sortable: true,
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('main:field-full-name'),
                field: 'createdBy',
                renderCell: ({ row }) =>
                    row.createdBy?.fullName
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => NewsService.delete(ids.map((x) => Number(x)));


    const rowActions = useRowActions<NewsEntity>({

        edit: (row) => NewsRoutesPath.Edit.replace(':id', String(row.id)),
        remove: (row) => clickDelete([row.id]),
        hideEdit: () => !checkAccess(Rules.News.Edit),
        hideRemove: () => !checkAccess(Rules.News.Delete),
    });

    const groupActions = useGroupDeleteAction({
        remove: checkAccess(Rules.News.Delete) ? clickDelete : null,
    });

    return (
        <DataTable
            id="center-news-table-component"
            queryKey={[EntityQueryKey.News, 'center']}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) =>
                NewsService.list({
                    ...requestData,
                    centerId: currentUser?.rules.find((x) => !!x.objectId)?.objectId,
                })
            }
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
};

export default NewsCenterTable;
