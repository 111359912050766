import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import {Autocomplete, CircularProgress, FormControl} from '@mui/material';
import { SelectValue } from "../../../select/SelectValue";
import {StyledTextField} from "../index";
import IControlProps from "../control.props";
import InputContainer from "../../../inputContainer";
import {useValidation} from "../../../../hooks/UseValidation";
import useTranslation from "../../../../hooks/translation.hook";
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import useDebounce from "../../../../hooks/debounce.hook";
import { ErrorHandler } from "../../../../service/errorHandler";
import { DictionaryType } from "../../../../models/dictionaryType";

interface IProps extends IControlProps {
   dictionary: DictionaryType,
   disabled?: boolean;
   options: (term: string|(string|number)[], language: string, skip?: number) => Promise<SelectValue[]>;
   all?: boolean
}

interface IPropsSingle extends IProps {
   multiple?: false;
   value: number | string | null;
   onChange: (selected: SelectValue | null) => void;
}

interface IPropsMulti extends IProps {
   multiple: true;
   value: number[]|string[];
   onChange: (selected: SelectValue[] | null) => void;
}

export default function AutocompleteControl(props: PropsWithChildren<IPropsSingle | IPropsMulti>) {
   const { t, i18n } = useTranslation();
   const validate = useValidation(props.validators);
   const { validateOn } = useContext(ValidationContext);

   const [inputValue, setInputValue] = useState('');
   const searchTerm = useDebounce(inputValue, 100);
   const [selected, setSelected] = useState<SelectValue|SelectValue[]|null>(props.multiple ? [] : null);
   const [errorText, setErrorText] = useState<string>();

   const queryClient = useQueryClient();
   const { data: initialValue } = useQuery([props.dictionary, i18n.language, props.multiple ? props.value : String(props.value ?? '')],
     () => props.value ? props.options(new Array<number|string>().concat(props.value), i18n.language) : Promise.resolve(null),
     {
        select: (data) => ((data && props.multiple) ? data : data?.[0] ?? null),
        onSuccess: (data) => {
           if (data === null || Array.isArray(data) && data.length === 0) {
              if (props.multiple) {
                 props.onChange([])
              }
              if (!props.multiple) {
                 props.onChange(null)
              }
           }
        },
        onError: () => {
           setSelected(props.multiple ? [] : null)
        }
     });
   useEffect(() => {
      setSelected(initialValue ?? (props.multiple ? [] : null))
      if (initialValue) {
         props.onChange(initialValue as any)
      }
   }, [initialValue])

   const fetchItems = ({ pageParam } :any) => {
      return props.options(searchTerm, i18n.language, pageParam)
   };

   const {
      data: options,
      fetchNextPage,
      hasNextPage,
      isFetching,
      isFetchingNextPage
   } = useInfiniteQuery([props.dictionary, i18n.language, searchTerm], params => fetchItems(params), {
      getNextPageParam: (lastPage, allPages) => {
         if (props.all || lastPage?.length < 20) return false;
         const currentCount = allPages.flatMap(x => x).length;
         return currentCount > 0 ? currentCount : false;
      },
      select: data => {
         const items = data?.pages.flatMap(d => d ?? []) ?? [];
         return {
            pages: items,
            pageParams: [items.length]
         }
      },
      onError: (err) => {
         ErrorHandler.handle(`Async select load failed`, err);
         setSelected(props.multiple ? [] : null)
      }
   });

   useEffect(() => {
      setErrorText(props.error || validate(props.value, props.tab ?? 'main'))
   }, [props.value, props.error, validateOn]);

   return (
      <InputContainer className={props.className}>
      <FormControl required={props.required} error={!!props.error} fullWidth size="small">
         <Autocomplete
            noOptionsText={t('main:select:notFound')}
            loadingText={t('main:loading')}
            autoComplete
            value={selected}
            filterOptions={(x) => x}
            includeInputInList={!props.multiple}
            onChange={(event: React.SyntheticEvent, newValue: SelectValue | SelectValue[] | null) => {
               if (selected != null && (!Array.isArray(selected) || selected.length)) {
                  queryClient.setQueryData([props.dictionary, i18n.language, Array.isArray(selected) ? selected.map(x => x.value) : String(selected.value)],
                    new Array<SelectValue>().concat(selected));
               }
               if (props.multiple && Array.isArray(newValue)) {
                  props.onChange(newValue);
                  return;
               }
               if (!props.multiple && !Array.isArray(newValue)) {
                  props.onChange(newValue);
                  return;
               }
               props.onChange(null);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
               setInputValue(newInputValue);
            }}
            isOptionEqualToValue={(option, selectValue) => option.value === selectValue.value}
            getOptionLabel={(option) => option.label}
            options={options?.pages ?? []}
            loading={isFetching || isFetchingNextPage}
            multiple={props.multiple}
            disabled={props.disabled}
            renderInput={(params) => (
               <StyledTextField
                  {...params}
                  size="small"
                  label={t(props.labelKey)}
                  required={props.required}
                  error={!!errorText}
                  helperText={errorText}
                  InputProps={{
                     ...params.InputProps,
                     endAdornment: (
                        <React.Fragment>
                           {(isFetching || isFetchingNextPage) ? <CircularProgress color="inherit" size={20}/> : null}
                           {params.InputProps.endAdornment}
                        </React.Fragment>
                     ),
                  }}
               />
            )}
            renderOption={(props, option) => {
               return (
                 <li {...props} key={option.value}>
                    {option.label}
                 </li>
               );
            }}
            ListboxProps={{
               onScroll: (event: React.SyntheticEvent) => {
                  const listboxNode = event.currentTarget;
                  if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
                     if (hasNextPage && !isFetchingNextPage) {
                        return fetchNextPage()
                     }
                  }
               }
            }}
         />
      </FormControl>
         {props.children}
      </InputContainer>
   );
}