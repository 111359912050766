import { Save, Cancel, Edit, Delete, Reply, Clear } from "@mui/icons-material";
import { Box, Avatar, Typography, TextField, IconButton, ListItemIcon } from "@mui/material";
import { FC, useContext, useState } from "react";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import ChatEditDto from "../../../eenApi/chat/dto/chat.edit.dto";
import { ChatService } from "../../../eenApi/chat/service";
import { ToastManager } from "../../../service/toastManager";
import { formatDateTime } from "../../../utils/date.utils";
import { DeleteModal } from "../../common/ui";
import getFileIcon from "../CommentIcon";
import CommentReplyForm from "../CommentReplyFormComp";
import DriveFileDto from "../../../eenApi/drive/dto/drive.file.dto";
import { UserContext } from "../../../context/user-context";
import ViewField from "../../fields/viewField";
import FileUploader from "../../fileUploader";
import FileList from "../../fields/fileList";
import Rules from "../../../rules";
import useTranslation from "../../../hooks/translation.hook";


interface CommentProps {
  comment: ChatEditDto;
  objectId: number;
  objectType: string;
  parentId: number;
  onDeleteFile: (updatedFiles: DriveFileDto[] | undefined, commentId: number) => void;
}

const CommentTextComp: FC<CommentProps> = ({
  comment,
  objectId,
  objectType,
  parentId,
  onDeleteFile,
}) => {
  const { checkAccess } = useContext(UserContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [onEditComment, setOnEditComment] = useState(false);
  const [editedComment, setEditedComment] = useState(comment.comment);
  const [attachedFiles, setAttachedFiles] = useState<DriveFileDto[]>(comment.files || []);
  const [isDeleteFileDialogOpen, setIsDeleteFileDialogOpen] = useState(false);
  const [fileIdToDelete, setFileIdToDelete] = useState<string | null>(null);

  const openDelete = () => setIsDeleteOpen(true);
  const closeDelete = () => setIsDeleteOpen(false);

  const handleToggleReplyForm = () => setShowReplyForm((prevState) => !prevState);
  const handleCloseReplyForm = () => setShowReplyForm(false);

  const handleEditComment = () => setOnEditComment(true);
  const handleCancelEditComment = () => { setEditedComment(comment.comment); setOnEditComment(false); };

  const handleDeleteComment = () => openDelete();
  const handleSaveComment = async () => {
    const updatedComment = {
      ...comment,
      comment: editedComment,
      objectId,
      objectType,
      parentId,
      fileIds: attachedFiles.map(file => file.id) || [],
      files: attachedFiles,
    };
    await saveCommentMutation.mutateAsync(updatedComment);
    handleCloseReplyForm();
  };

  const onUploaded = (file: DriveFileDto) => {
    setAttachedFiles((prevFiles) => [...prevFiles, file]);
  };

  const onRemoveFile = (fileId: string) => {
    setAttachedFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
  };

  const openDeleteFileDialog = (fileId: string) => {
    setFileIdToDelete(fileId);
    setIsDeleteFileDialogOpen(true);
  };
  const closeDeleteFileDialog = () =>
    setIsDeleteFileDialogOpen(false);

  const handleRemoveFile = (fileId: string) => {
    openDeleteFileDialog(fileId);
  };

  const confirmFileDelete = () => {
    if (fileIdToDelete) {
      const updatedFiles = attachedFiles.filter((file) => file.id !== fileIdToDelete);
      setAttachedFiles(updatedFiles);
      onDeleteFile(updatedFiles, comment.id);
      const delFiles = {
        ...comment,
        fileIds: [],
        files: [],
        objectId,
        objectType,
        parentId,
      };
      saveCommentMutation.mutate(delFiles);
    }
    closeDeleteFileDialog();
  };

  const executeModalAction = (mutation: UseMutationResult<any, unknown, void>) =>
    mutation.mutate();

  const deleteMutation = useMutation(() => ChatService.remove(comment.id!), {
    onSuccess: async () => {
      ToastManager.success({
        title: "main:msg-success",
        message: "main:msg-entity-deleted",
      });
      queryClient.invalidateQueries(["chat", objectType, objectId]);
      await queryClient.removeQueries(["chat", objectType, objectId]);
      closeDelete();
    },
  });

  const saveCommentMutation = useMutation(ChatService.save, {
    onSuccess: () => {
      ToastManager.success({
        title: "main:msg-success",
        message: "main:msg-changes-saved",
      });
      queryClient.invalidateQueries(["chat", objectType, objectId]);
      setOnEditComment(false);
    },
    onError: () => {
      ToastManager.error({
        title: "main:bad-request-error-title",
        message: "main:unhandled-exception-error-message",
      });
    },
  });

  return (
    <Box className={"chat-comment"} id={String(comment.id)}>
      <Box className={"chat-commentHeader"}>
        <Avatar className={'chat-commentAvatar'}>
          {comment.createdBy?.fullName[0]}
        </Avatar>
        <Box className={"userdata"}>
          <Typography className={'chat-commentFullName'} variant="subtitle1" component="span">
            {comment.createdBy?.fullName}
          </Typography>
          <Typography className={"chat-commentDate"}>{formatDateTime(comment.created)}</Typography>
        </Box>
      </Box>
      {
        onEditComment ? (
          <Box>
            <TextField
              value={editedComment} onChange={(e) => setEditedComment(e.target.value)}
              multiline rows={4} variant="outlined" fullWidth
            />
            <Box className="chat-commentUpload">
              <ViewField title={t(`chat:fields.addFiles`)}>
                <FileList
                  files={attachedFiles}
                  onRemove={onRemoveFile}
                />
              </ViewField>
              <FileUploader onFileUploaded={onUploaded} />
            </Box>
            <Box mt={1}>
              <IconButton onClick={handleSaveComment}>
                <Save />
              </IconButton>
              <IconButton onClick={handleCancelEditComment}>
                <Cancel />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box >
            <Typography className={'chat-commentText'}>{comment.comment}</Typography>
            <Box className={"chat-commentAction"} >
              <Box className={"chat-commentButtons"}>
                {checkAccess(Rules.Chat.Edit) && (
                  <IconButton onClick={handleEditComment}>
                    <Edit />
                  </IconButton>)}
                {checkAccess(Rules.Chat.Delete) && (
                  <IconButton onClick={handleDeleteComment}>
                    <Delete />
                  </IconButton>)}
                <IconButton onClick={() => handleToggleReplyForm()}>
                  <Reply />
                </IconButton>
              </Box>
              <Box className={"chat-commentAttach"}>
                {
                  attachedFiles.map((file, index) => (
                    <Box key={file.id}>
                      <ListItemIcon>
                        {getFileIcon(file.name)}
                        <a href={`/${file.source}`} download={file.name}>
                          {file.name}
                        </a>
                      </ListItemIcon>
                      <IconButton onClick={() => handleRemoveFile(file.id)}>
                        <Clear />
                      </IconButton>
                    </Box>
                  ))
                }
              </Box>
            </Box>
          </Box>
        )
      }
      {
        showReplyForm && (
          <CommentReplyForm objectId={objectId} objectType={objectType} parentId={comment.id} handleCloseReplyForm={handleCloseReplyForm} />
        )
      }
      {
        comment.children && comment.children.map((reply: ChatEditDto, idx) => (
          <Box key={idx} className={"chat-commentReplyForm"}>
            <CommentTextComp comment={reply} objectId={objectId} objectType={objectType} parentId={comment.id} onDeleteFile={onDeleteFile} />
          </Box>
        ))
      }
      <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={() => executeModalAction(deleteMutation)} />
      <DeleteModal isDeleteOpen={isDeleteFileDialogOpen} close={closeDeleteFileDialog} remove={confirmFileDelete} />
    </Box >
  );
};

export default CommentTextComp;