import * as React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { NewsRoutesPath } from '../../../routes';
import { formatDate } from '../../../../../utils/date.utils';
import NewsDto from '../../../../../eenApi/news/dto/news.dto';
import { CenterRoutesPath } from '../../../../center/routes';

const Wrapper = styled.div`
  background: #FFFFFF;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 20px;
`;

const StyledImg = styled.img`
  height: 105px;
  width: 140px;
  margin-right: 20px;
`;

const StyledCreated = styled.div`
  color: #b2bbc2;
  fill: #b2bbc2;
  font-size: 12px;
  margin-top: auto;
  bottom: 0;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledDescription = styled.div`
  margin-top: 15px;

  p {
    margin-bottom: 0px;
  }
`;


interface INewsCardProps {
  news: NewsDto,
}

export const NewsCard: React.FC<INewsCardProps> = ({
  news,
}) => {

  return (
    <Wrapper>
      {news.announcementFile &&
        <StyledImg src={`/${news.announcementFile!.source!.replace('\\', '/')}`}
        />
      }
      <ContentContainer>
        <Link to={NewsRoutesPath.View.replace(':id', news.id.toString())}>{news.title}</Link>
        <StyledDescription dangerouslySetInnerHTML={{ __html: news.announcementDescription! }} />
        <StyledCreated>{formatDate(news.created)}</StyledCreated>
        {news.centerId && <Link to={CenterRoutesPath.Details.replace(':id', news.centerId!.toString())}>{news.centerName}</Link>}
      </ContentContainer>
    </Wrapper>
  );
};