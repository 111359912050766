export default {
    Add: 'user.add',
    List: 'user.list',
    Edit: 'user.edit',
    AddRole: 'user.addrole',
    ListRoles: 'user.listroles',
    DeleteRole: 'user.deleterole',
    Delete: 'user.delete',
    ChangeEmailVerified: 'user.changeemailverified',
    ChangeActive: 'user.changeactive',
    EditSubscriptions: 'user.editsubscriptions',
    ViewNotifications: 'user.viewnotifications',
};
