import React, { FC, useCallback, useContext, useState } from "react";
import useTranslation from "../../../hooks/translation.hook";
import { MDBBtn, MDBSpinner } from "mdb-react-ui-kit";
import { Link, useLocation } from "react-router-dom";
import DefaultBlock from "../../../component/defaultBlock";
import MainContainer from "../../../component/mainContainer";
import PageTitle from "../../../component/pageTitle";
import { ConfigurationService } from "../../../eenApi/configuration/service";
import { ExternalProviderService } from "../../../eenApi/externalProvider/service";
import { ToastManager } from "../../../service/toastManager";
import { SettingsRoutesPath } from "../routes";
import EmailSendConfigurationDto from "../../../eenApi/configuration/email.send.configuration.dto";
import { UseMutateFunction, useMutation, useQuery, useQueryClient } from "react-query";
import { resetQueryFn } from "../../../query/query.utils";
import TopActions from "../../../component/actions/topActions";
import TopActionsContainer from "../../../component/actions/topActionsContainer";
import { Field } from "../../../component/common/layout";
import { AutocompleteControl } from "../../../component/common/ui";
import { DictionaryType } from "../../../models/dictionaryType";
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { NotEmpty } from "../../../hooks/UseValidation/validators";
import { EmailSendConfigurationValidator } from "../../../utils/validators/settings/common.mailemg.validator";
import { UserContext } from "../../../context/user-context";
import Rules from "../../../rules";

const SettingsNotification: FC = () => {
    const { t } = useTranslation();
    const { state } = useLocation<{ deliveryProvider?: number, mailingProvider?: number, notificationProvider?: number }>();
    const { checkAccess } = useContext((UserContext));

    const queryClient = useQueryClient();
    const { data: mailingEntity } = useQuery('configuration-mailing', ConfigurationService.getMailing, {
        onSuccess: data => {
            setMailingData(new EmailSendConfigurationDto(data, state?.mailingProvider));
        }
    });
    const { data: notificationEntity } = useQuery('configuration-notification', ConfigurationService.getNotification, {
        onSuccess: data => {
            setNotificationData(new EmailSendConfigurationDto(data, state?.notificationProvider));
        }
    });

    const [mailingData, setMailingData]
        = useState(new EmailSendConfigurationDto(mailingEntity, state?.mailingProvider));
    const [notificationData, setNotificationData]
        = useState(new EmailSendConfigurationDto(notificationEntity, state?.notificationProvider));

    const { mutate: saveMailing, isLoading: isMailingMutating } = useMutation((data: EmailSendConfigurationDto) => ConfigurationService.saveMailing(data), {

        onSuccess: () => {
            console.log("saveMailing", saveMailing)
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            return queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, 'configuration-mailing') });
        }
    });
    const { mutate: saveNotification, isLoading: isNotificationMutating } = useMutation((data: EmailSendConfigurationDto) => ConfigurationService.saveNotification(data), {
        onSuccess: () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            return queryClient.resetQueries({ predicate: (query) => resetQueryFn(query, 'configuration-notification') });
        }
    });

    const [validateOn, setValidateOn] = useState(false);
    const [tabs, setTabs] = useState<string[]>([]);

    const save = useCallback((mutation: UseMutateFunction<void, unknown, EmailSendConfigurationDto>, data: EmailSendConfigurationDto) => {
        setValidateOn(true);
        const erroredTabs = EmailSendConfigurationValidator.validate(data);
        if (erroredTabs.length === 0) {
            mutation(data);
        }
    }, []);

    return (
        <MainContainer>
            <PageTitle text={t('settings:page-title-mailing')} backLink={SettingsRoutesPath.Prefix} />
            <TopActionsContainer>
                <TopActions actions={[]} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <DefaultBlock>
                    {checkAccess(Rules.Configuration.EditMailing) && (
                        <Field labelKey="settings:title-mailing" vertical>
                            <div className="flex-grow-1">
                                <AutocompleteControl
                                    value={mailingData.providerId}
                                    onChange={(val) =>
                                        setMailingData((prev) => ({
                                            ...prev,
                                            providerId: Number(val?.value) || null,
                                        }))
                                    }
                                    dictionary={DictionaryType.ExternalProvider}
                                    options={ExternalProviderService.selectLoadOptions}
                                    labelKey="settings:field-provider"
                                    className="d-flex"
                                    required
                                    validators={[NotEmpty]}>
                                    <Link
                                        className="btn btn-outline-success btn-md ms-1 px-3"
                                        style={{ height: 40 }}
                                        to={{
                                            pathname: ExternalProviderService.getEditLink(0),
                                            state: {
                                                returnParam: 'mailingProvider',
                                            },
                                        }}>
                                        <i className="fas fa-plus mt-1" />
                                    </Link>
                                </AutocompleteControl>
                            </div>
                            <div className="mb-3">
                                <MDBBtn color="primary" disabled={isMailingMutating} onClick={() => save(saveMailing, mailingData)}>
                                    {t('main:btn-save')}
                                    {isMailingMutating && <MDBSpinner size="sm" role="status" tag="span" className="ms-2" />}
                                </MDBBtn>
                            </div>
                        </Field>
                    )}
                    {checkAccess(Rules.Configuration.EditNotification) && (
                        <Field labelKey="settings:title-notification" vertical>
                            <div className="flex-grow-1">
                                <AutocompleteControl
                                    value={notificationData.providerId}
                                    onChange={(val) =>
                                        setNotificationData((prev) => ({
                                            ...prev,
                                            providerId: Number(val?.value) || null,
                                        }))
                                    }
                                    dictionary={DictionaryType.ExternalProvider}
                                    options={ExternalProviderService.selectLoadOptions}
                                    labelKey="settings:field-provider"
                                    className="d-flex"
                                    required
                                    validators={[NotEmpty]}>
                                    <Link
                                        className="btn btn-outline-success btn-md ms-1 px-3"
                                        style={{ height: 40 }}
                                        to={{
                                            pathname: ExternalProviderService.getEditLink(0),
                                            state: {
                                                returnParam: 'notificationProvider',
                                            },
                                        }}>
                                        <i className="fas fa-plus mt-1" />
                                    </Link>
                                </AutocompleteControl>
                            </div>
                            <div className="mb-3">
                                <MDBBtn color="primary" disabled={isNotificationMutating} onClick={() => save(saveNotification, notificationData)}>
                                    {t('main:btn-save')}
                                    {isNotificationMutating && <MDBSpinner size="sm" role="status" tag="span" className="ms-2" />}
                                </MDBBtn>
                            </div>
                        </Field>
                    )}
                </DefaultBlock>
            </ValidationContext.Provider>
        </MainContainer>
    );
}

export default SettingsNotification;
