import { EenApi } from '..';
import { SelectValue } from '../../component/select/SelectValue';
import UserEditDto from './dto/user.edit.dto';
import { User } from './user';
import { UserRole } from './userRole';

class CUserService {
    async list(requestData: any) {
        return await EenApi.list<User>('user/list', requestData);
    }

    async save(data: UserEditDto): Promise<any> {
        return await EenApi.put<any>('user/edit', data);
    }

    async delete(id: number[]) {
        return await EenApi.delete('user/delete', { ids: id });
    }

    async get(id: number) {
        return await EenApi.get<User>(`user/get/${id}`);
    }

    async userRoles(requestData: any) {
        return await EenApi.list<UserRole>('user/roles', requestData);
    }

    async addRole(userId: number, roleId: number, clientId: number, centerId: number) {
        await EenApi.post('user/addRole', {
            userId: userId,
            roleId: roleId,
            clientId: clientId > 0 ? clientId : undefined,
            centerId: centerId > 0 ? centerId : undefined,
        });
    }

    async deleteRole(ids: number[]) {
        await EenApi.delete(`user/deleteRole`, { ids: ids });
    }

    /**
     * link /user/view/${id}
     * @param id User ID
     * @returns
     */
    getDetailLink(id: number) {
        return `/user/view/${id}`;
    }

    getEditLink(id: number) {
        return `/user/edit/${id}`;
    }

    getUserName(user: User): string {
        // if (user.firstName && user.firstName.length > 0) {
        //     return `${user.firstName} ${user.lastName}`.trim();
        // }
        // if (user.lastName && user.lastName.length > 0) {
        //     return user.lastName;
        // }
        return user.email;
    }

    async selectLoadOptions(inputValue: string, valueField: keyof User = 'id'): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {
                search: inputValue,
            },
        };
        const data = await UserService.list(rd);
        data.items.forEach((item) => {
            arItems.push({
                value: `${item[valueField]}` ?? '',
                label: UserService.getUserName(item),
            });
        });
        return arItems;
    }

    async selectLoadUserOptions(inputValue: string | (string | number)[], language: string, skip: number = 0): Promise<SelectValue[]> {
        const filter: Record<string, any> = {
            skip,
            name: typeof inputValue === 'string' ? inputValue?.trim() : undefined,
        };

        const rd = {
            filter,
            language,
        };
        const data = await UserService.list(rd);

        const ids = Array.isArray(inputValue) ? inputValue.map((id) => String(id)) : null;
        return data.items
            .filter((item) => !ids || ids.includes(String(item.id)))
            .map((item) => ({
                value: `${item.id}`,
                label: item.email ?? '',
            }));
    }

    async selectUserOptions(inputValue: string, language?: string): Promise<SelectValue[]> {
        const arItems: SelectValue[] = [];
        const rd = {
            filter: {
                fullName: inputValue,
            },
            language,
        };
        const data = await UserService.list(rd);
        data.items.forEach((item) => {
            arItems.push({
                value: `${item.userId}` ?? '',
                label: item.fullName ?? '',
            });
        });
        return arItems;
    }
}

export const UserService = new CUserService();
