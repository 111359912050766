import { FC, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from 'mdb-react-ui-kit';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import useTranslation from '../../hooks/translation.hook';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Button } from '@mui/material';
import { UserRoutesPath } from '../../page/user/routes';
import UserEditDto from '../../eenApi/user/dto/user.edit.dto';
import { useQuery } from 'react-query';

const LoginMenu: FC = () => {
    const { data: currentUser } = useCurrentUser();
    const userId = String(currentUser?.user?.id);

    if (!currentUser?.user) {
        return <AnonymousView registerPath={ApplicationPaths.Register} loginPath={ApplicationPaths.Login} />;
    } else {
        const userProfilePath = UserRoutesPath.View.replace(':id', userId);
        const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
        return <AuthenticatedView userProfilePath={userProfilePath} logoutPath={logoutPath} />;
    }
};

const AuthenticatedView: FC<{ userProfilePath: string; logoutPath: { pathname: string; state: { local: boolean } } }> = ({
    userProfilePath,
    logoutPath,
}) => {
    const { t } = useTranslation();
    const { data: currentUser } = useCurrentUser();
    const userId = String(currentUser?.user?.id);

    const { data: entity } = useQuery<UserEditDto>(['user', userId, currentUser?.language], {
        onSuccess: async (data) => {
            setData(new UserEditDto(data, currentUser?.language!));
        },
    });
    const [data, setData] = useState<UserEditDto>(new UserEditDto(entity, currentUser?.language!));

    return (
        <li className="nav-item">
            <MDBDropdown>
                <MDBDropdownToggle tag="a" className="nav-link" role={'button'}>
                    {data.fullName}
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                    <MDBDropdownItem>
                        <Link className="dropdown-item" to={userProfilePath}>
                            {t('authentication:profile')}
                        </Link>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                        <Link className="dropdown-item" to={logoutPath}>
                            {t('authentication:logout')}
                        </Link>
                    </MDBDropdownItem>
                </MDBDropdownMenu>
            </MDBDropdown>
        </li>
    );
};

const AnonymousView: FC<{ registerPath: string; loginPath: string }> = ({ registerPath, loginPath }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <>
            <li>
                <Button onClick={() => history.push(registerPath)} sx={{ color: 'white' }}>
                    {t('authentication:register')}
                </Button>
            </li>
            <li>
                <Button onClick={() => history.push(loginPath)} sx={{ color: 'white' }}>
                    {t('authentication:login')}
                </Button>
            </li>
        </>
    );
};

export default LoginMenu;
