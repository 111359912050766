import { FC, useEffect } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import Menu from '../menu/menu';
import Footer from './footer';
import Header from './header';
import RoleRoutes, { RoleRoutesPath } from '../../page/role/routes';
import SettingsRoutes, { SettingsRoutesPath } from '../../page/settings/routes';
import UserRoutes, { UserRoutesPath } from '../../page/user/routes';
import HomeRoutes, { HomeRoutesPath } from '../../page/home/routes';
import MenuRoutes, { MenuRoutesPath } from '../../page/menu/routes';
import NotificationRoutes, { NotificationRoutesPath } from '../../page/notification/routes';
import CenterRoutes, { CenterRoutesPath } from '../../page/center/routes';
import ClientRoutes, { ClientRoutesPath } from '../../page/client/routes';
import ChatRoutes, { ChatRoutesPath } from '../../page/chat/routes';
import LogRoutes, { LogRoutesPath } from '../../page/log/routes';
import DictionaryRoutes, { DictionaryRoutesPath } from '../../page/dictionary/routes';
import ExternalProviderRoutes, { ExternalProviderRoutesPath } from '../../page/externalProvider/routes';
import { ApplicationPaths } from '../auth-api/ApiAuthorizationConstants';
import ApiAuthorizationRoutes from '../../page/authentication/routes';
import DriveRoutes, { DriveRoutesPath } from '../../page/drive/routes';
import ProfileRoutes, { ProfileRoutesPath } from '../../page/profile/routes';
import SupportRoutes, { SupportRoutesPath } from '../../page/support/routes';
import NewsRoutes, { NewsRoutesPath } from '../../page/news/routes';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import SplashScreen from './splashScreen';
import { UserContext } from '../../context/user-context';
import { i18nGlobal } from '../../i18n';
import SpecialistRoutes, { SpecialistRoutesPath } from '../../page/specialist/routes';
import InnovationProjectRoutes, { InnovationProjectRoutesPath } from '../../page/innovationproject/routes';
import MemberCountryRoutes, { MemberCountryRoutesPath } from '../../page/membercountry/routes';
import { intersection } from 'lodash';
import Rules from '../../rules';
import { IRestrictedEntity } from '../../models/restricted.entity';
import MailingRoutes, { MailingRoutesPath } from '../../page/mailing/routes';
import EventRoutes, { EventRoutesPath } from '../../page/event/routes';
import EditMailingUnsubscribe from "../../page/mailing/mailings/unsubscribe/edit";

const Layout: FC = () => {
    const { data: currentUser, isFetched } = useCurrentUser();

    useEffect(() => {
        if (!currentUser) return;

        changeLanguage(currentUser?.language);
    }, [currentUser?.language]);

    const changeLanguage = (lang: string) => {
        i18nGlobal.changeLanguage(lang);
    };

    if (!isFetched) {
        return <SplashScreen />;
    }

    if (!currentUser?.user) {
        return (
            <>
                <Header />
                <Switch>
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <Route path={MailingRoutesPath.UnsubscribePublic} component={EditMailingUnsubscribe} />
                    <Redirect to={ApplicationPaths.Login} />
                </Switch>
                <Footer />
            </>
        );
    }

    const checkAccess = (key: string, objectIds?: string[] | null, memberCountryId?: number | string): boolean => {
        if (!currentUser?.user) return false;
        if (key === Rules.Public) return true;
        return currentUser?.rules.some(
            (x) =>
                x.rules.includes(key) &&
                (!objectIds || !x.objectId || objectIds.includes(x.objectId)) &&
                (!memberCountryId || !x.objectType || x.memberCountryId === Number(memberCountryId)),
        );
    };

    const checkAccessPublic = (key: string | string[]): boolean => key.includes(Rules.Public);

    const checkAccessAdmin = (...keys: [string, ...string[]]): boolean => {
        return checkAccessPublic(keys) || (currentUser?.rules?.some((x) => !x.objectId && intersection(x.rules, keys).length > 0) ?? false);
    };

    const checkAccessOwner = (key: string, objectId: string | number, objectType?: string): boolean => {
        return (
            checkAccessPublic(key) ||
            (currentUser?.rules?.some((x) => (!objectType || x.objectType === String(objectType)) && objectId === x.objectId && x.rules.includes(key)) ?? false)
        );
    };

    const checkAccessUser = <T extends Record<string, string>>(
        userId: string | undefined,
        rules: T,
        ...checkRule: [Extract<keyof T, string>, ...Extract<keyof T, string>[]]
    ) => {
        if (!currentUser?.user?.userId || !currentUser?.rules?.length) return false;
        return (
            currentUser.rules
                .filter((x) => !x.objectId)
                .some(
                    (x) =>
                        intersection(
                            x.rules,
                            checkRule.map((r) => rules[r]),
                        ).length,
                ) ||
            (currentUser.rules.some(
                (x) =>
                    intersection(
                        x.rules,
                        checkRule.map((r) => rules[`${r}Own`]),
                    ).length,
            ) &&
                currentUser.user.userId === userId)
        );
    };

    const checkAccessCountry = <T extends Record<string, string>>(
        countryId: number | undefined,
        rules: T,
        ...checkRule: [Extract<keyof T, string>, ...Extract<keyof T, string>[]]
    ) => {
        if (!currentUser?.rules?.length) return false;
        return currentUser.rules
            .filter((x) => x.memberCountryId === countryId)
            .some(
                (x) =>
                    intersection(
                        x.rules,
                        checkRule.map((r) => rules[r]),
                    ).length,
            );
    };

    const checkFullAccess = <T extends Record<string, string>>(
        entity: IRestrictedEntity,
        rules: T,
        ...checkRule: [Extract<keyof T, string>, ...Extract<keyof T, string>[]]
    ) => {
        if (!currentUser?.rules?.length) return false;
        const otherRestrictions = entity.otherRestrictions ?? (() => true);
        for (const role of currentUser.rules) {
            // find out whether has required rule
            const hasRule =
                intersection(
                    role.rules,
                    checkRule.map((r) => rules[r]),
                ).length > 0;

            // check whether is admin and has rule
            if (hasRule && !role.objectId) {
                return otherRestrictions(true);
            }

            // check whether is entity owner and has rule
            if (entity.objectId !== 'no-check') {
                if (hasRule && (entity.objectType === 'no-check' || entity.objectType === role.objectType) && entity.objectId === role.objectId) {
                    return otherRestrictions(false);
                }
            }

            // check whether is country representative
            if (entity.memberCountry !== 'no-check') {
                if (hasRule && entity.memberCountry === role.memberCountryId) {
                    return otherRestrictions(false);
                }
            }

            // check whether is creator
            if (entity.createdBy !== 'no-check' && currentUser.user?.userId) {
                if (
                    entity.createdBy === currentUser.user.userId &&
                    intersection(
                        role.rules,
                        checkRule.map((r) => rules[`${r}Own`] ?? rules[r]),
                    ).length > 0
                ) {
                    return otherRestrictions(false);
                }
            }
        }

        return false;
    };

    return (
        <UserContext.Provider
            value={{
                //language: this.state.lang,
                checkAccess,
                checkAccessUser,
                checkAccessCountry,
                checkAccessAdmin,
                checkAccessOwner,
                checkFullAccess,
            }}
        >
            <Header />
            <div className="d-md-flex">
                <Menu />
                <div className="flex-grow-1">
                    <Switch>
                        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                        <Route path={CenterRoutesPath.Prefix} component={CenterRoutes} />
                        <Route path={ClientRoutesPath.Prefix} component={ClientRoutes} />
                        <Route path={DriveRoutesPath.Prefix} component={DriveRoutes} />
                        <Route path={ChatRoutesPath.Prefix} component={ChatRoutes} />
                        <Route path={DictionaryRoutesPath.Prefix} component={DictionaryRoutes} />
                        <Route path={ExternalProviderRoutesPath.Prefix} component={ExternalProviderRoutes} />
                        <Route path={LogRoutesPath.Prefix} component={LogRoutes} />
                        <Route path={MenuRoutesPath.Prefix} component={MenuRoutes} />
                        <Route path={NotificationRoutesPath.Prefix} component={NotificationRoutes} />
                        <Route path={RoleRoutesPath.Prefix} component={RoleRoutes} />
                        <Route path={SettingsRoutesPath.Prefix} component={SettingsRoutes} />
                        <Route path={UserRoutesPath.Prefix} component={UserRoutes} />
                        <Route path={ProfileRoutesPath.Prefix} component={ProfileRoutes} />
                        <Route path={SupportRoutesPath.Prefix} component={SupportRoutes} />
                        <Route path={MailingRoutesPath.Prefix} component={MailingRoutes} />
                        <Route path={NewsRoutesPath.Prefix} component={NewsRoutes} />
                        <Route path={SpecialistRoutesPath.Prefix} component={SpecialistRoutes} />
                        <Route path={InnovationProjectRoutesPath.Prefix} component={InnovationProjectRoutes} />
                        <Route path={MemberCountryRoutesPath.Prefix} component={MemberCountryRoutes} />
                        <Route path={EventRoutesPath.Prefix} component={EventRoutes} />
                        <Route path={HomeRoutesPath.Prefix} component={HomeRoutes} />
                    </Switch>
                </div>
            </div>
            <Footer />
        </UserContext.Provider>
    );
};

export default Layout;
