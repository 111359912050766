import React, {PropsWithChildren} from "react";
import { BackButton } from '../common/ui';
import {useTypedSelector} from "../../hooks/useTypedSelector";

interface IProps {
    className?: string
}

export default function TopActionsContainer({ className, children }: PropsWithChildren<IProps>) {
    const { backLink } = useTypedSelector(state => state.layout);

    return (
        <main className={`${className ?? "mb-3"} ms-2 ms-lg-0`}>
            {backLink && <BackButton to={backLink}/>}
            {children}
        </main>
    )
}