import { FC, useEffect, useState } from 'react';
import { MDBCheckbox, MDBInput } from 'mdb-react-ui-kit';
import { useHistory, useParams } from 'react-router';
import useTranslation from '../../hooks/translation.hook';
import { EenApi } from '../../eenApi';
import MainContainer from '../../component/mainContainer';
import DefaultBlock from '../../component/defaultBlock';
import PageTitle from '../../component/pageTitle';
import { ErrorHandler } from '../../service/errorHandler';
import { ToastManager } from '../../service/toastManager';
import InputContainer from '../../component/inputContainer';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { MenuTemEditDto } from "../../eenApi/menu/menu.tem.edit.dto";
import { SelectControl } from "../../component/common/ui";
import { useOptions } from "../../hooks/select.options.hook";
import { UnviewedItemTypeEnum } from "../../eenApi/menu/enum/unviewd.item.type";

const MenuEdit: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const currentUser = useCurrentUser();
    const history = useHistory();
    const { t } = useTranslation();
    const [entity, setEntity] = useState<MenuTemEditDto>(new MenuTemEditDto({ id: id ? parseInt(id) || 0 : 0 }));
    const objectTypeOptions = useOptions({ center: 'center', client: 'client' }, 'main:object-type-');
    const unviewedItemTypeOptions = useOptions(UnviewedItemTypeEnum, 'menu:unviewedItemType.');

    useEffect(() => {
        refreshData();
    }, []);

    const refreshData = async () => {
        if (entity.id < 1) {
            return;
        }

        try {
            const data = await EenApi.get<MenuTemEditDto>(`menu/get/${entity.id}?language=${currentUser.data?.language}`);
            if (data) {
                setEntity(new MenuTemEditDto(data));
            }
        } catch (err) {
            ErrorHandler.handle('menuEdit refreshData', err);
        }
    };

    const onClickSave = async () => {
        saveAction();
    };

    const onClickDelete = async () => {
        deleteAction();
    };

    const saveAction = async () => {
        try {
            //todo validation
            const rd = {
                ...entity,
                language: currentUser.data?.language,
            };
            const result = entity.id > 0 ? await EenApi.putRequest('menu/edit', rd) : await EenApi.postRequest('menu/add', rd);
            console.log('menu add/edit result', result.data);
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            if (entity.id < 1) {
                history.push(`/menu/edit/${result.data}`);
            }
            setEntity({ ...entity, id: result.data });
            refreshData();
        } catch (err) {
            ErrorHandler.handle('menu add/edit', err);
        }
    };

    const deleteAction = async () => {
        try {
            if (entity.id < 1) {
                return;
            }
            await EenApi.delete(`menu/delete/${entity.id}`);
            history.push(`/menu`);
        } catch (err) {
            ErrorHandler.handle('menu delete', err);
        }
    };

    return (
        <MainContainer>
            <PageTitle text={t(entity.id > 0 ? 'menu:edit-title' : 'menu:create-title')} backLink="/menu" />
            <DefaultBlock>
                <InputContainer>
                    <MDBInput
                        type="text"
                        size="lg"
                        value={entity.name}
                        name="name"
                        onChange={(e: any) => setEntity({ ...entity, name: e.target.value })}
                        required
                        label={t('main:field-name')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBCheckbox
                        id="menu-active"
                        checked={entity.active}
                        onChange={(e: any) =>
                            setEntity({
                                ...entity,
                                active: !!e.target.checked,
                            })
                        }
                        label={t('main:field-active')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBCheckbox
                        id="menu-action"
                        checked={entity.isAction}
                        onChange={(e: any) =>
                            setEntity({
                                ...entity,
                                isAction: !!e.target.checked,
                            })
                        }
                        label={t('menu:field-action')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBInput
                        type="text"
                        size="lg"
                        value={entity.link}
                        name="link"
                        onChange={(e: any) => setEntity({ ...entity, link: e.target.value })}
                        required
                        label={t('menu:field-link')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBInput
                        type="color"
                        size="lg"
                        value={entity.color}
                        name="color"
                        onChange={(e: any) => setEntity({ ...entity, color: e.target.value })}
                        required
                        label={t('menu:field-color')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBInput
                        type="text"
                        size="lg"
                        value={entity.helpHintId}
                        name="helpHintId"
                        onChange={(e: any) => setEntity({ ...entity, helpHintId: e.target.value })}
                        required
                        label={t('menu:field-help-hint')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBInput
                        type="text"
                        size="lg"
                        value={entity.icon}
                        name="icon"
                        onChange={(e: any) => setEntity({ ...entity, icon: e.target.value })}
                        required
                        label={t('menu:field-icon')}
                    />
                </InputContainer>
                <InputContainer>
                    <MDBInput
                        type="text"
                        size="lg"
                        value={entity.rule}
                        name="rule"
                        onChange={(e: any) => setEntity({ ...entity, rule: e.target.value })}
                        required
                        label={t('menu:field-rule')}
                    />
                </InputContainer>
                <SelectControl options={objectTypeOptions} labelKey="main:field-object-type"
                               value={entity.objectTypes ?? []} multiple
                               onChange={val => setEntity(prev => ({ ...prev, objectTypes: val ?? [] }))}/>
                <SelectControl options={unviewedItemTypeOptions} labelKey="menu:field-unviewed-item-type"
                               value={entity.unviewedItemType}
                               onChange={val => setEntity(prev => ({ ...prev, unviewedItemType: val }))}/>
                <div>
                    <button className="btn btn-primary" onClick={onClickSave}>
                        {t('main:btn-save')}
                    </button>
                    {entity.id > 0 && (
                        <button className="btn btn-outline-danger ms-2" onClick={onClickDelete}>
                            {t('main:btn-delete')}
                        </button>
                    )}
                </div>
            </DefaultBlock>
        </MainContainer>
    );
};

export default MenuEdit;
