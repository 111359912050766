import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { Suspense, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import MainEditTab from '../../../component/specialist/MainEditTab';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { SpecialistService } from '../../../eenApi/specialist/service';
import Loader from '../../../component/loader';
import { SpecialistDto } from '../../../eenApi/specialist/dto/specialist.dto';
import { SpecialistRoutesPath } from '../routes';
import { SpecialistEditValidator } from '../../../utils/validators/specialist/edit.validator';
import Rules from '../../../rules';
import LanguageEnum from '../../../enums/language.enum';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { LoaderTab } from '../../../component/common/layout';
import { DeleteModal } from '../../../component/common/ui';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';

const ContactsEditTab = React.lazy(() => import('../../../component/specialist/ContactsEditTab'));

export default function SpecialistEdit() {
    const { data: currentUser } = useCurrentUser();
    const {
        t,
        i18n,
        isFetching,
        data,
        entity,
        activeTab,
        setActiveTab,
        updateData,
        validateOn,
        tabs,
        setTabs,
        saveActions,
        isDeleteOpen,
        closeDelete,
        remove,
    } = useEditPageWithDelete<SpecialistDto>({
        dataFactory: (entity, language) => new SpecialistDto(entity, language as LanguageEnum, currentUser?.rules),
        queryKey: 'specialist',
        handler: (data) => SpecialistService.save(data),
        validator: SpecialistEditValidator,
        applyRoute: SpecialistService.getEditRoute,
        saveRoute: SpecialistService.getDetailsRoute,
        cancelRoute: [SpecialistRoutesPath.List, SpecialistService.getDetailsRoute],
        canDeleteUser: {
            userId: 'createdBy',
            rules: Rules.Specialist,
        },
        deleteHandler: (id) => SpecialistService.remove(id),
    });

    const tabItems = useMemo(() => {
        return [new TabItem('main', t('specialist:tabs.main')), new TabItem('contacts', t('specialist:tabs.contacts'))];
    }, [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`specialist:title.${entity?.id ? 'edit' : 'add'}`)} backLink={SpecialistRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={saveActions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <LoaderTab activeId={activeTab} />
                {!isFetching && (
                    <>
                        <MainEditTab activeId={activeTab} id="main" entity={entity} data={data} updateData={updateData} />
                        <Suspense fallback={<Loader />}>
                            <ContactsEditTab activeId={activeTab} id="contacts" entity={entity} data={data} updateData={updateData} />
                        </Suspense>
                        <BottomActionsContainer>
                            <BottomActions actions={saveActions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}