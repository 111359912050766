import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { Suspense, useContext, useMemo, useState } from 'react';
import MainContainer from '../../../component/mainContainer';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ToastManager } from '../../../service/toastManager';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import TabPanel from '../../../component/tabs/tabPanel';
import Loader from '../../../component/loader';
import useTranslation from '../../../hooks/translation.hook';
import { EventRoutesPath } from '../routes';
import { ActionItem } from '../../../models/actionItem';
import { UserContext } from '../../../context/user-context';
import EventDto from '../../../eenApi/event/dto/event.dto';
import MainDetailsTab from '../../../component/event/MainDetailsTab';
import EventStatusEnum from '../../../eenApi/event/enum/event.status.enum';
import Rules from '../../../rules';
import { EventService } from '../../../eenApi/event/service';
import { DeleteModal } from '../../../component/common/ui';
import { resetQueryFn } from '../../../query/query.utils';
import { EntityQueryKey } from '../../../query/query.keys';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import CommentSection from '../../../component/chat/CommentSectionСomp';
import ChatObjectTypeEnum from '../../../eenApi/chat/enum/chat.entity.enum';

const LocationDetailsTab = React.lazy(() => import('../../../component/event/LocationDetailsTab'));

export default function EventDetails() {
    const { checkAccess } = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const currentUser = useCurrentUser();
    const { id } = useParams<{ id: string }>();

    const { data: entity } = useQuery<EventDto>(['event', id, i18n.language], {
        onSuccess: async () => {
            setActiveTab('main');
        },
    });
    const [activeTab, setActiveTab] = useState(entity?.id ? 'main' : 'loading');
    const tabItems = useMemo(() => [new TabItem('main', t('event:tab.main')), new TabItem('location', t('event:tab.location'))], [i18n.language]);

    const deleteMutation = useMutation(() => EventService.remove(Number(id)), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-entity-deleted' });
            await queryClient.removeQueries(['event', id]);
            await queryClient.resetQueries({ predicate: query => resetQueryFn(query, EntityQueryKey.EventValidation, EntityQueryKey.Event) });
            history.push(EventRoutesPath.List);
        },
        onSettled: (data, error, tab) => {
            if (error) {
                setActiveTab(activeTab);
            }
        },
    });

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const closeDelete = () => setIsDeleteOpen(false);
    const remove = () => {
        closeDelete();
        window.scrollTo(0, 0);
        deleteMutation.mutate();
        setActiveTab('loading');
    };

    const changeStatusMutation = useMutation(({ status }: { tab: string; status: EventStatusEnum }) => EventService.updateEventStatus(entity!.id!, status), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.resetQueries({ predicate: query => resetQueryFn(query, EntityQueryKey.EventValidation, EntityQueryKey.Event) });
            await queryClient.invalidateQueries(['event', id]);
        },
        onSettled: (data, error, { tab }: { tab: string; status: EventStatusEnum }) => {
            if (error) {
                setActiveTab(tab);
            }
        },
    });

    const actions = useMemo(() => {
        const items: ActionItem[] = [];
        if (checkAccess(Rules.Event.Edit) || (checkAccess(Rules.Event.EditActive) && currentUser.data?.user?.id === entity?.createdBy?.id)) {
            items.push({
                name: t('main:btn-edit'),
                onClick: async () => {
                    history.push(EventRoutesPath.Edit.replace(':id', id));
                },
            });
        }

        if (id && checkAccess(Rules.Event.Delete)) {
            items.push({
                name: t('main:btn-delete'),
                onClick: async () => setIsDeleteOpen(true),
            });
        }

        if (checkAccess(Rules.Event.Validate) && (entity?.status === EventStatusEnum.Draft || entity?.status === EventStatusEnum.CommentsReceived)) {
            items.push({
                name: t('main:btn-sendValidation'),
                onClick: async () =>
                    changeStatusMutation.mutate({
                        tab: activeTab,
                        status: EventStatusEnum.Validation,
                    }),
            });
        }
        if (checkAccess(Rules.Event.Validate) && entity?.status === EventStatusEnum.Validation) {
            items.push({
                name: t('main:btn-remarks'),
                onClick: async () =>
                    changeStatusMutation.mutate({
                        tab: activeTab,
                        status: EventStatusEnum.CommentsReceived,
                    }),
            });
            items.push({
                name: t('main:btn-publish'),
                onClick: async () =>
                    changeStatusMutation.mutate({
                        tab: activeTab,
                        status: EventStatusEnum.Active,
                    }),
            });
        }

        return items;
    }, [id, i18n.language, entity?.status]);

    return (
        <MainContainer>
            <PageTitle text={t('event:title.details')} backLink={EventRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <TabPanel activeId={activeTab} id="loading">
                <Loader />
            </TabPanel>
            {entity?.id && (
                <>
                    <MainDetailsTab entity={entity} id="main" activeId={activeTab} />
                    <Suspense fallback={<Loader />}>
                        <LocationDetailsTab entity={entity} id="location" activeId={activeTab} />
                    </Suspense>
                    <BottomActionsContainer>
                        <BottomActions actions={actions} />
                    </BottomActionsContainer>
                    <CommentSection objectType={ChatObjectTypeEnum.Event} objectId={entity.id!} />
                </>
            )}
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}
