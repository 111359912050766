import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { MDBBtn } from 'mdb-react-ui-kit';
import useTranslation from '../../../../hooks/translation.hook';
import React, { Suspense, useMemo, useState } from 'react';
import { InterestDto } from '../../../../eenApi/profile/dto/interest.dto';
import TabItem from '../../../../component/tabs/tabItem';
import { ToastManager } from '../../../../service/toastManager';
import { ActionItem } from '../../../../models/actionItem';
import { ProfileRoutesPath } from '../../routes';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import TopActionsContainer from '../../../../component/actions/topActionsContainer';
import TopActions from '../../../../component/actions/topActions';
import TabControls from '../../../../component/tabs/tabControls';
import TabPanel from '../../../../component/tabs/tabPanel';
import Loader from '../../../../component/loader';
import ProfileService from '../../../../eenApi/profile/service';
import BaseModal from '../../../../component/modal/baseModal';
import ModalHeader from '../../../../component/modal/modalHeader';
import BottomActions from '../../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../../component/actions/bottomActionsContainer';
import { InfoTab } from '../../../../component/common/layout';
import { useCanViewClient } from "../../../client/access.hook";
import { Link } from "react-router-dom";
import { ClientService } from "../../../../eenApi/client/service";
import { AccessActionEnum } from "../../../../models/access.dto";
import CommentSection from '../../../../component/chat/CommentSectionСomp';
import ChatObjectTypeEnum from '../../../../eenApi/chat/enum/chat.objecttype.enum';

const ContactsTab = React.lazy(() => import('../../../../component/profile/ContactsTab'));

export default function InterestDetails() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const clientAccess = useCanViewClient();

    const { id } = useParams<{ id: string }>();
    const { data: entity } = useQuery<InterestDto>(['profile', id, null, 'interest/'], {
        onSuccess: () => setActiveTab('main')
    });

    const [activeTab, setActiveTab] = useState((entity?.id) ? 'main' : 'loading');
    const tabItems = useMemo(() => {
        const items = [
            new TabItem('main', t('profile:tabs.main'))
        ];

        if (entity?.client?.id) {
            items.push(new TabItem('contacts', t('profile:tabs.contacts')))
        }

        return items
    }, [i18n.language, entity?.client?.id]);
    let currentTab = '';

    const deleteMutation = useMutation(() => ProfileService.removeInterest(Number(id)), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-entity-deleted' });
            await queryClient.removeQueries(['interest', id]);
            history.push(ProfileRoutesPath.InterestList);
        },
        onSettled: () => {
            setActiveTab(currentTab);
        },
    });

    const actions = useMemo(() => {
        const items: ActionItem[] = [];

        if (entity?.accessDto[AccessActionEnum.Update]) {
            items.push({
                name: t('main:btn-edit'), icon: 'far fa-trash-alt',
                onClick: () => history.push(ProfileRoutesPath.InterestEdit.replace(':id', String(entity?.id)))
            });
        }

        if (entity?.accessDto[AccessActionEnum.Delete]) {
            items.push({ name: t('main:btn-delete'), icon: 'far fa-trash-alt', onClick: () => setIsDeleteOpen(true) });
        }

        return items;
    }, [i18n.language, entity?.id]);

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const closeDelete = () => setIsDeleteOpen(false);
    const executeModalAction = (mutation: UseMutationResult<any, unknown, void>) => {
        closeDelete();
        window.scrollTo(0, 0);
        currentTab = activeTab;
        setActiveTab('loading');
        mutation.mutate();
    };

    return (
        <MainContainer>
            <PageTitle text={t('profile:title.interestDetails')} backLink={ProfileRoutesPath.InterestList} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <TabPanel activeId={activeTab} id="loading">
                <Loader />
            </TabPanel>
            {entity?.id && (
                <>
                    <InfoTab activeId={activeTab} id="main" entity={entity} namespace="profile" prefix="fields.interest" fields={[
                        {
                            name: 'profile',
                            value: item => item.profile?.name ?? ''
                        },
                        {
                            name: 'clientId',
                            children: clientAccess(entity.client) ? (
                                <Link to={ClientService.getViewLink(entity.client?.id ?? 0)}>
                                    {entity.client?.name}
                                </Link>
                            ) : (
                                <>{entity.client?.name}</>
                            ),
                        },
                        {
                            name: 'status',
                            value: item => t(`profile:interestStatus.${item.status}`)
                        },
                        'cooperationKind',
                        'missingInformation'
                    ]} />
                    {entity.client?.id && <Suspense fallback={<Loader />}>
                        <ContactsTab activeId={activeTab} id='contacts' entity={entity.client} />
                    </Suspense>}
                    <BottomActionsContainer>
                        <BottomActions actions={actions} />
                    </BottomActionsContainer>
                </>
            )}
            <BaseModal isOpen={isDeleteOpen} onRequestClose={closeDelete} modalSize="s">
                <ModalHeader title={t('main:msg-entity-delete-confirm')} onRequestClose={closeDelete} />
                <div className="text-center p-3">
                    <MDBBtn onClick={() => executeModalAction(deleteMutation)}>{t('main:yes')}</MDBBtn>
                </div>
            </BaseModal>
            {entity?.id && (<CommentSection objectType={ChatObjectTypeEnum.interest} objectId={entity?.id} />)}
        </MainContainer>
    );
}
