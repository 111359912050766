import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { Suspense, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import TabPanel from '../../../component/tabs/tabPanel';
import Loader from '../../../component/loader';
import { MemberCountryRoutesPath } from '../routes';
import { ActionItem } from '../../../models/actionItem';
import Rules from '../../../rules';
import { MemberCountryService } from '../../../eenApi/membercountry/member.country.service';
import { MemberCountry } from '../../../eenApi/membercountry/dto/member.country';
import { InfoTab } from '../../../component/common/layout';
import { Field } from '../../../component/common/layout';
import { useDetailsStates } from '../../../hooks/UseDetailsStates';
import { useDeleteModal } from '../../../hooks/UseDeleteModal';
import { DeleteModal } from '../../../component/common/ui';

const CentersTab = React.lazy(() => import('../../../component/membercountry/CentersTab'));
const ProjectsTab = React.lazy(() => import('../../../component/membercountry/ProjectsTab'));

export default function MemberCountryDetails() {
    const { t, i18n, history, id, entity, activeTab, setActiveTab, checkAccess } = useDetailsStates<MemberCountry>({ queryKey: 'membercountry' });

    const { isDeleteOpen, closeDelete, remove, deleteButton } = useDeleteModal({
        queryKey: 'membercountry',
        handler: (id) => MemberCountryService.remove(id),
        successPath: MemberCountryRoutesPath.List,
    });

    const tabItems = useMemo(
        () => [
            new TabItem('main', t('membercountry:tab.main')),
            new TabItem('centers', t('membercountry:tab.centers')),
            new TabItem('projects', t('membercountry:tab.projects')),
        ],
        [i18n.language],
    );

    const actions = useMemo(() => {
        const items: ActionItem[] = [];
        if (checkAccess(Rules.MemberCountry.Edit) || checkAccess(Rules.MemberCountry.EditBasic, entity?.centerDtos?.map((c) => String(c.id)) ?? [])) {
            items.push({
                name: t('main:btn-edit'),
                onClick: async () => {
                    history.push(MemberCountryRoutesPath.Edit.replace(':id', id));
                },
            });
        }

        if (id && checkAccess(Rules.MemberCountry.Delete)) {
            items.push(deleteButton);
        }

        return items;
    }, [id, i18n.language, entity?.centerDtos]);

    return (
        <MainContainer>
            <PageTitle text={t('membercountry:title.details')} backLink={MemberCountryRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <TabPanel activeId={activeTab} id="loading">
                <Loader />
            </TabPanel>
            {entity?.id && (
                <>
                    <InfoTab
                        activeId={activeTab}
                        id="main"
                        entity={entity}
                        namespace="membercountry"
                        fields={[
                            {
                                render: (entity) =>
                                    entity.flag?.source ? (
                                        <Field key={0} labelKey="membercountry:field.flagFileId" optional>
                                            <img src={`/${entity.flag.source}`} style={{ maxHeight: '200px' }} alt={t('membercountry:field.flagFileId')} />
                                        </Field>
                                    ) : null,
                            },
                            'name',
                            'capital',
                            'description',
                        ]}
                    />
                    <Suspense fallback={<Loader />}>
                        <CentersTab activeId={activeTab} id="centers" centers={entity.centerDtos} />
                        <ProjectsTab activeId={activeTab} id="projects" memberCountryId={entity.id} />
                    </Suspense>
                    <BottomActionsContainer>
                        <BottomActions actions={actions} />
                    </BottomActionsContainer>
                </>
            )}
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}