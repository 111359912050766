import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import ProtectedRoute from '../../../component/common/ProtectedRoute';
import Rules from '../../../rules';
import { ChatList } from '../list';
import { EventRoutesPath } from '../../event/routes';
import { ProfileRoutesPath } from '../../profile/routes';
import { SupportRoutesPath } from '../../support/routes';

export default function ChatRoutes() {
    return (
        <Switch>
            <ProtectedRoute path={ChatRoutesPath.List} component={ChatList} rule={Rules.Chat.List} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

const prefix = '/chat';
export const ChatRoutesPath = {
    Prefix: `${prefix}`,
    List: `${prefix}/list`,
};

export const getLinkRouteByObjectType = (objectType: string, id: string | number) => {
    switch (objectType) {
        case 'event':
            return EventRoutesPath.View.replace(':id', id.toString());
        case 'profile-interest':
            return ProfileRoutesPath.InterestView.replace(':id', id.toString());
        case 'support-request':
            return SupportRoutesPath.View.replace(':id', id.toString());
        default:
            return '#';
    }
};