import { Route, Switch } from 'react-router-dom';
import NotFound from '../../home/notFound';
import SettingsNotification from '../notification';
import SettingsMain from '../settingsMain';

export default function SettingsRoutes() {
    return (
        <Switch>
            <Route path={SettingsRoutesPath.Notification} component={SettingsNotification} />
            <Route path={SettingsRoutesPath.Prefix} component={SettingsMain} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}

const prefix = 'settings';
export const SettingsRoutesPath = {
    Prefix: `/${prefix}`,
    Notification: `/${prefix}/notification`,
};
