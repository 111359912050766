import ModalHeader from "../../../modal/modalHeader";
import { MDBBtn } from "mdb-react-ui-kit";
import BaseModal from "../../../modal/baseModal";
import React from "react";
import useTranslation from "../../../../hooks/translation.hook";

interface IProps {
  isDeleteOpen: boolean,
  close: () => void
  remove: () => void
}

export function DeleteModal({ isDeleteOpen, remove, close }: IProps) {
  const { t } = useTranslation();
  return (
    <BaseModal isOpen={isDeleteOpen} onRequestClose={close} modalSize='s'>
      <ModalHeader title={t('main:msg-entity-delete-confirm')} onRequestClose={close} />
      <div className='text-center p-3'>
        <MDBBtn style={{width: '110px'}} onClick={() => remove()}>{t('main:yes')}</MDBBtn>
        <MDBBtn style={{width: '110px'}} className="ms-3" onClick={close}>{t('main:btn-cancel')}</MDBBtn>
      </div>
    </BaseModal>
  )
}