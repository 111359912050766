import React, { useContext, useMemo, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { useHistory, useParams } from 'react-router-dom';
import { ActionItem } from '../../../../models/actionItem';
import { SupportRoutesPath } from '../../routes';
import SupportService from '../../../../eenApi/support/service';
import { ErrorHandler } from '../../../../service/errorHandler';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import BottomActionsContainer from '../../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../../component/actions/bottomActions';
import { ToastManager } from '../../../../service/toastManager';
import Utils from '../../../../utils';
import MainTab from '../../../../component/support/supports/MainTab';
import TabItem from '../../../../component/tabs/tabItem';
import TopActions from '../../../../component/actions/topActions';
import TopActionsContainer from '../../../../component/actions/topActionsContainer';
import TabControls from '../../../../component/tabs/tabControls';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import SupportDto from '../../../../eenApi/support/supports/dto/support.dto';
import SupportEditDto from '../../../../eenApi/support/supports/dto/support.edit.dto';
import SupportStatusEnum from '../../../../eenApi/support/supports/enum/support.status.enum';
import { DeleteModal } from '../../../../component/common/ui';
import { useDeleteModal } from '../../../../hooks/UseDeleteModal';
import { UserContext } from '../../../../context/user-context';
import Rules from '../../../../rules';
import CommentSection from '../../../../component/chat/CommentSectionСomp';
import ChatObjectTypeEnum from '../../../../eenApi/chat/enum/chat.entity.enum';

export default function ViewSupport() {
    const { checkAccess } = useContext(UserContext);
    const { id } = useParams<{ id?: string }>();
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();

    let entityId = Utils.getEntityId(id);

    if (isNaN(entityId)) {
        history.push('*');
    }

    const { isDeleteOpen, closeDelete, remove, deleteButton } = useDeleteModal({
        queryKey: 'support',
        handler: (id) => SupportService.delete(id),
        successPath: SupportRoutesPath.List,
    });

    const { data: entity } = useQuery<SupportDto>(['support', id], {
        onError: (error) => {
            ErrorHandler.handle('publication refreshData', error);
        },
    });


    const makeClosedMutation = useMutation(() => SupportService.makeClosed(new SupportEditDto(entity!)), {
        onSuccess: async () => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.invalidateQueries(['support', id]);
        },
        onError: (error) => {
            ErrorHandler.handle('support close', error);
        },
    });

    const tabItems = useMemo(() => [new TabItem('main', t('main:tab-information'))], [i18n.language]);

    const [activeTab, setActiveTab] = useState('main');

    const actions = useMemo<ActionItem[]>(() => {
        const items: ActionItem[] = [];
        if (checkAccess(Rules.Support.Edit)) {
            items.push({
                name: t('main:btn-edit'),
                onClick: async () => {
                    await queryClient.invalidateQueries(['support', id]);
                    history.push(SupportRoutesPath.Edit.replace(':id', id!));
                },
                icon: 'fas fa-pen',
            });
        }

        if (checkAccess(Rules.Support.Delete)) {
            items.push(deleteButton);
            if (entity?.status !== SupportStatusEnum.Closed) {
                items.push({
                    name: t('main:btn-close'),
                    onClick: async () => {
                        await makeClosedMutation.mutate();
                    },
                });
            }
        }
        return items;
    }, [entity, i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t('support:supports.view.title')} backLink={SupportRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <MainTab id="main" activeId={activeTab} entity={entity} />
            <BottomActionsContainer>
                <BottomActions actions={actions} />
            </BottomActionsContainer>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
            <CommentSection objectType={ChatObjectTypeEnum.Support} objectId={entityId} />
        </MainContainer>
    );
};