import { CrudService } from '../common/service/crud.service';
import { EenApi } from '..';
import EventDto from './dto/event.dto';
import EventStatusEnum from './enum/event.status.enum';
import LanguageEnum from '../../enums/language.enum';
import { EventRoutesPath } from '../../page/event/routes';

class EventServiceImpl extends CrudService<EventDto> {
    constructor() {
        super('event');
    }

    async get(id: number) {
        const data = await EenApi.get<EventDto>(`${this.baseUrl}/get/${id}`);
        return data;
    }

    updateEventStatus(id: number, status: EventStatusEnum) {
        return EenApi.put(`${this.baseUrl}/status`, { id, status });
    }

    async status(id: number, language: LanguageEnum, status: EventStatusEnum) {
        return await EenApi.put(`${this.baseUrl}/status`, { id, language, status });
    }

    listValidation(requestData: any) {
        return EenApi.list<EventDto>(`${this.baseUrl}/list/validation`, requestData);
    }

    getViewLink(id: string) {
        return EventRoutesPath.View.replace(':id', id);
    }
}
export const EventService = new EventServiceImpl();
