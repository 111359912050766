import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { Suspense, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import TabPanel from '../../../component/tabs/tabPanel';
import Loader from '../../../component/loader';
import { ActionItem } from '../../../models/actionItem';
import Rules from '../../../rules';
import MainDetailsTab from '../../../component/client/MainDetailsTab';
import { ClientRoutesPath } from '../routes';
import { ClientService } from '../../../eenApi/client/service';
import { Client } from '../../../eenApi/client/client';
import { useDetailsStates } from '../../../hooks/UseDetailsStates';
import { useCanViewBasic, useCanViewContacts } from "../access.hook";
import { useDeleteModal } from "../../../hooks/UseDeleteModal";
import { DeleteModal } from "../../../component/common/ui";
import { useCountryWarning } from "../../../hooks/UseCountryWarning";


const ContactsDetailsTab = React.lazy(() => import('../../../component/client/ContactsDetailsTab'));

export default function ClientDetails() {
    const {
        t, i18n,
        history,
        id, entity,
        activeTab, setActiveTab,
        checkAccess } = useDetailsStates<Client>({ queryKey: 'client' });
    const canViewBasic = useCanViewBasic();
    const canViewContacts = useCanViewContacts();

    const {
        isDeleteOpen, closeDelete,
        remove,
        deleteButton
    } = useDeleteModal({ queryKey: 'client', handler: id => ClientService.delete([Number(id)]), successPath: ClientRoutesPath.List });

    useCountryWarning(entity, entity?.memberCountry);

    const tabItems = useMemo(() => {
        const items: TabItem[] = [];
        if (canViewBasic(entity)) {
            items.push(new TabItem('main', t('client:tabs.main')))
        }
        if (canViewContacts(entity)) {
            items.push(new TabItem('contacts', t('client:tabs.contacts')))
        }
        return items
    }, [i18n.language, entity?.id]);

    const actions = useMemo(() => {
        const items: ActionItem[] = [];
        if (checkAccess(Rules.Client.Edit, null, entity?.memberCountry?.id)) {
            items.push({
                name: t('main:btn-edit'), onClick: async () => {
                    history.push(ClientRoutesPath.Edit.replace(':id', id));
                },
            });
        }

        if (id && checkAccess(Rules.Client.Delete)) {
            items.push(deleteButton);
        }

        return items
    }, [id, i18n.language, entity?.memberCountry?.id]);

    return (
        <MainContainer>
            <PageTitle text={t('client:view-title')} backLink={ClientRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            <TabPanel activeId={activeTab} id="loading">
                <Loader />
            </TabPanel>
            {entity?.id && (
                <>
                    <MainDetailsTab activeId={activeTab} id="main" entity={entity} />
                    <Suspense fallback={<Loader />}>
                        <ContactsDetailsTab activeId={activeTab} id="contacts" entity={entity} />
                    </Suspense>
                    <BottomActionsContainer>
                        <BottomActions actions={actions} />
                    </BottomActionsContainer>
                </>
            )}
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove}/>
        </MainContainer>
    );
}