import { useMemo } from 'react';
import { Link, useLocation } from "react-router-dom";
import { DriveEntity } from '../../../../eenApi/drive/driveEntity';
import { DriveService } from '../../../../eenApi/drive/service';
import { ObjectType } from '../../../../enums/objectType';
import useTranslation from "../../../../hooks/translation.hook";
import { formatDateTime } from "../../../../utils/date.utils";
import { formatFileSize } from "../../../../utils/string.utils";
import { ICrumb } from "../../../../component/common/layout/Breadcrumbs";
import {
    TableColumn,
    TableGroupAction,
    TableRowAction
} from "../../../../component/common/layout/DataTable/table.model";
import { DataTable } from "../../../../component/common/layout";
import { EntityQueryKey } from "../../../../query/query.keys";

interface IProps {
    parentId?: string;
    filterValues?: { [key: string]: any };
    rowActions: TableRowAction<DriveEntity>[];
    groupActions: TableGroupAction[];
    isDragActive?: boolean;
    path: string
}

export function DriveTable(props: IProps) {
    const { t, i18n } = useTranslation();
    const { state } = useLocation<{crumbs: ICrumb[]}>();
    
    const columns = useMemo<TableColumn<DriveEntity>[]>(
        () => [
            new TableColumn({
                headerName: t('main:field-name'),
                hideable: false,
                sortable: true,
                field: 'name',
                renderCell: (params) => {
                    if (params.row.type === ObjectType.DriveFile) {
                        return (
                            <>
                                <i className="fas fa-file me-2" />
                                {params.value}
                            </>
                        );
                    }
                    if (params.row.type === ObjectType.DriveFolder) {
                        return (
                            <>
                                <i className="far fa-folder me-2" />
                                <Link
                                    className=""
                                    to={{
                                        pathname: DriveService.getViewFolderLink(String(params.id), props.path),
                                        state: {
                                            crumbs: (state?.crumbs ?? []).concat(params.row),
                                        },
                                    }}
                                >
                                    {params.value}
                                </Link>
                            </>
                        );
                    }
                    return 'unknown type';
                },
            }),
            new TableColumn({
                headerName: t('drive:field-last-modified'),
                sortable: true,
                field: 'lastModified',
                valueFormatter: ({ value }) => formatDateTime(value),
            }),
            new TableColumn({
                headerName: t('drive:field-content-length'),
                field: 'contentLength',
                renderCell: ({ row }) => {
                    return (<>
                            {row.type === ObjectType.DriveFolder && <>-</>}
                            {row.type === ObjectType.DriveFile && <>{t(...formatFileSize(row.contentLength))}</>}
                        </>);
                },
            }),
            new TableColumn({
                headerName: t('drive:field-content-type'),
                field: 'contentType',
                renderCell: ({ row }) => {
                    return (<>
                            {row.type === ObjectType.DriveFolder && <>-</>}
                            {row.type === ObjectType.DriveFile && <>{row.contentType}</>}
                        </>);
                },
            }),
        ],
        [i18n.language, props.path, state?.crumbs],
    );

    return (
        <div className={`border ${props.isDragActive ? 'border-info' : 'border-transparent'}`}>
            <DataTable
                id={`${props.path}drive-table-component`}
                queryKey={[EntityQueryKey.Drive, props.parentId, props.path]}
                filterValues={props.filterValues}
                columns={columns}
                fetchData={(requestData) => DriveService.list({ ...requestData, id: props.parentId }, props.path)}
                rowActions={props.rowActions}
                groupActions={props.groupActions}
                sortBy="name"
            />
        </div>
    );
}