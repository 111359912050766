import React, { Suspense, useMemo, useState } from 'react';
import { NewsRoutesPath } from '../routes';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Loader from '../../../component/loader';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../component/actions/bottomActions';
import TabItem from '../../../component/tabs/tabItem';
import TabControls from '../../../component/tabs/tabControls';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { NewsService } from '../../../eenApi/news/service';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import NewsValidator from '../../../utils/validators/news/edit.validator';
import NewsMainTab from '../../../component/news/NewsMainTab';
import NewsEditDto from '../../../eenApi/news/dto/news.edit.dto';
import LanguageEnum from '../../../enums/language.enum';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';
import Rules from '../../../rules';
import { DeleteModal } from '../../../component/common/ui';

const DetailTab = React.lazy(() => import('../../../component/news/DetailTab'));
const AnnouncementTab = React.lazy(() => import('../../../component/news/AnnouncementTab'));

export default function EditNews() {
    const {
        t,
        id,
        i18n,
        history,
        isFetching,
        data,
        entity,
        activeTab,
        setActiveTab,
        updateData,
        validateOn,
        tabs,
        setTabs,
        saveActions,
        isDeleteOpen,
        closeDelete,
        remove,
    } = useEditPageWithDelete<NewsEditDto>({
        dataFactory: (entity, language) => new NewsEditDto(entity as any, language as LanguageEnum),
        queryKey: 'news',
        handler: (data) => NewsService.save(data),
        validator: NewsValidator,
        applyRoute: NewsService.getEditRoute,
        saveRoute: NewsService.getDetailsRoute,
        cancelRoute: [NewsRoutesPath.CenterList, NewsService.getDetailsRoute],
        canDelete: Rules.News.Delete,
        deleteHandler: (id) => NewsService.delete(id),
    });

    const [isRoutePathCreate] = useState(history.location.pathname === NewsRoutesPath.Create);

    const tabItems = useMemo(() => {
        return [
            new TabItem('main', t('news:tabs.news')),
            new TabItem('announcement', t('news:tabs.announcement')),
            new TabItem('detail', t('news:tabs.detail')),
        ];
    }, [i18n.language, id]);

    return (
        <MainContainer>
            <PageTitle text={t(!isRoutePathCreate ? 'news:edit-title' : 'news:create-title')} backLink={NewsRoutesPath.CenterList} />
            <TopActionsContainer />
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                {isFetching ? (
                    <Loader />
                ) : (
                    <>
                        <NewsMainTab id="main" activeId={activeTab} updateData={updateData} data={data} />
                        <Suspense fallback={<Loader />}>
                            <AnnouncementTab activeId={activeTab} id="announcement" data={data} updateData={updateData} />
                            <DetailTab activeId={activeTab} id="detail" data={data} entity={entity as any} updateData={updateData} />
                        </Suspense>
                        <BottomActionsContainer>
                            <BottomActions actions={saveActions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}
