import useTranslation from '../../hooks/translation.hook';

interface IProps {
    title: string;
    children?: React.ReactNode;
    required?: boolean,
    className?: string
}

const ViewField: React.FunctionComponent<IProps> = (props) => {
    const { t } = useTranslation();
    return (
        <div className={props.className ?? 'mb-2'}>
            <div>
                <strong>
                    {t(props.title)}
                    {props.required && (
                        <span aria-hidden="true" style={{ color: 'red' }} className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
                             *
                        </span>
                    )}
                </strong>
            </div>
            <div>{props.children}</div>
        </div>
    );
};

export default ViewField;
