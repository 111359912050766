import useTranslation from '../../../../hooks/translation.hook';
import { QueryKey, useQuery } from 'react-query';
import { loadSettings, saveSettings } from './table.utils';
import { TableColumn, TableGroupAction, TableRowAction } from './table.model';
import React, { useEffect, useState } from 'react';
import {
    DataGrid,
    enUS,
    ruRU,
    GridSortModel,
    GridRowId,
    GridSelectionModel,
    GridColumnVisibilityModel, GridValidRowModel
} from "@mui/x-data-grid";
import { ErrorHandler } from '../../../../service/errorHandler';
import { ListRequestData } from '../../../../eenApi/common/listRequestData';
import { TableFooter } from './table.footer';
import { useTableRowActionsColumn } from './table.row.actions.column';
import { TableLoader } from "./table.loader";
import './css/style.css'

interface IProps<T extends GridValidRowModel> {
    /**
     * уникальный идентификатор таблицы. Влияет на сохранение\загрузку настроек из localStorage
     */
    id: string;
    /**
     * Ключ кэшируемого запроса. Используется для сброса кэша после действия над элементом или групповым действием.
     */
    queryKey: QueryKey;
    /**
     * Идентификатор строки (например id сущности). По умолчанию "id"
     */
    itemId?: string | ((item: any) => string);
    /**
     * Настройки колонок таблицы
     */
    columns: TableColumn<T>[];
    fetchData: (requestData: ListRequestData) => Promise<{ items: T[]; total: number }>;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
    onPage?: number;
    /**
     * Объект со значениями фильтра.
     */
    filterValues?: { [key: string]: any };
    /**
     * Действия над строкой таблицы (элементы выпадающего списка)
     */
    rowActions?: TableRowAction<T>[];
    groupActions?: TableGroupAction[];
}

export function DataTable<T extends GridValidRowModel>({ filterValues, ...props }: IProps<T>) {
    const { t, i18n } = useTranslation();
    const [settings, setSettings] = useState(loadSettings(props.id, props.onPage, props.sortBy, props.sortOrder));
    const [currentPage, setCurrentPage] = useState(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: settings.sortBy, sort: settings.sortOrder }]);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
      props.columns.reduce((acc, col) => ({ ...acc, [col.field]: !settings.visibleColumns.length || settings.visibleColumns.includes(col.field)}), {}));
    const fetchRows = async () =>
        props.fetchData({
            order: { [settings.sortBy]: settings.sortOrder },
            filter: { ...filterValues },
            skip: currentPage * settings.onPage,
            take: settings.onPage,
            language: i18n.language,
        });

    const { data, isFetching } = useQuery(
        [...new Array<QueryKey>().concat(props.queryKey), currentPage, settings.onPage, settings.sortBy, settings.sortOrder, JSON.stringify(filterValues), i18n.language],
        fetchRows,
        {
            onError: (err) => {
                ErrorHandler.handle(`${props.id} loadDataError`, err);
                setCurrentPage(0);
            },
            placeholderData: { items: [], total: 0 },
            enabled: filterValues !== undefined,
            keepPreviousData: true
        },
    );

    useEffect(() => {
        saveSettings(props.id, settings);
    }, [settings]);

    function onSortModelChange(newSortModel: GridSortModel) {
        setSettings((prev) => ({
            ...prev,
            sortBy: newSortModel[0]?.field ?? 'id',
            sortOrder: newSortModel[0]?.sort ?? 'asc',
        }));
        setSortModel(newSortModel)
    }

    function onPageSizeChange(pageSize: number) {
        setSettings((prev) => ({ ...prev, onPage: pageSize }));
    }

    function getRowId(row: T): GridRowId {
        if (!props.itemId) return (row as any)['id'];

        return typeof props.itemId === 'string' ? (row as any)[props.itemId] : props.itemId(row);
    }

    function onSelectionModelChange(newSelectionModel: GridSelectionModel) {
        setSelectionModel(newSelectionModel);
    }

    function onColumnVisibilityModelChange(newVisibilityModel: GridColumnVisibilityModel) {
        setSettings(prev => ({
            ...prev,
            visibleColumns: Object.entries(newVisibilityModel).filter(([, visible]) => visible).map(([column, ]) => column)
        }));
        setColumnVisibilityModel(newVisibilityModel)
    }

    function getMinWidth() {
        return props.columns.length * 75 + (props.rowActions ? 50 : 0) + (props.groupActions ? 50 : 0);
    }

    const rowActionsColumn = useTableRowActionsColumn(props.queryKey, props.rowActions ?? []);

    return (
        <div style={{ overflowX: 'auto' }}>
            <DataGrid
                columns={props.rowActions?.length ? [rowActionsColumn, ...props.columns] : props.columns}
                disableColumnMenu
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={onColumnVisibilityModelChange}
                rows={filterValues === undefined ? [] : data?.items ?? []}
                getRowHeight={() => 'auto'}
                getEstimatedRowHeight={() => props.rowActions?.length ? 67 : 51 }
                checkboxSelection={props.groupActions && props.groupActions.length > 0}
                disableSelectionOnClick
                selectionModel={selectionModel}
                onSelectionModelChange={onSelectionModelChange}
                filterMode="server"
                disableColumnFilter
                sortingMode="server"
                sortingOrder={['desc', 'asc']}
                sortModel={sortModel}
                onSortModelChange={onSortModelChange}
                pagination
                paginationMode="server"
                page={currentPage}
                pageSize={settings.onPage}
                rowsPerPageOptions={[...new Set([settings.onPage, 10, 20, 50])].sort()}
                onPageChange={setCurrentPage}
                onPageSizeChange={onPageSizeChange}
                rowCount={data?.total ?? 0}
                getRowId={getRowId}
                loading={filterValues === undefined || isFetching}
                components={{
                    Footer: TableFooter,
                    LoadingOverlay: TableLoader,
                    ColumnSortedAscendingIcon: () => (<i className="fas fa-angle-up ms-1 text-white"/>),
                    ColumnSortedDescendingIcon: () => (<i className="fas fa-angle-down ms-1 text-white"/>),
                }}
                componentsProps={{
                    footer: {
                        queryKey: props.queryKey,
                        selectionModel,
                        groupActions: props.groupActions,
                        columns: props.columns,
                        columnVisibilityModel,
                        columnVisibilityChange: onColumnVisibilityModelChange
                    }
                }}
                localeText={{
                    ...(i18n.language === 'ru' ? ruRU : enUS).components.MuiDataGrid.defaultProps.localeText,
                    noRowsLabel: t('main:table.data-not-found')
                }}
                autoHeight
                sx={{
                    '&.MuiDataGrid-root': { minWidth: getMinWidth() },
                    '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                    '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                    '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                    '& .MuiDataGrid-footerContainer .MuiDataGrid-footerContainer': { border: 'none' },
                    '& .MuiDataGrid-columnHeaders': {
                        background: 'rgba(99,99,115,.5)',
                        color: '#fff',
                        textTransform: 'uppercase',
                    },
                    '& .MuiDataGrid-row': { background: '#fff' },
                    '& .MuiDataGrid-sortIcon': { color: '#fff' },
                    '& .MuiDataGrid-row:hover': { background: '#fff' },
                    '& .MuiDataGrid-columnSeparator': { visibility: 'hidden' },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': { outline: 'none' },
                    '& .MuiDataGrid-row.Mui-selected .dropdown-toggle': { backgroundColor: 'transparent' },
                    '& .MuiPaginationItem-root.Mui-selected, & .MuiPaginationItem-root:hover': { backgroundColor: '#fff', color: '#333' },
                }}
                {...{}}
            />
        </div>
    );
}
