export default class EmailSendConfigurationDto {
    providerId: number|null = null;

    constructor(entity: EmailSendConfigurationDto|undefined, providerId?: number) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof EmailSendConfigurationDto] = val as never
            }
        });
        if (providerId) {
            this.providerId = providerId
        }
        if (this.providerId != null && this.providerId <= 0) {
            this.providerId = null
        }
    }
}
