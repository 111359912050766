import TabPanel from '../../tabs/tabPanel';
import { InputControl } from '../../common/ui';
import IEditPanelProps from '../edit.panel.props';
import DictionaryControl from '../../common/ui/DictionaryControl';
import { DictionaryType } from '../../../models/dictionaryType';
import { FormHelperText } from '@mui/material';
import ViewField from '../../fields/viewField';
import FileList from '../../fields/fileList';
import FileUploader from '../../fileUploader';
import { useContext, useState } from 'react';
import { UserContext } from '../../../context/user-context';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import InputContainer from '../../inputContainer';
import useTranslation from '../../../hooks/translation.hook';
import { NotEmpty } from '../../../hooks/UseValidation/validators';
import Rules from '../../../rules';

export default function LocationEditTab({ data, entity, updateData, ...rest }: IEditPanelProps) {
    const { checkAccess } = useContext(UserContext);
    const { t } = useTranslation();
    const readOnly = !checkAccess(Rules.Event.Edit);

    const [files, setFile] = useState(entity?.mapFiles ?? []);
    const onUploaded = (file: { id: string; name: string }) => {
        setFile(prev => [...prev, file]);
        updateData('mapFileIds', [...data.mapFileIds, file.id]);
    };
    const onRemoveFile = (fileId: string) => {
        data.mapFileIds.splice(data.mapFileIds.indexOf(fileId), 1)
        setFile(prev => {
            prev.splice(prev.map(x => x.id).indexOf(fileId), 1);
            return prev
        });
        updateData('mapFileIds', [...data.mapFileIds]);
    };

    const { validateOn } = useContext(ValidationContext);

    return (
        <TabPanel {...rest}>
            <InputControl
                labelKey={'event:fields.locationName'}
                onChange={(data) => updateData('locationName', data)}
                value={data.locationName}
                tab="location"
                required
                validators={[NotEmpty]}
            />

            <DictionaryControl
                value={data.memberCountryId}
                onChange={(value) => updateData('memberCountryId', value)}
                dictionary={DictionaryType.MemberCountry}
                language={data.language}
                labelKey="event:fields.country"
                required
                minInput={0}
                validators={[NotEmpty]}
            />
            <InputControl
                labelKey={'event:fields.city'}
                onChange={(data) => updateData('city', data)}
                value={data.city}
                tab="location"
                required
                validators={[NotEmpty]}
            />
            <InputControl
                labelKey={'event:fields.locationAddress'}
                onChange={(data) => updateData('locationAddress', data)}
                value={data.locationAddress}
                tab="location"

            />
            <InputControl
                labelKey={'event:fields.locationPhone'}
                onChange={(data) => updateData('locationPhone', data)}
                value={data.locationPhone}
                tab="location"
            />
            <InputContainer>
                <ViewField title="event:fields.mapFiles">
                    <FileList files={files} onRemove={readOnly ? undefined : onRemoveFile} />
                </ViewField>
                <FileUploader onFileUploaded={onUploaded} />
                {validateOn && !data.mapFileIds && <FormHelperText error>{t('main:error.required')}</FormHelperText>}
            </InputContainer>
        </TabPanel>
    );
}
