import React, { FC, useContext, useMemo } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { UserRole } from '../../../../eenApi/user/userRole';
import { UserService } from '../../../../eenApi/user/service';
import { UserContext } from '../../../../context/user-context';
import { RoleRoutesPath } from '../../../role/routes';
import { Link } from 'react-router-dom';
import { ObjectType } from '../../../../enums/objectType';
import Rules from '../../../../rules';
import { CenterService } from '../../../../eenApi/center/service';
import { ClientService } from '../../../../eenApi/client/service';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import { useRowActions } from '../../../../component/common/layout/DataTable/table.utils';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';

interface IProps {
    userId: number;
    filterValues?: { [key: string]: any };
    showActions?: boolean;
}

const UserRoleTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);

    const columns = useMemo<TableColumn<UserRole>[]>(
        () => [
            new TableColumn({
                headerName: t('main:field-name'),
                field: 'role',
                hideable: false,
                renderCell: ({ row }) =>
                    checkAccess(Rules.Role.Edit) ? <Link to={RoleRoutesPath.Edit.replace(':id', String(row.roleId))}>{row.role}</Link> : row.role,
            }),
            new TableColumn({
                headerName: t('log:field-object-type'),
                field: 'objectType',
                valueFormatter: ({ value }) => (value ? t(`main:object-type-${value}`) : ''),
            }),
            new TableColumn({
                headerName: t('main:field-organization'),
                field: 'objectId',
                hideable: false,
                renderCell: ({ row }) => {
                    let link;
                    if (ObjectType.Center === row.objectType && (checkAccess(Rules.Center.View) || checkAccess(Rules.Center.ViewOwn, [String(row.id)]))) {
                        link = CenterService.getDetailsRoute(row.objectId!);
                    }
                    if (ObjectType.Client === row.objectType && (checkAccess(Rules.Client.ViewBasic) || checkAccess(Rules.Client.ViewContacts))) {
                        link = ClientService.getViewLink(Number(row.objectId));
                    }
                    return link ? <Link to={link}>{row.object?.name}</Link> : row.object?.name
                },
            }),
        ],
        [i18n.language],
    );

    const rowActions = useRowActions<UserRole>({
        remove: checkAccess(Rules.User.DeleteRole) && props.showActions ? (row) => UserService.deleteRole([row.id]) : null,
    });

    return (
        <DataTable
            id="user-roles-table-component"
            queryKey={[EntityQueryKey.Role, props.userId]}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => UserService.userRoles({ ...requestData, userId: props.userId })}
            rowActions={rowActions}
        />
    );
};

export default UserRoleTable;
