import { FC, useCallback, useRef, useState } from "react";
import { Trans } from "react-i18next";
import { debounce } from "lodash";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { ISelectProps } from "./SelectProps";
import useTranslation from '../../hooks/translation.hook';

const AppAsyncSelect: FC<ISelectProps> = props => {
    const { t, i18n } = useTranslation();
    const loadDelay = useRef(800);
    const [focus, setFocus] = useState(false);

    const getDefaultOptions = () => {
        return props.defaultOptions ? props.defaultOptions : [];
    };

    const handleOnLoadOptions = (inputValue: string, callback: (data: { value: string; label: string }[]) => void) => {
        if (props.loadOptions) {
            if (!inputValue || inputValue.length < 1) {
                callback(getDefaultOptions());
                return;
            }

            props.loadOptions(inputValue, i18n.language).then((result: { value: string; label: string }[]) => {
                callback(result);
            });
        }
    };

    const onLoadOptions = useCallback(debounce(handleOnLoadOptions, loadDelay.current), []);

    const onFocus = () => {
        if (focus) {
            return;
        }
        setFocus(true);
    };

    const onBlur = () => {
        if (!focus) {
            return;
        }
        setFocus(false);
    };

    const { value, required, title, onChange, isClearable, options, noOptionsMessage, isDisabled, error, loadOptions, ...newProps } = props;
    const addClassName = error && error.isFocused ? "error_focused" : "";
    const hasValue = (Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value);
    return (
        <div className="form-outline">
            {options ? (
                <Select
                    {...newProps}
                    isDisabled={isDisabled}
                    value={value}
                    placeholder=""
                    options={options}
                    onChange={props.onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    isClearable={isClearable ?? false}
                    loadingMessage={() => t("main:select-message-loading")}
                    className={`${props.className ?? ""} ${addClassName}`}
                    menuPlacement={props.menuPlacement}
                />
            ) : (
                <AsyncSelect
                    {...newProps}
                    isDisabled={isDisabled}
                    value={value}
                    cacheOptions
                    placeholder=""
                    loadOptions={onLoadOptions}
                    onChange={props.onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    isClearable={isClearable ?? false}
                    noOptionsMessage={({ inputValue }) => {
                        //TODO noOptionsMessage in props
                        return t(`main:select-message-no-options${inputValue && inputValue.length > 0 ? "" : "-empty"}`);
                    }}
                    loadingMessage={() => t("main:select-message-loading")}
                    className={`${props.className ?? ""} ${addClassName}`}
                />
            )}
            {title && <label className={`select-label ${hasValue || focus ? "active" : ""} ${isDisabled ? "disabled" : ""} ${addClassName ? addClassName : ""}`}>{t(title)}</label>}
            {required && (
                <input
                    tabIndex={-1}
                    autoComplete="off"
                    style={{
                        opacity: 0,
                        width: "40%",
                        height: 0,
                        position: "absolute",
                    }}
                    defaultValue={hasValue ? "Y" : ""}
                    required
                />
            )}
            {error && error.message && (
                <div className="text-danger">
                    <Trans>{error.message}</Trans>
                </div>
            )}
        </div>
    );
};

export default AppAsyncSelect;
