import React, { FC, Suspense, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Loader from '../../../component/loader';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import BottomActions from '../../../component/actions/bottomActions';
import { ActionItem } from '../../../models/actionItem';
import { NewsService } from '../../../eenApi/news/service';
import { NewsEntity } from '../../../eenApi/news/news';
import { NewsRoutesPath } from '../routes';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TabItem from '../../../component/tabs/tabItem';
import TabControls from '../../../component/tabs/tabControls';
import TopActions from '../../../component/actions/topActions';
import ValidateViewTab from '../../../component/news/ValidateViewTab';
import Rules from '../../../rules';
import { useDetailsStates } from '../../../hooks/UseDetailsStates';
import { useChangeStatusMutation } from '../../../hooks/UseChangeStatusMutation';
import NewsStatusEnum from '../../../eenApi/news/enum/news.status.enum';

const AnnouncementTab = React.lazy(() => import('../../../component/news/ValidateAnnouncementTab'));
const DetailsTab = React.lazy(() => import('../../../component/news/ValidateDetailTab'));

export default function ValidateNews() {
    const {
        t, i18n,
        id, entity,
        activeTab, setActiveTab,
        checkAccess } = useDetailsStates<NewsEntity>({ queryKey: 'news' });

    const tabItems = useMemo(() => {
        return [
            new TabItem('main', t('news:tabs.news')),
            new TabItem('announcement', t('news:tabs.announcement')),
            new TabItem('detail', t('news:tabs.detail')),
        ];
    }, [i18n.language, id, entity?.status]);

    const actions = useMemo(() => {
        const items: ActionItem[] = [];

        if (checkAccess(Rules.News.Validate) && (entity?.status !== NewsStatusEnum.Remarks
            && entity?.status !== NewsStatusEnum.Active)) {
            items.push({
                name: t('main:btn-remarks'),
                onClick: async () => changeStatusMutation.mutate(NewsStatusEnum.Remarks),
            });
            items.push({
                name: t('main:btn-active'),
                onClick: async () => changeStatusMutation.mutate(NewsStatusEnum.Active),
            });
        }

        return items
    }, [id, entity?.status, i18n.language]);

    const changeStatusMutation = useChangeStatusMutation<NewsStatusEnum>(
        (validationStatus) => NewsService.validate(id, validationStatus), 'news');

    return (
        <MainContainer>
            <PageTitle text={t('news:validate-title')} backLink={NewsRoutesPath.CenterList} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} />
            {entity?.id && <>
                <ValidateViewTab activeId={activeTab} id='main' data={entity} />
                <Suspense fallback={<Loader />}>
                    <AnnouncementTab activeId={activeTab} id='announcement' data={entity} />
                    <DetailsTab activeId={activeTab} id='detail' data={entity} />
                </Suspense>
            </>}
            <BottomActionsContainer>
                <BottomActions actions={actions} />
            </BottomActionsContainer>
        </MainContainer>
    );
};

