import { EenApi } from '..';
import EmailSendConfigurationDto from "./email.send.configuration.dto";

class CConfigurationService {
    async saveDelivery(entity: EmailSendConfigurationDto) {
        await EenApi.put('configuration/delivery', entity);
    }

    async getDelivery() {
        return EenApi.get<EmailSendConfigurationDto>('configuration/delivery');
    }

    async saveMailing(entity: EmailSendConfigurationDto) {
        await EenApi.put('configuration/mailing', entity);
    }

    async getMailing() {
        return await EenApi.get<EmailSendConfigurationDto>('configuration/mailing');
    }

    async saveNotification(entity: EmailSendConfigurationDto) {
        await EenApi.put('configuration/notification', entity);
    }

    async getNotification() {
        return  await EenApi.get<EmailSendConfigurationDto>(`configuration/notification`);
    }
}

export const ConfigurationService = new CConfigurationService();
