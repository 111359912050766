import React, { useMemo, useState } from 'react';
import useTranslation from '../../../../../hooks/translation.hook';
import MainContainer from '../../../../../component/mainContainer';
import Filter from '../../../../../component/filter';
import FilterItemText from '../../../../../component/filter/types/filterItem/items/text';
import FilterItemSelect from '../../../../../component/filter/types/filterItem/items/select';
import FilterItemDateRange from '../../../../../component/filter/types/filterItem/items/date';
import { ErrorHandler } from '../../../../../service/errorHandler';
import { useQuery } from "react-query";
import { NewsService } from '../../../../../eenApi/news/service';
import { NewsCard } from './news-card';
import { EntityQueryKey } from "../../../../../query/query.keys";
import Loader from "../../../../../component/loader";
import { Pagination } from "@mui/material";
import { useOptions } from "../../../../../hooks/select.options.hook";
import { BoolEnum } from "../../../../../enums/bool.enum";

const NewsNetworkTable: React.FC = () => {
    const { t, i18n } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();


    const isVisitedOptions = useOptions(BoolEnum, 'main:');

    const filterItems = useMemo(() => [
        new FilterItemText('title', t('news:filter.field-name')),
        new FilterItemSelect('visited', t('news:filter.looked-through'), { options: isVisitedOptions, multiple: false }),
        new FilterItemDateRange('created', t('news:filter.creation-data')),
    ], [i18n.language]);

    const [onPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    const fetchRows = () => NewsService.networkList({
        take: onPage,
        skip: (currentPage - 1) * onPage,
        filter: filterValues,
    });

    const { data, isFetching } = useQuery(
        [EntityQueryKey.News, 'network', currentPage, onPage, JSON.stringify(filterValues), i18n.language],
        fetchRows,
        {
            onError: (err) => {
                ErrorHandler.handle(`network news load error`, err);
            },
            placeholderData: { items: [], total: 0 },
            enabled: !!filterItems
        });

    return (
        <>
            <MainContainer>
                <h2>{t('news:list.network-title')}</h2>
                <div className='d-xl-flex mb-1 mb-xl-3'>
                    <Filter id='filter-network-news'
                        items={filterItems}
                        onFind={setFilterValues}
                    />
                </div>
                {isFetching
                    ? <Loader />
                    : <>
                        {data?.items.map((news, index) => <NewsCard news={news} key={index} />)}
                        {data!.total > 0 && <div className='row my-3'>
                            <Pagination page={currentPage} count={Math.ceil(Number(data!.total) / Number(onPage))} boundaryCount={2}
                                onChange={(_, page) => setCurrentPage(page)} />
                        </div>}
                    </>
                }
            </MainContainer>
        </>
    );
};

export default NewsNetworkTable;