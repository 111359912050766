import { EenApi } from '..';
import { NewsEntity } from './news';
import { NewsRoutesPath } from '../../page/news/routes';
import NewsEditDto from './dto/news.edit.dto';
import NewsDto from './dto/news.dto';
import NewsStatusEnum from './enum/news.status.enum';

class CNewsService {
    async list(requestData?: any) {
        return await EenApi.list<NewsEntity>('news/list', requestData);
    }

    async networkList(requestData?: any) {
        return await EenApi.list<NewsDto>('news/networkNews', requestData);
    }

    async add(requestData: NewsEditDto): Promise<number> {
        return await EenApi.post<number>('news/add', requestData);
    }

    async get(id: number) {
        return await EenApi.get<NewsEntity>(`news/get/${id}`);
    }

    async delete(ids: string | number | string[] | number[]) {
        if (!Array.isArray(ids)) {
            ids = [ids as string];
        }
        return EenApi.delete(`news/delete`, { ids: ids });
    }

    async update(requestData: NewsEditDto) {
        return await EenApi.put<number>('news/edit', requestData);
    }

    save(data: NewsEditDto): Promise<any> {
        return (data.id ?? 0) > 0 ? EenApi.put<any>('news/edit', data) : EenApi.post<any>('news/add', data);
    }

    async validate(id: string | number, status: NewsStatusEnum | undefined): Promise<any> {
        return await EenApi.post('news/validate', { id, status });
    }

    async addOrUpdate(requestData: NewsEditDto) {
        if (requestData.id === 0 || requestData.id === undefined) {
            return await this.add(requestData);
        }
        return await EenApi.put<number>('news/edit', requestData);
    }

    getDetailsRoute(id: number | string) {
        return NewsRoutesPath.View.replace(':id', String(id));
    }

    getEditRoute(id: number | string) {
        return NewsRoutesPath.Edit.replace(':id', String(id));
    }
}

export const NewsService = new CNewsService();
