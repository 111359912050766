import TabPanel from '../../tabs/tabPanel';
import { InputControl, CheckboxControl } from '../../common/ui';
import IEditUnsubscribeProps from '../edit.unsubscribe.props';
import { Length } from '../../../hooks/UseValidation/validators';

export default function UnsubscribeEmailEdit({ data, updateData, ...rest }: IEditUnsubscribeProps) {
    return (
        <TabPanel {...rest}>
            <CheckboxControl value={data.notification}
                labelKey='mailing:unsubscribe.notification'
                onChange={(data) => {
                    updateData('notification', data)
                }} />
            <CheckboxControl value={data.mailing}
                labelKey='mailing:unsubscribe.mailing'
                onChange={(data) => updateData('mailing', data)} />
            <InputControl
                labelKey='mailing:unsubscribe.comment'
                onChange={(data) => {
                    updateData('comment', data)
                }
                }
                value={data.comment}
                validators={[Length(300)]}
            />
        </TabPanel>
    );
}