
import { NotEmpty } from '../../../hooks/UseValidation/validators';
import { InputControl, CheckboxControl, DictionaryControl, SelectControl, } from '../../common/ui';
import TabPanel from '../../tabs/tabPanel';
import IEditPanelProps from '../edit.panel.props';
import ViewField from '../../fields/viewField';
import FileList from '../../fields/fileList';
import FileUploader from '../../fileUploader';
import InputContainer from '../../inputContainer';
import { useContext, useState } from 'react';
import { DictionaryType } from '../../../models/dictionaryType';
import useTranslation from '../../../hooks/translation.hook';
import { GenderOption } from '../../../eenApi/user/enum/user.gender.options';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import LanguageEnum from '../../../enums/language.enum';

const genderOptions = (t: any): GenderOption[] => {
    return [
        {
            label: t('user:boolean.female'),
            value: 'false',
        },
        {
            label: t('user:boolean.male'),
            value: 'true',
        },
    ];
};

export default function MainEditTab({ data, updateData, ...rest }: IEditPanelProps) {
    const { checkAccess } = useContext(UserContext);
    const [file, setFile] = useState(data?.photo ? [data.photo] : []);
    const { t, i18n } = useTranslation();

    const onUploaded = (file: { id: string; name: string }) => {
        setFile([file]);
        updateData('photo', file);
        updateData('photoId', file.id)
    };
    const onRemoveFile = () => {
        setFile([]);
        updateData('photo', null);
        updateData('photoId', null)
    };

    return (
        <TabPanel {...rest}>
            <InputContainer>
                <ViewField title='user:fields.photo'>
                    <FileList files={file} onRemove={onRemoveFile} />
                </ViewField>
                {file.length < 1 && <FileUploader onFileUploaded={onUploaded} />}
            </InputContainer>
            <InputControl
                labelKey="user:fields.fullName"
                onChange={(value) => updateData('fullName', value)}
                value={data.fullName}
                required
                validators={[NotEmpty]}
                tab="main"
            />
            <CheckboxControl
                labelKey="user:fields.active"
                onChange={(value) => updateData('active', value)}
                value={data.active}
                disabled={!!checkAccess(Rules.User.ChangeActive)}
            />
            <CheckboxControl
                labelKey="user:fields.isEmailVerified"
                onChange={(value) => updateData('isEmailVerified', value)}
                value={data.isEmailVerified}
                disabled={!checkAccess(Rules.User.ChangeEmailVerified)}
            />
            <SelectControl options={genderOptions(t)} labelKey="user:fields.gender"
                value={String(data.gender)} onChange={value => updateData('gender', value === 'true')}
                required validators={[NotEmpty]} />
            <InputControl
                labelKey="user:fields.email"
                onChange={(value) => updateData('email', value)}
                value={data.email}
                required
                validators={[NotEmpty]}
                tab="main"
            />
            <InputControl
                labelKey="user:fields.phone"
                onChange={(value) => updateData('phone', value)}
                required
                validators={[NotEmpty]}
                value={data.phone}
            />
            <InputControl
                labelKey="user:fields.linkedIn"
                onChange={(value) => updateData('linkedIn', value)}
                value={data.linkedIn}
            />
            <InputControl
                labelKey="user:fields.telegram"
                onChange={(value) => updateData('telegram', value)}
                value={data.telegram}
            />
            <InputControl labelKey={'user:fields.dateOfBirth'}
                onChange={value => updateData('dateOfBirth', value)}
                value={data.dateOfBirth?.substring(0, 10)}
                type="date"
                required
                validators={[NotEmpty]} tab='main' />
            <DictionaryControl
                labelKey="user:fields.country"
                value={data.memberCountryId ?? null}
                onChange={value => {
                    updateData('memberCountryId', value ?? null)
                }}
                dictionary={DictionaryType.MemberCountry}
                language={i18n.language as LanguageEnum}
                required validators={[NotEmpty]}
                minInput={0}
                tab='main'
            />
        </TabPanel>
    );
}
