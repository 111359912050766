import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { useContext, useEffect, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import MainEditTab from '../../../component/membercountry/MainEditTab';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import { MemberCountry } from '../../../eenApi/membercountry/dto/member.country';
import { MemberCountryRoutesPath } from '../routes';
import { MemberCountryEditValidator } from '../../../utils/validators/membercountry/edit.validator';
import { MemberCountryService } from '../../../eenApi/membercountry/member.country.service';
import LanguageEnum from '../../../enums/language.enum';
import { DeleteModal } from '../../../component/common/ui';
import { LoaderTab } from '../../../component/common/layout';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';

export function MemberCountryEdit() {
    const { checkAccess } = useContext(UserContext);
    const {
        t,
        i18n,
        history,
        isFetching,
        data,
        entity,
        activeTab,
        setActiveTab,
        updateData,
        validateOn,
        tabs,
        setTabs,
        saveActions,
        isDeleteOpen,
        closeDelete,
        remove,
    } = useEditPageWithDelete<MemberCountry>({
        dataFactory: (entity, language) => new MemberCountry(entity, language as LanguageEnum),
        queryKey: 'membercountry',
        handler: (data) => MemberCountryService.save(data, checkAccess(Rules.MemberCountry.Edit)),
        validator: MemberCountryEditValidator,
        applyRoute: MemberCountryService.getEditRoute,
        saveRoute: MemberCountryService.getDetailsRoute,
        cancelRoute: [MemberCountryRoutesPath.List, MemberCountryService.getDetailsRoute],
        canDelete: Rules.MemberCountry.Delete,
        deleteHandler: (id) => MemberCountryService.remove(id),
    });

    useEffect(() => {
        if (isFetching) return;
        if (
            !!entity &&
            !checkAccess(Rules.MemberCountry.Edit) &&
            !checkAccess(Rules.MemberCountry.EditBasic, entity.centerDtos?.map((c) => String(c.id)) ?? [])
        ) {
            history.replace('/error/access-denied');
        }
    }, [entity?.id, isFetching]);

    const tabItems = useMemo(() => {
        return [new TabItem('main', t('membercountry:tab.main'))];
    }, [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`membercountry:title.${entity?.id ? 'edit' : 'add'}`)} backLink={MemberCountryRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={saveActions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <LoaderTab activeId={activeTab} />
                {data?.language && !isFetching && (
                    <>
                        <MainEditTab activeId={activeTab} id="main" entity={entity} data={data} updateData={updateData} />
                        <BottomActionsContainer>
                            <BottomActions actions={saveActions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}
