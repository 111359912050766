import React, { FC, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import SupportService from '../../../../eenApi/support/service';
import { SupportRoutesPath } from '../../routes';
import SupportSubjectDto from '../../../../eenApi/support/subjects/dto/support.subject.dto';
import useTranslation from '../../../../hooks/translation.hook';
import { TableColumn } from '../../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../../component/common/layout';
import { EntityQueryKey } from '../../../../query/query.keys';
import { useGroupDeleteAction, useRowActions } from '../../../../component/common/layout/DataTable/table.utils';
import { GridSelectionModel } from '@mui/x-data-grid';
import { UserContext } from '../../../../context/user-context';
import Rules from '../../../../rules';

interface IProps {
    filterValues?: { [key: string]: any };
}

const SubjectsTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);

    const columns = useMemo<TableColumn<SupportSubjectDto>[]>(
        () => [
            new TableColumn({
                headerName: t('support:fields.name'),
                field: 'name',
                renderCell: ({ id, value, row }) => checkAccess(Rules.Support.EditSubject)
                    ? <Link to={`${SupportRoutesPath.EditSubject.replace(':id', id.toString())}`}>{value}</Link>
                    : value
            }),
            new TableColumn({
                headerName: t('support:fields.fullName'),
                field: 'responsableUser',
                valueGetter: ({ row }) => row.responsableUser?.fullName || row.responsableUser?.email
            }),
            new TableColumn({
                headerName: t('support:fields.requestCount'),
                field: 'requestCount',
                type: 'number',
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => SupportService.removeSubject(ids.map((id) => Number(id)));

    const rowActions = useRowActions<SupportSubjectDto>(
        {
            edit: (row) => SupportRoutesPath.Edit.replace(':id', row.id!.toString()),
            hideEdit: () => !checkAccess(Rules.Support.EditSubject),
            remove: (row) => clickDelete([row.id!]),
            hideRemove: () => !checkAccess(Rules.Support.DeleteSubject),
        },
    );

    const groupActions = useGroupDeleteAction({
        remove: checkAccess(Rules.Support.DeleteSubject) ? clickDelete : null,
    });

    return (
        <DataTable
            id="support-subjects-table-component"
            queryKey={EntityQueryKey.SupportSubject}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => SupportService.listSubjects(requestData)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
};

export default SubjectsTable;
