import LanguageEnum from '../../../enums/language.enum';
import { DictionaryEntity } from '../../../models/dictionaryEntity';
import { SelectItemDto } from '../../common/select.item.dto';
import DriveFileDto from '../../drive/dto/drive.file.dto';
import { User } from '../../user/user';
import EventStatusEnum from '../enum/event.status.enum';
import EventTypeEnum from '../enum/event.type.enum';

export default class EventDto {
    id?: number;
    created: string = '';
    createdBy?: User;
    isExists: boolean = true;
    title: string = '';
    description: string = '';
    locationName: string = '';
    locationAddress: string = '';
    locationPhone: string = '';
    deadlineRegistration: string = '';
    city: string = '';
    lastModified: string = '';
    preliminaryDescription: string = '';
    publishOnCalendar: boolean = true;
    type: EventTypeEnum.Brokerage | string = '';
    eenId: string = '';
    activitiesEligibleWorkProgramme: boolean = true;
    status = EventStatusEnum.Draft;
    startDate: string = '';
    endDate: string = '';
    center?: SelectItemDto | null;
    closedDate: string = '';
    organizationHosting: string = '';
    coOrganisers: string = '';
    otherPartners: string = '';
    language: LanguageEnum;
    website: string = '';
    mapFileIds: string[] = [];
    mapFiles?: DriveFileDto[] = [];
    contactUser?: User | undefined = undefined;
    contactUserId: number = 0;
    memberCountryId = '';
    memberCountry?: DictionaryEntity;

    constructor(entity: EventDto | undefined, language: LanguageEnum) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof EventDto] = val as never;
            }
        });

        this.language = entity?.language ?? language;
        this.mapFileIds = entity?.mapFiles?.map((x) => x.id) ?? [];
        this.memberCountryId = entity?.memberCountry?.id ?? '';
        this.contactUserId = entity?.contactUser?.id ?? 0;
    }
}
