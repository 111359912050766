import { FC, useContext, useState } from "react";
import UserSubcriptionTable from "./table";
import { UserContext } from "../../../../context/user-context";
import Rules from "../../../../rules";
import { useCurrentUser } from "../../../../hooks/useCurrentUser";

interface IProps {
    userId: number;
}

const NotificationSubscriptions: FC<IProps> = ({ userId }) => {
    const [filterValues] = useState<{ [key: string]: any }>({});
    const { checkAccess } = useContext(UserContext);
    const { data: currentUser } = useCurrentUser();

    return (
        <>
            <div className="d-xl-flex mb-1 mb-xl-3 mt-3">
                <div className="app-filter-actions mb-2 mb-xl-0"></div>
            </div>
            {(checkAccess(Rules.User.ViewNotifications) || currentUser?.user?.id === userId) &&
                <UserSubcriptionTable filterValues={filterValues} userId={userId} />}
        </>
    );
};

export default NotificationSubscriptions;
