import { FC } from 'react';
import Modal from 'react-modal';
import { useSpring, animated } from 'react-spring';

interface IProps {
    isOpen: boolean;
    onRequestClose: any;
    shouldCloseOnEsc?: boolean;
    modalSize?: string;
    scrollable?: boolean;
    centered?: boolean;
    children?: React.ReactNode;
}

const BaseModal: FC<IProps> = (props) => {
    const springContentProps = useSpring({
        transform: `translate3d(0,${props.isOpen ? 0 : -200}px,0)`,
    });

    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            className={`modal-dialog ${props.centered ? 'modal-dialog-centered' : ''} ${props.scrollable ? 'modal-dialog-scrollable' : ''
                } modal-notify modal-app modal-${props.modalSize ?? 'xl'}`}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    overflowY: 'scroll',
                    zIndex: 1200,
                },
            }}
            shouldCloseOnEsc={props.shouldCloseOnEsc !== undefined ? props.shouldCloseOnEsc : true}
        >
            <animated.div className="modal-content" style={springContentProps}>
                {props.children}
            </animated.div>
        </Modal>
    );
};

export default BaseModal;
