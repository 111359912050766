import { Suspense, useContext, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import Rules from '../../../rules';
import PageTitle from '../../../component/pageTitle';
import { UserService } from '../../../eenApi/user/service';
import { UserRoutesPath } from '../routes';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TopActions from '../../../component/actions/topActions';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import { LoaderTab } from '../../../component/common/layout';
import { DeleteModal } from '../../../component/common/ui';
import Loader from '../../../component/loader';
import TabControls from '../../../component/tabs/tabControls';
import TabItem from '../../../component/tabs/tabItem';
import LanguageEnum from '../../../enums/language.enum';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import UserEditDto from '../../../eenApi/user/dto/user.edit.dto';
import { UserEditValidator } from '../../../utils/validators/user/edit.validator';
import UserRoles from '../component/userRoles';
import { UserContext } from '../../../context/user-context';
import NotificationSubscriptions from '../component/notificationSubscriptions';
import UserCompWrapper from '../../../component/user/UserFCWrapper';
import UserDetailsTab from '../../../component/user/MainDetaillTab';
import { ActionItem } from '../../../models/actionItem';


export default function UserDetail() {
    const { data: currentUser } = useCurrentUser();
    const { checkAccess } = useContext(UserContext);

    const {
        t,
        i18n,
        isFetching,
        id,
        data,
        entity,
        activeTab,
        setActiveTab,
        validateOn,
        history,
        tabs,
        setTabs,
        queryClient,
        saveActions,
        isDeleteOpen,
        deleteButton,
        closeDelete,
        remove,
    } = useEditPageWithDelete<UserEditDto>({
        dataFactory: (entity, language) => new UserEditDto(entity, language as LanguageEnum),
        queryKey: 'user',
        handler: (data) => UserService.save(data),
        validator: UserEditValidator,
        applyRoute: UserService.getEditLink,
        saveRoute: UserService.getDetailLink,
        cancelRoute: [UserRoutesPath.View, UserService.getDetailLink],
        canDelete: Rules.User.Delete,
        deleteHandler: (id) => UserService.delete([Number(id)]),
    });

    const tabItems = useMemo(() => {
        const items: TabItem[] = [];
        items.push(new TabItem('main', t('user:tabs.main')));
        if (checkAccess(Rules.User.ListRoles)) {
            items.push(new TabItem('roles', t('user:tabs.roles')));
        }
        if (entity?.id === currentUser!.user?.id || checkAccess(Rules.User.ViewNotifications)) {
            items.push(new TabItem('notification', t('user:tabs.notification')));
        }
        return items;
    }, [i18n.language, entity]);

    const actions = useMemo<ActionItem[]>(() => {
        const items: ActionItem[] = [];
        if (checkAccess(Rules.User.Edit) || currentUser?.user?.id === entity?.id) {
            items.push({
                name: t('main:btn-edit'),
                onClick: async () => {
                    await queryClient.invalidateQueries(['user', id]);
                    history.push(UserRoutesPath.Edit.replace(':id', id!));
                },
                icon: 'fas fa-pen',
            });
        }

        if (checkAccess(Rules.User.Delete) || entity?.createdBy?.userId === currentUser!.user?.id) {
            items.push(deleteButton);
        }
        return items;

    }, [entity, i18n.language]);


    return (
        <MainContainer>
            <PageTitle text={entity ? entity.fullName : ''} backLink={UserRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={saveActions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <LoaderTab activeId={activeTab} />
                {!isFetching && (
                    <>
                        <UserDetailsTab activeId={activeTab} id="main" entity={data} />
                        {entity && (checkAccess(Rules.User.ListRoles)) && (

                            <UserCompWrapper activeId={activeTab} id="roles">
                                <Suspense fallback={<Loader />}>
                                    <div>
                                        <UserRoles userId={entity.id} />
                                    </div>
                                </Suspense>
                            </UserCompWrapper>
                        )}
                        {entity && (entity.id === currentUser!.user?.id || checkAccess(Rules.User.ViewNotifications)) && (
                            <UserCompWrapper activeId={activeTab} id="notification">
                                <div>
                                    <NotificationSubscriptions userId={entity.id} />
                                </div>
                            </UserCompWrapper>
                        )}
                        {activeTab === "main" && (
                            <BottomActionsContainer>
                                <BottomActions actions={actions} />
                            </BottomActionsContainer>
                        )}
                    </>
                )}
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}
