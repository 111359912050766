import { InputBase, Button, Box } from "@mui/material";
import React, { useState } from "react";
import { FC } from "react";
import useTranslation from "../../../hooks/translation.hook";

interface CommentEditorProps {
    comment: string;
    onSave: (newComment: string) => void;
    onCancel: () => void;
}

export const CommentEditor: FC<CommentEditorProps> = ({ comment, onSave, onCancel }) => {
    const [editedComment, setEditedComment] = useState(comment);
    const { t } = useTranslation();

    const handleSave = () => {
        onSave(editedComment);
    };

    return (
        <Box border={1}
            borderColor="primary.main"
            borderRadius={2}
            p={1}>
            <InputBase
                multiline
                value={editedComment}
                onChange={(e) => setEditedComment(e.target.value)}
            />
            <Box>
                <Button onClick={handleSave}>{t('chat:button.save')}</Button>
                <Button onClick={onCancel}>{t('chat:button.cancel')}</Button>
            </Box>
        </Box>
    );
};
