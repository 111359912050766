import React, { useContext, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import { ProfileRoutesPath } from '../routes';
import useTranslation from '../../../hooks/translation.hook';
import ProfileDto from '../../../eenApi/profile/dto/profile.dto';
import { ClientRoutesPath } from '../../client/routes';
import ProfileService from '../../../eenApi/profile/service';
import LanguageEnum from '../../../enums/language.enum';
import ProfileStatusEnum from '../../../eenApi/profile/enum/profile.status.enum';
import { ObjectType } from '../../../enums/objectType';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useParams } from 'react-router';
import { AccessActionEnum } from '../../../models/access.dto';
import { TableColumn, TableRowAction } from '../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { useGroupDeleteAction, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import classNames from "classnames";

interface IProps {
    filterValues?: { [key: string]: any };
    validation: boolean;
    prefix?: string;
}

export function ProfileTable(props: IProps) {
    const currentUser = useCurrentUser();
    const { checkAccess } = useContext(UserContext);
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { centerId } = useParams<{ centerId?: string }>();

    const columns = useMemo<TableColumn<ProfileDto>[]>(
        () => [
            new TableColumn({
                headerName: t('profile:fields.id'),
                field: 'id',
                sortable: true,
                width: 110,
            }),
            new TableColumn({
                headerName: t('profile:fields.title'),
                field: 'title',
                renderCell: ({ row, id, value }) =>
                    row.accessDto[AccessActionEnum.View]
                      ? <Link to={`${ProfileRoutesPath.View.replace(':id', id!.toString())}`}
                              className={classNames({ 'font-weight-bold': props.validation && row.isNotViewed })}>
                          {value}
                        </Link>
                      : <span className={classNames({ 'font-weight-bold': props.validation && row.isNotViewed })}>value</span>,
            }),
            new TableColumn({
                headerName: t('profile:fields.clientId'),
                field: 'clientId',
                renderCell: ({ row }) => <Link to={`${ClientRoutesPath.View.replace(':id', row.client?.id!.toString())}`}>{row.client?.name}</Link>,
            }),
            new TableColumn({
                headerName: t('profile:fields.profileType'),
                field: 'type',
                valueFormatter: ({ value }) => t(`profile:type.${value}`),
            }),
            new TableColumn({
                headerName: t('profile:fields.status'),
                field: 'status',
                width: 150,
                valueFormatter: ({ value }) => t(`profile:status.${value}`),
            }),
        ],
        [i18n.language, props.validation],
    );

    const clickDelete = (ids: (number | string)[]) => ProfileService.removeAll(ids);
    const clickNavigate = (link: string) => history.push(link);
    const clickStatusChange = (id: number, language: LanguageEnum, status: ProfileStatusEnum) => ProfileService.status(id, language, status);

    const rowActions = useRowActions<ProfileDto>(
        {
            edit: checkAccess(Rules.Profile.Edit) || checkAccess(Rules.Profile.EditOwn) ? (row) => ProfileRoutesPath.Edit.replace(':id', String(row.id)) : null,
            hideEdit: (row) => !row.accessDto[AccessActionEnum.Update],
            remove: checkAccess(Rules.Profile.Delete) || checkAccess(Rules.Profile.DeleteOwn) ? (row) => clickDelete([row.id!]) : null,
            hideRemove: (row) => !row.accessDto[AccessActionEnum.Delete],
        },
        useMemo<TableRowAction<ProfileDto>[]>(() => {
            if (!!centerId) return [];

            const items: TableRowAction<ProfileDto>[] = [];

            items.push({
                id: 'send',
                label: t('main:btn-sendValidation'),
                action: (item: ProfileDto) => clickStatusChange(item.id!, item.language, ProfileStatusEnum.Validation),
                isHide: (item: ProfileDto) =>
                    !item.accessDto[AccessActionEnum.Status] || (item.status !== ProfileStatusEnum.Draft && item.status !== ProfileStatusEnum.CommentsReceived),
            });

            if (props.validation) {
                items.push({
                    id: 'remarks',
                    label: t('main:btn-remarks'),
                    action: (item: ProfileDto) => clickStatusChange(item.id!, item.language, ProfileStatusEnum.CommentsReceived),
                    isHide: (item: ProfileDto) => !item.accessDto[AccessActionEnum.Status] || item.status !== ProfileStatusEnum.Validation,
                });
                if (checkAccess(Rules.Profile.StatusActive)) {
                    items.push({
                        id: 'activate',
                        label: t('main:btn-active'),
                        action: (item: ProfileDto) => clickStatusChange(item.id!, item.language, ProfileStatusEnum.Active),
                        isHide: (item: ProfileDto) => item.status !== ProfileStatusEnum.Validation,
                    });
                }
            }

            if (checkAccess(Rules.Profile.InterestEdit) && currentUser.data?.rules?.find((r) => r.objectType === ObjectType.Client)) {
                items.push({
                    id: 'interest',
                    label: t('main:btn-interest'),
                    action: (item: ProfileDto) => clickNavigate(`${ProfileRoutesPath.InterestCreate}?profileId=${item.id}`),
                    refreshTableAfterAction: false,
                    isHide: (item: ProfileDto) => item.status !== ProfileStatusEnum.Active,
                });
            }

            return items;
        }, [props.validation, i18n.language, centerId]),
        [props.validation, centerId],
    );
    const groupActions = useGroupDeleteAction(
        {
            remove: !centerId && (checkAccess(Rules.Profile.Delete) || checkAccess(Rules.Profile.DeleteOwn)) ? clickDelete : null,
        },
        centerId,
    );

    return (
        <DataTable
            id={!centerId ? `profile-${props.prefix}-table-component` : 'center-profiles-table'}
            queryKey={[EntityQueryKey.Profile, props.prefix, centerId]}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => ProfileService.list({ ...requestData, centerId }, props.prefix)}
            rowActions={!centerId ? rowActions : undefined}
            groupActions={groupActions}
        />
    );
}
