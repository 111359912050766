import { MDBBtn, MDBInput } from 'mdb-react-ui-kit';
import React, { FC, useEffect, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { useHistory, useParams } from 'react-router';
import DefaultBlock from '../../../component/defaultBlock';
import InputContainer from '../../../component/inputContainer';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { ExternalProviderService } from '../../../eenApi/externalProvider/service';
import { ExternalProvider } from '../../../eenApi/externalProvider/externalProvider';
import { ErrorHandler } from '../../../service/errorHandler';
import { ToastManager } from '../../../service/toastManager';
import { ExternalProviderRoutesPath } from '../routes';
import { SelectValue } from '../../../component/select/SelectValue';
import AppAsyncSelect from '../../../component/select/appAsyncSelect';
import { ExternalProviderParamDescription } from '../../../eenApi/externalProvider/externalProviderParam';
import FieldString from '../component/fieldString';
import { FieldType } from '../../../enums/fieldType';
import FieldInt from '../component/fieldInt';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { useLocation } from "react-router-dom";
import { SettingsRoutesPath } from "../../settings/routes";

const ExternalProviderEdit: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const history = useHistory();
    const currentUser = useCurrentUser();
    const [typeOptions, setTypeOptions] = useState<SelectValue[]>([]);
    const [providerParams, setProviderParams] = useState<ExternalProviderParamDescription[]>();
    const { state } = useLocation<{ returnParam: string }>();

    const [entity, setEntity] = useState<ExternalProvider>({
        id: id ? parseInt(id) || 0 : 0,
        name: '',
        params: [],
    });

    useEffect(() => {
        loadTypes();
    }, []);

    useEffect(() => {
        refreshData();
    }, [entity.id]);

    useEffect(() => {
        loadTypeParams();
    }, [entity.type]);

    const refreshData = async () => {
        if (entity.id < 1 || !currentUser.data?.language) {
            return;
        }

        try {
            const result = await ExternalProviderService.get(entity.id, currentUser.data?.language);
            if (result) {
                setEntity({
                    ...result,
                });
            }
        } catch (err) {
            ErrorHandler.handle('extproviderEdit refreshData', err);
        }
    };

    const loadTypes = async () => {
        try {
            const result = await ExternalProviderService.getTypes();
            setTypeOptions(
                result.map((x) => {
                    return {
                        value: x.code,
                        label: x.code,
                    };
                }),
            );
        } catch (err) {
            ErrorHandler.handle('extproviderEdit types', err);
        }
    };

    const loadTypeParams = async () => {
        if (!entity.type) {
            setProviderParams([]);
            return;
        }

        try {
            const result = await ExternalProviderService.getTypeParams(entity.type);
            setProviderParams(result);
        } catch (err) {
            ErrorHandler.handle('extproviderEdit type params', err);
        }
    };

    const saveAction = async () => {
        try {
            const rd = {
                ...entity,
                language: currentUser.data?.language,
            };
            const result = await ExternalProviderService.addOrUpdate(rd);
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });

            if (state?.returnParam) {
                history.replace({
                    pathname: SettingsRoutesPath.Notification,
                    state: {
                        [state.returnParam]: result
                    }
                })
                return
            }

            if (entity.id < 1) {
                history.push(ExternalProviderService.getEditLink(result));
            }
            setEntity({ ...entity, id: result });
        } catch (err) {
            ErrorHandler.handle('extprovider add/edit', err);
        }
    };

    const onSave = async (e: React.FormEvent) => {
        e.preventDefault();
        saveAction();
    };

    const onChangeParam = async (name: string, data: string) => {
        const params = entity.params;
        const index = params.findIndex((x) => x.name === name);
        if (index > -1) {
            params[index].data = data;
        } else {
            params.push({
                data: data,
                name: name,
            });
        }
        setEntity({ ...entity, params: params });
    };

    return (
        <MainContainer>
            <PageTitle text={t(entity.id > 0 ? 'extprovider:edit-title' : 'extprovider:create-title')} backLink={ExternalProviderRoutesPath.List} />
            <DefaultBlock>
                <form onSubmit={onSave}>
                    <InputContainer>
                        <MDBInput
                            type="text"
                            size="lg"
                            value={entity.name}
                            name="name"
                            onChange={(e: any) => setEntity({ ...entity, name: e.target.value })}
                            required
                            label={t('main:field-name')}
                        />
                    </InputContainer>
                    <InputContainer>
                        <AppAsyncSelect
                            className="select-lg"
                            value={typeOptions?.find((x) => x.value === entity.type)}
                            options={typeOptions}
                            onChange={(selected: SelectValue) => setEntity({ ...entity, type: selected?.value })}
                            title={'main:field-type'}
                        />
                    </InputContainer>
                    {providerParams?.map((item) => (
                        <InputContainer key={`${entity.type}_${item.name}_${item.type}`}>
                            {item.type === FieldType.String && (
                                <FieldString
                                    value={entity.params.find((x) => x.name === item.name)?.data}
                                    onChange={(data) => onChangeParam(item.name, data)}
                                    required={item.isRequired === 'Y' ? true : undefined}
                                    title={t(`extprovider:field-${entity.type}-${item.name}`)}
                                />
                            )}
                            {item.type === FieldType.Int && (
                                <FieldInt
                                    value={entity.params.find((x) => x.name === item.name)?.data}
                                    onChange={(data) => onChangeParam(item.name, data)}
                                    required={item.isRequired === 'Y' ? true : undefined}
                                    title={t(`extprovider:field-${entity.type}-${item.name}`)}
                                />
                            )}
                        </InputContainer>
                    ))}
                    <div>
                        <MDBBtn type="submit" color="primary">
                            {t('main:btn-save')}
                        </MDBBtn>
                    </div>
                </form>
            </DefaultBlock>
        </MainContainer>
    );
};

export default ExternalProviderEdit;
