import { EntityQueryKey } from '../../../query/query.keys';

export enum UnviewedItemTypeEnum {
    Profile = 'Profile',
    ProfileValidadion = 'ProfileValidadion',
    Specialist = 'Specialist',
    SpecialistValidadion = 'SpecialistValidadion',
    ProfileInterest = 'ProfileInterest',
    EventValidation = 'EventValidation',
    Support = 'Support',
    News = 'News',
}

export function matchEntity(key: UnviewedItemTypeEnum): EntityQueryKey {
  switch (key) {
      case UnviewedItemTypeEnum.Profile:
      case UnviewedItemTypeEnum.ProfileValidadion:
        return EntityQueryKey.Profile
    default:
      console.error(`No match for unviewd entity [${key}]`)
      return EntityQueryKey.Unsupported
  }
}