import React, { FC, useEffect, useRef, useState } from 'react';
import { arrayMoveImmutable } from 'array-move';
import Moment from 'moment';
import FilterType from './types/filterType';
import FilterRangeType from './types/filterRangeType';
import Utils from '../../utils';
import { FilterService } from '../../eenApi/filter/service';
import FilterModalContainer from './component/modal/container';
import FilterModal from './component/modal';
import FilterItem from './types/filterItem';
import { RequestData } from './types/requestData';
import { FilterEntity } from './types/filter';
import './css/style.css';
import useTranslation from '../../hooks/translation.hook';
import { useHistory } from "react-router-dom";
import { CustomFilterDto } from "../../eenApi/filter/dto/custom.filter.dto";
import { CustomFilterItemDto } from "../../eenApi/filter/dto/custom.filter.item.dto";

interface IProps {
    id: string,
    items: FilterItem[],
    query?: { [key: string]: any },
    onFind: (data: any) => void
}

const Filter: FC<IProps> = (props) => {
    const [currentFilterId, setCurrentFilterId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [requestParams, setRequestParams] = useState<RequestData[]>([]);
    const [loadedFilters, setLoadedFilters] = useState<{ [key: string]: FilterEntity }>({});
    const [filterSorting, setFilterSorting] = useState<number[]>([]);
    const delay = useRef(700);
    const findTimestamp = useRef(0);
    const history = useHistory();

    const { t, i18n } = useTranslation();

    const init = async () => {
        await loadFilters();
        await loadStateParams();
    };

    useEffect(() => {
        props.onFind(undefined)
        init();
    }, [history.location.pathname, history.location.state]);

    useEffect(() => {
        find();
    }, [requestParams]);

    useEffect(() => {
        const map: any = {};
        props.items.forEach(x => map[x.Name] = x.Title);
        const data = [...requestParams];
        data.forEach(x => x.title = map[x.name]);
        setRequestParams(data);
    }, [i18n.language])

    const loadFilters = async () => {
        try {
            const entities = await loadFiltersRequest();
            await loadFiltersEndAction(entities);
        } catch (err) {
            await loadFiltersEndAction([]);
        }
    };

    const loadFiltersRequest = async () => {
        const result = await FilterService.list(props.id);
        const items: FilterEntity[] = [];
        if (result && Array.isArray(result)) {
            result.forEach((item) => {
                items.push({
                    id: item.id!,
                    name: item.title,
                    data: item.customFilterItems,
                    default: false
                });
            });
        }
        return items
    };

    const getFilterItemByName = (name: string) => {
        const index = props.items.findIndex(x => x.Name === name);
        if (index < 0)
            return false;
        return props.items[index];
    };

    const loadFiltersEndAction = (entities: any[]) => {
        const filters: { [key: string]: FilterEntity } = {};
        entities.forEach(function(item) {
            filters[item.id] = item;
        });

        setLoadedFilters(filters);
        setFilterSorting(Object.keys(filters) as any);
    };

    const saveSortingRequest = async () => { };

    const loadStateParams = () => {
        if (props.query) {
            const query = props.query;
            const queryArray: RequestData[] = [];
            Object.keys(query).forEach((key) => {
                const item = getFilterItemByName(key);
                if (item) {
                    queryArray.push({
                        name: key,
                        title: item.Title,
                        value: query[key]
                    });
                }
            });
            if (queryArray.length > 0) {
                setCurrentFilterId('');
                setRequestParams(queryArray);
                return;
            }
        }

        const stateString = getFromLocalStorage(props.id);
        if (!stateString) {
            find();
            return;
        }

        const result = Utils.tryParseJson<{ currentFilterId: string, requestParams: RequestData[] }>(stateString);
        setCurrentFilterId(result && result.currentFilterId ? result.currentFilterId : '');
        setRequestParams(result && Array.isArray(result.requestParams) ? result.requestParams.slice() : []);
    };

    const clickFind = (e: React.SyntheticEvent) => {
        if (e) e.preventDefault();
        closeModal();
        find();
    };

    const clickReset = (e: React.SyntheticEvent) => {
        if (e) e.preventDefault();
        setCurrentFilterId('');
        removeRequestParams();
        closeModal();
    };

    const clickClear = (e: React.SyntheticEvent) => {
        if (e) e.preventDefault();
        setCurrentFilterId('');
        removeRequestParams(true);
    };

    const removeRequestParams = (all = false) => {
        setRequestParams(requestParams.map((item) => {
            if (item.title || all) {
                item.value = false;
            }
            return item;
        }));
    };

    const clickRemoveUserFilters = async () => {
        await removeLoadedFilters();
        await loadFilters();
    };

    const removeLoadedFilters = async () => {
        const keys = Object.keys(loadedFilters);
        if (keys.length < 1) {
            return;
        }
        const ids = keys.filter(x => loadedFilters[x].default);
        if (ids.length < 1) {
            return;
        }

        await removeFilterRequest(ids);
    };

    const find = () => {
        const timestamp = new Date().getTime();
        findTimestamp.current = timestamp;
        setTimeout(function() {
            if (findTimestamp.current !== timestamp)
                return;
            findAction();
        }, delay.current);
    };

    const findAction = () => {
        trySaveStateParams();
        const filterParams = fillRequestFilter();
        props.onFind(filterParams);
    };

    const trySaveStateParams = () => {
        try {
            saveInLocalStorage(`${props.id}`, JSON.stringify({
                currentFilterId: currentFilterId,
                requestParams: requestParams,
            }));
        } catch (err) {
            console.error('save filter params', err);
        }
    };

    // const openModal = () => {
    //     setShowModal(true);
    //     if (inputText.current) {
    //         inputText.current.focus();
    //     }
    // }

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    /**
     * Показ полей фильтра по умолчанию
     */
    const loadFilterDefaultFields = () => {
        const data = [...requestParams];
        props.items.forEach((item) => {
            const index = data.findIndex(x => x.name === item.Name);
            if (index < 0 && item.additionalParams && (item.additionalParams.isTyping || item.additionalParams.isDefault)) {
                data.push({
                    name: item.Name,
                    value: false,
                });
            }
            if (index > -1 && !item.additionalParams?.isTyping && !item.additionalParams?.isDefault) {
                data.splice(index, 1);
            }
        });
        setRequestParams(data);
    };

    const saveFilterRequest = async (id: false | number, name: string) => {
        try {
            const result = await FilterService.save(new CustomFilterDto({
                id: id || undefined,
                title: name,
                filterId: props.id,
                customFilterItems: requestParams.map(x => new CustomFilterItemDto(x.name, x.value,{
                    title: x.title
                }))
            }));
            await loadFilters();
            setCurrentFilterId(String(result));
        } catch (err) {
            console.error('err', err);
        }
    };

    const clickSave = (editFilterName: string) => {
        if (currentFilterId === 'new') {
            saveFilterRequest(false, editFilterName);
        } else {
            const id = parseInt(currentFilterId) || 0;
            if (id > 0) {
                saveFilterRequest(id, editFilterName);
            }
        }
    };

    const getTypingFilterItem = () => {
        const typingIndex = props.items.findIndex(x => x.additionalParams?.isTyping === 'Y');
        if (typingIndex > -1) {
            return props.items[typingIndex];
        }
    };

    const clickSaveFieldsVisibility = (values: string[]) => {
        const typing = getTypingFilterItem();
        const data = requestParams.filter(x => values.includes(x.name) || (typing && typing.Name === x.name));
        values.forEach((item) => {
            const index = data.findIndex(x => x.name === item);
            if (index < 0) {
                data.push({
                    value: false,
                    name: item,
                });
            }
        });
        setRequestParams(data);
    };

    const saveInLocalStorage = (key: string, value: string) => {
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            console.warn(e);
        }
    };

    const getFromLocalStorage = (key: string) => {
        try {
            return localStorage.getItem(key);
        } catch (e) {
            console.warn(e);
        }
        return false;
    };

    const clickRemovePresetFilter = () => {
        const data = [...requestParams];
        data.forEach((item) => {
            item.value = false;
        });

        setCurrentFilterId('');
        setRequestParams(data);
    };

    const clickRemovePresetParam = (params: any) => {
        const data = [...requestParams];
        const index = data.findIndex(x => x.name === params.name);
        if (index > -1) {
            data[index].value = false;
            setRequestParams(data);
        }
    };

    const clickRemovePresetMore = (e: React.SyntheticEvent, arrMore: any[]) => {
        console.log('clickRemovePresetMore', arrMore);
        /*
        btnRemove.click(function (e) {
                arrMore.forEach(function (item) {
                    item.value = false;
                });
                if (e.originalEvent !== undefined) {
                    filter.displayRequestParams();
                    filter.find();
                }
            });
        */
    };

    const clickRemoveField = (name: string) => {
        setRequestParams(requestParams.filter(x => x.name !== name));
    };

    const changeValue = (value: any, filterItem: FilterItem) => {
        const data = [...requestParams];
        const index = data.findIndex(x => x.name === filterItem.Name);
        if (index > -1) {
            data[index].title = filterItem.Title;
            data[index].value = value;
            setRequestParams(data);
        }
    };

    const changeTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const typing = getTypingFilterItem();
        if (!typing)
            return;

        const data = [...requestParams];
        const index = requestParams.findIndex(x => x.name === typing.Name);
        if (index < 0 && e.target.value.length > 0) {
            data.push({
                value: [{ value: e.target.value }],
                name: typing.Name,
            });
        } else {
            if (e.target.value.length > 0) {
                data[index] = {
                    value: [{ value: e.target.value }],
                    name: typing.Name,
                };
            } else {
                data.splice(index, 1);
            }
        }

        setShowModal(false);
        setRequestParams(data);
    };

    const clickSelectFilter = (id: string) => {
        setCurrentFilterId(id);
        setRequestParams(loadedFilters[id].data.map((x: any) => ({
            name: x.columnName,
            value: Utils.tryParseJson<any>(x.value),
            title: Utils.tryParseJson<any>(x.metaInfo)?.title
        })));
    };

    const clickRemoveFilter = async (e: React.SyntheticEvent, id: string) => {
        if (e) e.preventDefault();
        await removeFilterRequest([id]);
        await loadFilters();
    };

    const removeFilterRequest = async (ids: string[]) => {
        try {
            await FilterService.delete(ids);
            if (ids.includes(currentFilterId)) {
                setCurrentFilterId('');
            }
        } catch (err) {
            console.error('remove filter', err);
        }
    };

    const fillRequestFilter = () => {
        const requestFilter: { [key: string]: any } = {};
        props.items.forEach(function(item) {
            let upperName = item.Name;
            switch (item.Type) {
                case FilterType.Hidden:
                    const hiddenDataValues = getValueByName(item.Name);
                    if (hiddenDataValues && hiddenDataValues.length > 0) {
                        addValueToRequestFilter(requestFilter, upperName, hiddenDataValues[0].value);
                        // if (item.PREFIX === 'Y') {
                        // }
                        // else {
                        //     addValueToRequestFilter(requestFilter, upperName, [data.value]);
                        // }
                        // let arValues = Utils.getStringArray(hiddenValues);
                        // if (arValues && arValues.length > 0) {
                        //     if (item.PREFIX === 'Y') {
                        //         addValueToRequestFilter(requestFilter, upperName, arValues[0]);
                        //     }
                        //     else {
                        //         addValueToRequestFilter(requestFilter, upperName, arValues);
                        //     }
                        // }
                    }
                    break;
                case FilterType.Text:
                    const textValues = getValueByName(item.Name);
                    if (textValues && textValues.length > 0) {
                        addValueToRequestFilter(requestFilter, upperName, textValues[0].value);
                    }
                    break;
                case FilterType.NumberRange:
                    const numberRangeValue = getValueByName(item.Name);
                    if (numberRangeValue && numberRangeValue.length > 0) {
                        const value = numberRangeValue[0];
                        if (value.from) {
                            addValueToRequestFilter(requestFilter, `>=${upperName}`, value.from);
                        }
                        if (value.to) {
                            addValueToRequestFilter(requestFilter, `<=${upperName}`, value.to);
                        }
                        if (value.value) {
                            switch (value.type) {
                                case FilterRangeType.GreaterThan:
                                    addValueToRequestFilter(requestFilter, `>${upperName}`, value.value);
                                    break;
                                case FilterRangeType.LessThan:
                                    addValueToRequestFilter(requestFilter, `<${upperName}`, value.value);
                                    break;
                                default:
                                    addValueToRequestFilter(requestFilter, `${upperName}`, value.value);
                                    break;
                            }
                        }
                    }
                    break;
                case FilterType.DateRange:
                    const dateRangeValue = getValueByName(item.Name);
                    if (dateRangeValue && dateRangeValue.length > 0) {
                        const value = dateRangeValue[0];
                        switch (value.type) {
                            case FilterRangeType.Range:
                                if (value.from) {
                                    addValueToRequestFilter(requestFilter, `>=${upperName}`, new Date(value.from).toISOString());
                                }
                                if (value.to) {
                                    addValueToRequestFilter(requestFilter, `<=${upperName}`, new Date(value.to).toISOString());
                                }
                                break;
                            case FilterRangeType.Period:
                                switch (value.value) {
                                    case FilterRangeType.Today:
                                        addValueToRequestFilter(requestFilter, `>=${upperName}`, Moment().format(Moment.HTML5_FMT.DATE));
                                        addValueToRequestFilter(requestFilter, `<=${upperName}`, Moment().add(1, 'day').format(Moment.HTML5_FMT.DATE));
                                        break;
                                    case FilterRangeType.Yesterday:
                                        addValueToRequestFilter(requestFilter, `>=${upperName}`, Moment().subtract(1, 'day').format(Moment.HTML5_FMT.DATE));
                                        addValueToRequestFilter(requestFilter, `<=${upperName}`, Moment().format(Moment.HTML5_FMT.DATE));
                                        break;
                                    case FilterRangeType.Week:
                                        addValueToRequestFilter(requestFilter, `>=${upperName}`, Moment().subtract(7, 'day').format(Moment.HTML5_FMT.DATE));
                                        addValueToRequestFilter(requestFilter, `<=${upperName}`, Moment().add(1, 'day').format(Moment.HTML5_FMT.DATE));
                                        break;
                                    case FilterRangeType.Month:
                                        addValueToRequestFilter(requestFilter, `>=${upperName}`, Moment().subtract(30, 'day').format(Moment.HTML5_FMT.DATE));
                                        addValueToRequestFilter(requestFilter, `<=${upperName}`, Moment().add(1, 'day').format(Moment.HTML5_FMT.DATE));
                                        break;
                                }
                                break;
                            case FilterRangeType.GreaterThan:
                                if (value.value) {
                                    addValueToRequestFilter(requestFilter, `>=${upperName}`, new Date(value.value).toISOString());
                                }
                                break;
                            case FilterRangeType.LessThan:
                                if (value.value) {
                                    addValueToRequestFilter(requestFilter, `<=${upperName}`, new Date(value.value).toISOString());
                                }
                                break;
                            default:
                                if (value.value) {
                                    addValueToRequestFilter(requestFilter, `${upperName}`, new Date(value.value).toISOString());
                                }
                                break;
                        }
                    }
                    break;
                case FilterType.Select:
                case FilterType.AsyncSelect:
                    const selectValue = getValueByName(item.Name);
                    if (selectValue && selectValue.length > 0) {
                        addValueToRequestFilter(requestFilter, `${upperName}`, selectValue.map(x => x.value));
                    }
                    break;
            }
        });
        return requestFilter;
    };

    const getValueByName = (name: string) => {
        var index = requestParams.findIndex(x => x.name === name);
        if (index < 0)
            return false;
        return requestParams[index].value;
    };

    const addValueToRequestFilter = (requestFilter: { [key: string]: any }, name: string, value: any) => {
        if (value || value === 0) {
            requestFilter[name] = value;
        }
    };

    const onSortFields = (data: { oldIndex: number, newIndex: number }) => {
        if (data.oldIndex === data.newIndex)
            return;

        setRequestParams(arrayMoveImmutable(requestParams, data.oldIndex, data.newIndex));
    };

    const onSortFilters = async (data: { oldIndex: number, newIndex: number }) => {
        if (data.oldIndex === data.newIndex)
            return;

        setFilterSorting(arrayMoveImmutable(filterSorting, data.oldIndex, data.newIndex));
        await saveSortingRequest();
    };

    const compareFilterFields = () => {
        if (!currentFilterId || !loadedFilters[currentFilterId])
            return false;
        const filterFields = loadedFilters[currentFilterId].data;

        if (filterFields.length !== requestParams.length) {
            return false
        }

        var result = true;
        requestParams.forEach(function(field) {
            var index = filterFields.findIndex((x) => x.columnName === field.name);
            if (index < 0 && field.value) {
                result = false;
                return false;
            }
            if (filterFields[index] && JSON.stringify(field.value) !== filterFields[index].value) {
                result = false;
                return false;
            }
        });
        return result;
    };

    var currentFilter = currentFilterId && loadedFilters[currentFilterId] && compareFilterFields() ? loadedFilters[currentFilterId] : false;
    var hasValue = false;
    var hasPreset = false;
    var buffer: RequestData[] = [];
    var inputTextValue = '';
    requestParams.slice().reverse().forEach(function(params) {
        if (!params.value && params.value)
            return;
        if (!params.title && params.value !== false && params.value.length > 0) {
            hasValue = true;
            inputTextValue = params.value[0].value;
            return;
        }
        if (params.value && params.value.length < 1) {
            return;
        }
        if (params.value && params.value.length > 0 && (!params.value[0].label || params.value[0].label.length < 1)) {// && params.value.description && Array.isArray(params.value.description) && params.value.description.length < 1
            return;
        }
        buffer.push(params);
    });
    const arrPresetItems = [];
    if (currentFilter) {
        arrPresetItems.push((
            <div className='filter-preset-item' key={currentFilter.id} title={currentFilter.name}>
                <span className='filter-preset-item-text'>{currentFilter.name}</span>
                <span className='preset-remove' onClick={clickRemovePresetFilter}>
                    <i className='fas fa-times'/>
                </span>
            </div>
        ));
        hasPreset = true;
    } else {
        var arrMore: any[] = [];
        var arrPreset = buffer.filter(x => {
            return !(x.value === false || (Array.isArray(x.value) && x.value.length < 1));

        });
        arrPreset.forEach(function(params) {
            if (arrPresetItems.length > 2) {
                arrMore.push(params);
                return;
            }
            arrPresetItems.push((
                <div className='filter-preset-item' key={params.name}>
                    {Array.isArray(params.value) &&
                        <span className='filter-preset-item-text' title={params.value.map(x => x.label).join(', ')}>
                            <span>{t(params.title ?? '')}</span>: <span>{params.value.map(x => x.label).join(', ')}</span>
                        </span>
                    }
                    <span className='preset-remove' onClick={() => clickRemovePresetParam(params)}>
                        <i className='fas fa-times'/>
                    </span>
                </div>
            ));
            hasPreset = true;
        });
        if (arrMore.length > 0) {
            arrPresetItems.push((
                <div className='filter-preset-item' key='more'>
                    <span className='filter-preset-item-text'>{t('filter:preset-more', { val: arrMore.length })}</span>
                    <span className='preset-remove' onClick={(e) => clickRemovePresetMore(e, arrMore)}>
                        <i className='fas fa-times'/>
                    </span>
                </div>
            ));
        }
    }

    return (
        <div className='filter-search'>
            {arrPresetItems.map((item) => (
                item
            ))}
            <input type='text' name='find' value={inputTextValue} className='filter-search-text' autoComplete='off'
                   onChange={changeTextInput} onClick={toggleModal}
                   placeholder={t('filter:filter-placeholder')}
            />
            <div className='main-ui-item-icon-block'>
                <span className='main-ui-item-icon' onClick={clickFind}>
                    <i className='fas fa-search'/>
                </span>
                {(hasValue || hasPreset) &&
                    <span className='main-ui-item-icon' onClick={clickClear}>
                        <i className='fas fa-times'/>
                    </span>
                }
            </div>
            {showModal &&
                <FilterModalContainer>
                    <FilterModal
                        items={props.items}
                        requestParams={requestParams}
                        currentFilterId={currentFilterId}
                        setCurrentFilterId={setCurrentFilterId}
                        filterSorting={filterSorting}
                        loadedFilters={loadedFilters}
                        clickFind={clickFind}
                        loadFilterDefaultFields={loadFilterDefaultFields}
                        clickReset={clickReset}
                        clickSave={clickSave}
                        clickRemoveUserFilters={clickRemoveUserFilters}
                        onSortFilters={onSortFilters}
                        //editFilter={editFilter}
                        currentFilter={currentFilter}
                        clickSelectFilter={clickSelectFilter}
                        //clickEditFilterName={clickEditFilterName}
                        clickRemoveFilter={clickRemoveFilter}
                        getFilterItemByName={getFilterItemByName}
                        onSortFields={onSortFields}
                        changeValue={changeValue}
                        clickRemoveField={clickRemoveField}
                        clickSaveFieldsVisibility={clickSaveFieldsVisibility}
                    />
                </FilterModalContainer>
            }
        </div>
    );
};

export default Filter;
