import { EenApi } from '..';
import { Notification } from './notification';
import { UserSubscription } from './userSubscription';
import { clearByCondition } from "../../utils/string.utils";
import { SelectItemDto } from "../common/select.item.dto";

class CNotificationService {
    list(requestData: any) {
        return EenApi.list<Notification>('notification/list', requestData);
    }

    get(id: number) {
        return EenApi.get<Notification>(`notification/get/${id}`);
    }

    types() {
        return EenApi.get<SelectItemDto[]>('notification/types');
    }

    subscriptions(requestData: any) {
        return EenApi.list<UserSubscription>('notification/subscriptions', requestData);
    }

    subscribe(requestData: { userId: number; notificationId: number; channel: string; state: boolean }) {
        return EenApi.put<boolean>('notification/subscribe', requestData);
    }

    addOrUpdate(entity: Notification) {
        const data = {
            ...entity,
            roles: entity.roleIds
        }
        clearByCondition(data, 'emailSubject', !data.isEmail);
        clearByCondition(data, 'emailText', !data.isEmail);
        clearByCondition(data, 'feedText', !data.isFeed);
        clearByCondition(data, 'pushText', !data.isPush);
        clearByCondition(data, 'pushLink', !data.isPush);
        if (data.isAllUsersReceive) {
            data.roles = [];
        }
        if (!entity.id) {
            return EenApi.post<number>('notification/add', data);
        }
        return EenApi.put<number>('notification/edit', data);
    }

    delete(ids: number[]) {
        return EenApi.delete('notification/delete', { ids: ids });
    }

    getEditLink(id: number) {
        return `/notification/${id}/edit`;
    }
}

export const NotificationService = new CNotificationService();
