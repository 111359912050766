import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../context/user-context';
import { formatDate } from '../../../utils/date.utils';
import useTranslation from '../../../hooks/translation.hook';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { useGroupDeleteAction, useIdColumn, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import { GridSelectionModel } from '@mui/x-data-grid';
import Rules from '../../../rules';
import { ChatService } from '../../../eenApi/chat/service';
import ChatListDto from '../../../eenApi/chat/dto/chat.list.dto';
import { Box, Button } from '@mui/material';
import { CommentEditor } from '../../../component/chat/CommentEditor';
import { getLinkRouteByObjectType as getLinkRoute } from '../routes';
import { useMutation, useQueryClient } from 'react-query';
import { ToastManager } from '../../../service/toastManager';

interface IProps {
    filterValues?: { [key: string]: any };
}

export function ChatTable(props: IProps) {
    const { checkAccess } = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const [editingId, setEditingId] = useState<number | null>(null);
    const queryClient = useQueryClient();

    const idColumn = useIdColumn<ChatListDto>();

    const updateCommentMutation = useMutation(
        (data: {
            id: number;
            comment: string;
            objectId: number;
            objectType: string;
        }) =>
            ChatService.updateComment({
                id: data.id,
                comment: data.comment,
                objectId: data.objectId,
                objectType: data.objectType,
            }),
        {
            onSuccess: async () => {
                await queryClient.resetQueries({
                    predicate: (query) => query.queryKey.includes('chat'),
                });
                ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            },
            onError: (error: Error) => {
                ToastManager.error({ title: 'main:msg-error', message: 'main:unhandled-exception-error-message' });
            }
        }
    );


    const deleteCommentMutation = useMutation(
        (id: number) => ChatService.remove(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(EntityQueryKey.Chat);
                ToastManager.success({ title: 'main:msg-success', message: 'main:msg-entity-deleted' });
            },
            onError: (error: Error) => {
                ToastManager.error({ title: 'main:msg-error', message: 'main:unhandled-exception-error-message' });
            }
        }
    );

    const columns = useMemo<TableColumn<ChatListDto>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('chat:fields.comment'),
                field: 'comment',
                renderCell: (params) => {
                    const isEditing = editingId === params.row.id;
                    return (
                        <div>
                            {isEditing ? (
                                <CommentEditor
                                    comment={params.row.comment!}
                                    onSave={async (newComment) => {
                                        try {
                                            await updateCommentMutation.mutateAsync({
                                                id: params.row.id!,
                                                comment: newComment,
                                                objectId: params.row.objectId!,
                                                objectType: params.row.objectType!,
                                            });
                                            setEditingId(null);
                                        } catch (error) {
                                            ToastManager.error({ title: 'main:msg-error', message: 'main:unhandled-exception-error-message' });
                                        }
                                    }}
                                    onCancel={() => setEditingId(null)}
                                />
                            ) : (
                                <Box>
                                    <div>{params.row.comment}</div>
                                    {checkAccess(Rules.Chat.Edit) && (
                                        <Button onClick={() => setEditingId(params.row.id!)}>
                                            {t('chat:button.edit')}
                                        </Button>
                                    )}
                                </Box>
                            )}
                        </div>
                    );
                },
            }),
            new TableColumn({
                headerName: t('chat:fields.created'),
                field: 'created',
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('chat:fields.objectType'),
                field: 'objectType',
                sortable: true,
                renderCell: ({ row, value }) => (
                    <Link to={getLinkRoute(value, row.objectId!.toString())}>
                        {t(`chat:type.${value}`)}
                    </Link>
                ),
            }),
            new TableColumn({
                headerName: t('chat:fields.email'),
                field: 'createdBy',
                renderCell: (params) =>
                    params.row.createdBy?.email
            }),
            new TableColumn({
                headerName: t('chat:fields.fullName'),
                field: 'fullName',
                renderCell: (params) =>
                    params.row.createdBy?.fullName
            }),
            new TableColumn({
                headerName: t('chat:fields.reply'),
                field: 'children',
                renderCell: ({ row, value }) => {
                    if (row.children && row.children.length > 0) {
                        return (
                            row.children.map((child, idx) => (
                                <Link key={idx} to={row.objectType && row.objectId ? `${getLinkRoute(row.objectType, row.objectId.toString())}#${value}` : '#'}>
                                    {value.join(', ')}
                                </Link>
                            ))
                        );
                    } else {
                        return null;
                    }
                },
            }),
        ],
        [i18n.language, editingId]
    );

    const clickDelete = async (ids: GridSelectionModel) => {
        await Promise.all(ids.map((id) => deleteCommentMutation.mutateAsync(Number(id))));
    };

    const rowActions = useRowActions<ChatListDto>({
        remove: checkAccess(Rules.Chat.Delete)
            ? (row) => clickDelete([row.id!])
            : null,
    });

    const groupActions = useGroupDeleteAction({
        remove: checkAccess(Rules.Chat.Delete) ? clickDelete : null,
    });

    return (
        <DataTable
            id={"chatcomment-component"}
            queryKey={[EntityQueryKey.Chat]}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(data) => ChatService.list(data)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
}
