import { FC, useContext, useState } from 'react';
import useTranslation from '../../../../hooks/translation.hook';
import { useHistory, useParams } from 'react-router-dom';
import BottomActions from '../../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../../component/actions/bottomActionsContainer';
import Loader from '../../../../component/loader';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import { ToastManager } from '../../../../service/toastManager';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TopActions from '../../../../component/actions/topActions';
import TopActionsContainer from '../../../../component/actions/topActionsContainer';
import MainEditTab from '../../../../component/support/subjects/MainEditTab';
import TabControls from '../../../../component/tabs/tabControls';
import ValidationContext from '../../../../hooks/UseValidation/validation.context';
import TabItem from '../../../../component/tabs/tabItem';
import SupportSubjectEditDto from '../../../../eenApi/support/subjects/dto/support.subject.edit.dto';
import { SupportRoutesPath } from '../../routes';
import SupportService from '../../../../eenApi/support/service';
import { SupportSubjectValidator } from '../../../../utils/validators';
import SupportSubjectDto from '../../../../eenApi/support/subjects/dto/support.subject.dto';
import { ActionItem } from '../../../../models/actionItem';
import { isRoutePathCreate } from '../../../../utils/check.route.path';
import { UserContext } from '../../../../context/user-context';
import Rules from '../../../../rules';
import { DeleteModal } from '../../../../component/common/ui';
import { useDeleteModal } from '../../../../hooks/UseDeleteModal';

const EditSubject: FC = () => {
    const { id } = useParams<{ id?: string }>();
    const { t } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();

    const [tabs, setTabs] = useState<string[]>([]);
    const [validateOn, setValidateOn] = useState(false);
    const { checkAccess } = useContext(UserContext);

    const createPath = SupportRoutesPath.CreateSubject;

    const { isDeleteOpen, closeDelete, remove, deleteButton } = useDeleteModal({
        queryKey: 'supportsubject',
        handler: (id) => SupportService.delete(id),
        successPath: SupportRoutesPath.List,
    });

    const actions: ActionItem[] = [
        {
            name: t('main:btn-save'),
            onClick: () => save(false),
        },
        {
            name: t('main:btn-apply'),
            onClick: () => save(true),
        },
        {
            name: t('main:btn-cancel'),
            onClick: async () => {
                await queryClient.invalidateQueries(['support/getSubject', id]);
                history.goBack();
            },
            color: '#8E9FA7',
        },
    ];

    const {
        data: entity,
        isFetching,
    } = useQuery<SupportSubjectDto>([`support/getSubject`, id], () => SupportService.getSubject(parseInt(id!)), {
        onSuccess: async (data) => {
            setData(new SupportSubjectEditDto(data));
        },
        enabled: !isRoutePathCreate(history.location.pathname, createPath),
    });

    const [data, setData] = useState<SupportSubjectEditDto>(
        isRoutePathCreate(history.location.pathname, createPath) ? new SupportSubjectEditDto() : new SupportSubjectEditDto(entity),
    );

    const [activeTab, setActiveTab] = useState('main');
    let showDetails = true;

    const updateMutation = useMutation(() => SupportService.addOrUpdateSubject(data), {
        onSuccess: async (data) => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            if (showDetails) {
                await queryClient.resetQueries({
                    predicate: (query) => query.queryKey.includes('supportsubject'),
                });
                history.replace(SupportRoutesPath.ListSubject);
            } else {
                history.replace(`${SupportRoutesPath.EditSubject.replace(':id', data.toString())}`);
            }
        },
    });

    if (!isRoutePathCreate(history.location.pathname, createPath) && checkAccess(Rules.Support.DeleteSubject)) {
        actions.push(deleteButton);
    }

    const tabItems = [new TabItem('main', t('main:tab-information'))];

    const save = (stay: boolean) => {
        setValidateOn(true);
        showDetails = !stay;
        window.scrollTo(0, 0);
        const erroredTabs = SupportSubjectValidator.validate(data!, tabs);
        setTabs(erroredTabs);
        if (erroredTabs.length == 0) {
            updateMutation.mutate();
        }
    };

    const updateData = (field: keyof SupportSubjectEditDto, value: any) => {
        let newValue = value?.value ?? value;
        setData({ ...data!, [field]: Array.isArray(newValue) ? [...newValue] : newValue });
    };

    return (
        <MainContainer>
            <PageTitle
                text={t(!isRoutePathCreate(history.location.pathname, createPath) ? 'support:subjects.edit.title' : 'support:subjects.create.title')}
                backLink={SupportRoutesPath.ListSubject}
            />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                {isFetching ? <Loader /> : <MainEditTab activeId={activeTab} id="main" data={data} validate={validateOn} updateData={updateData} />}
                <BottomActionsContainer>
                    <BottomActions actions={actions} />
                </BottomActionsContainer>
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
};

export default EditSubject;
