import { useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { InterestTable } from './table';
import { InterestStatusEnum } from '../../../../eenApi/profile/enum/interest.status.enum';
import useTranslation from '../../../../hooks/translation.hook';
import { UserContext } from '../../../../context/user-context';
import { useOptions } from '../../../../hooks/select.options.hook';
import FilterItemText from '../../../../component/filter/types/filterItem/items/text';
import FilterItemAsyncSelect from '../../../../component/filter/types/filterItem/items/asyncSelect';
import { ClientService } from '../../../../eenApi/client/service';
import FilterItemSelect from '../../../../component/filter/types/filterItem/items/select';
import MainContainer from '../../../../component/mainContainer';
import PageTitle from '../../../../component/pageTitle';
import Rules from '../../../../rules';
import { ProfileRoutesPath } from '../../routes';
import Filter from '../../../../component/filter';

interface IProps {
  list: 'my-interest'|'interest'
}

export function InterestList({ list = 'interest'}: IProps) {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const statusOptions = useOptions<InterestStatusEnum>(InterestStatusEnum, 'profile:interestStatus.');

    const filterItems = useMemo(() => [
            new FilterItemText('title', t('profile:fields.title'), { isDefault: "Y" }),
            new FilterItemText('profileId', t('profile:fields.id'), { isDefault: "Y" }),
            new FilterItemAsyncSelect('fromClientId', t('profile:fields.clientId'), { isDefault: "Y", loadOptions: ClientService.selectLoadOptions }),
            new FilterItemSelect('status', t('profile:fields.status'), { isDefault: "Y", options: statusOptions }),
        ], [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t('profile:title.interestList')} backLink='/' />
            <div className='d-xl-flex mb-1 mb-xl-3'>
                <div className='app-filter-actions mb-2 mb-xl-0'>
                    {checkAccess(Rules.Profile.InterestAdd) &&
                        <Link className='btn btn-success me-2'
                              to={ProfileRoutesPath.InterestCreate}>{t('main:btn-add')}</Link>
                    }
                </div>
                <Filter id="filter-interest"
                        items={filterItems}
                        onFind={setFilterValues}
                        query={{}}
                />
            </div>
            <InterestTable list={list} filterValues={filterValues}/>
        </MainContainer>
    );
}