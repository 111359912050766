import PageTitle from '../../../component/pageTitle';
import { ProfileRoutesPath } from '../routes';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { Suspense, useContext, useMemo } from 'react';
import MainContainer from '../../../component/mainContainer';
import { ClientRoutesPath } from '../../client/routes';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import MainEditTab from '../../../component/profile/MainEditTab';
import ProfileEditDto from '../../../eenApi/profile/dto/profile.edit.dto';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import ProfileService from '../../../eenApi/profile/service';
import TabPanel from '../../../component/tabs/tabPanel';
import Loader from '../../../component/loader';
import { ProfileEditValidator } from '../../../utils/validators';
import ProfileStatusEnum from '../../../eenApi/profile/enum/profile.status.enum';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import { ActionItem } from '../../../models/actionItem';
import { ObjectType } from '../../../enums/objectType';
import Rules from '../../../rules';
import { UserContext } from '../../../context/user-context';
import { useActions } from '../hooks/UseActions';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';
import { DeleteModal } from '../../../component/common/ui';
import LanguageEnum from '../../../enums/language.enum';

const ContactsTab = React.lazy(() => import('../../../component/profile/ContactsTab'));

export default function ProfileEdit() {
    const { t, history, data, entity, activeTab, setActiveTab, updateData, validateOn, tabs, setTabs, saveActions, isDeleteOpen, closeDelete, remove } =
        useEditPageWithDelete<ProfileEditDto>({
            dataFactory: (entity, language) => new ProfileEditDto(entity as any, language as LanguageEnum),
            queryKey: 'profile',
            handler: (data) => ProfileService.update(data),
            validator: ProfileEditValidator as any,
            applyRoute: ProfileService.getEditRoute,
            saveRoute: ProfileService.getDetailsRoute,
            cancelRoute: [ProfileRoutesPath.My, ProfileService.getDetailsRoute],
            deleteHandler: (id) => ProfileService.remove(id),
        });
    const currentUser = useCurrentUser();
    const { checkAccess } = useContext(UserContext);

    const tabItems = useMemo(() => [new TabItem('main', t('profile:tabs.main')), new TabItem('contacts', t('profile:tabs.contacts'))], [entity?.type]);

    const commonActions = useActions(entity as any);
    const actions = (() => {
        const items: ActionItem[] = [
            {
                isTop: true,
                name: t('profile:button.clientView'),
                onClick: () => history.push(ClientRoutesPath.View.replace(':id', String(entity?.client?.id))),
            },
            ...saveActions,
        ];
        items.splice(3, 0, ...commonActions);

        if (
            entity?.status === ProfileStatusEnum.Active &&
            checkAccess(Rules.Profile.InterestEdit) &&
            currentUser.data?.rules?.some((r) => r.objectType === ObjectType.Client)
        ) {
            items.splice(items.length - 2, 0, {
                name: t('main:btn-interest'),
                onClick: () => history.push(`${ProfileRoutesPath.InterestCreate}?profileId=${entity.id}`),
            });
        }

        return items;
    })();

    return (
        <MainContainer>
            <PageTitle text={t('profile:edit.title')} backLink={ProfileRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <TabPanel activeId={activeTab} id="loading">
                    <Loader />
                </TabPanel>
                {data?.id && entity?.id && (
                    <>
                        <MainEditTab activeId={activeTab} id="main" entity={entity as any} data={data} updateData={updateData} />
                        <Suspense fallback={<Loader />}>
                            <ContactsTab activeId={activeTab} id="contacts" entity={entity?.client} />
                        </Suspense>
                        <BottomActionsContainer>
                            <BottomActions actions={actions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}
