import { Suspense, useContext, useMemo, useState } from 'react';
import MainContainer from '../../../component/mainContainer';
import Rules from '../../../rules';
import PageTitle from '../../../component/pageTitle';
import { UserService } from '../../../eenApi/user/service';
import { UserRoutesPath } from '../routes';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TopActions from '../../../component/actions/topActions';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import { LoaderTab } from '../../../component/common/layout';
import { DeleteModal } from '../../../component/common/ui';
import Loader from '../../../component/loader';
import TabControls from '../../../component/tabs/tabControls';
import TabItem from '../../../component/tabs/tabItem';
import LanguageEnum from '../../../enums/language.enum';
import { useEditPageWithDelete } from '../../../hooks/UseEditPage';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import UserEditDto from '../../../eenApi/user/dto/user.edit.dto';
import { UserEditValidator } from '../../../utils/validators/user/edit.validator';
import UserRoles from '../component/userRoles';
import { UserContext } from '../../../context/user-context';
import NotificationSubscriptions from '../component/notificationSubscriptions';
import MainEditTab from '../../../component/user/MainEditTab';
import UserCompWrapper from '../../../component/user/UserFCWrapper';
import { ActionItem } from '../../../models/actionItem';
import { isRoutePathCreate } from '../../../utils/check.route.path';
import { useMutation } from 'react-query';
import { ToastManager } from '../../../service/toastManager';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

export default function UserEdit() {
    const { checkAccess } = useContext(UserContext);
    const { data: currentUser } = useCurrentUser();

    const {
        t,
        id,
        i18n,
        isFetching,
        data,
        entity,
        activeTab,
        setActiveTab,
        updateData,
        queryClient,
        tabs,
        setTabs,
        saveActions,
        isDeleteOpen,
        closeDelete,
        history,
        remove,
    } = useEditPageWithDelete<UserEditDto>({
        dataFactory: (entity, language) => new UserEditDto(entity, language as LanguageEnum),
        queryKey: 'user',
        handler: (data) => UserService.save(data),
        validator: UserEditValidator,
        applyRoute: UserService.getEditLink,
        saveRoute: UserService.getDetailLink,
        cancelRoute: [UserRoutesPath.View, UserService.getDetailLink],
        canDelete: Rules.User.Delete,
        deleteHandler: (id) => UserService.delete([Number(id)]),
    });
    const [validateOn, setValidateOn] = useState(false);

    const tabItems = useMemo(() => {
        const items: TabItem[] = [];
        items.push(new TabItem('main', t('user:tabs.main')));
        if (checkAccess(Rules.User.ListRoles)) {
            items.push(new TabItem('roles', t('user:tabs.roles')));
        }
        if (entity?.id === currentUser!.user?.id || checkAccess(Rules.User.ViewNotifications)) {
            items.push(new TabItem('notification', t('user:tabs.notification')));
        }
        return items;
    }, [i18n.language, entity]);

    const permissions = useMemo(() => {
        return {
            active: checkAccess(Rules.User.ChangeActive),
            isEmailVerified: checkAccess(Rules.User.ChangeEmailVerified)
        };
    }, [checkAccess]);

    function cleanDto(dto: any, permissions: { [key: string]: boolean }) {
        const cleanedDto = { ...dto };

        Object.keys(permissions).forEach(key => {
            if (!permissions[key] && cleanedDto.hasOwnProperty(key)) {
                delete cleanedDto[key];
            }
        });
        return cleanedDto;
    }

    const updateMutation = useMutation(({ data, stay }: { data: UserEditDto, stay: boolean }) => {
        return UserService.save(data).then(() => stay);
    }, {
        onSuccess: async (stay) => {
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-changes-saved' });
            await queryClient.removeQueries(['user', id]);
            await queryClient.resetQueries({
                predicate: (query) => query.queryKey.includes('user'),
            });
            history.push(stay ? UserRoutesPath.View.replace(':id', id!) : UserRoutesPath.Edit.replace(':id', id!));

        },
        onSettled: (stay, error, tab) => {
            if (error) {
                setActiveTab(activeTab);
            }
        },
    });


    const save = async (stay: boolean) => {
        setValidateOn(true);
        window.scrollTo(0, 0);
        const erroredTabs = UserEditValidator.validate(data!, tabs, entity!);
        setTabs(erroredTabs);

        if (erroredTabs.length === 0) {
            const cleanedDto = cleanDto(data, permissions);
            updateMutation.mutate({ data: cleanedDto, stay });
            setActiveTab('loading');
        }
    };


    const createPath = UserRoutesPath.View;

    const actions = (() => {
        const items: ActionItem[] = [
            {
                name: t('main:btn-save'),
                onClick: () => save(true),

            },
            {
                name: t('main:btn-apply'),
                onClick: () => save(false),
            },
            {
                name: t('main:btn-cancel'),
                onClick: async () => {
                    if (isRoutePathCreate(history.location.pathname, createPath)) {
                        await queryClient.invalidateQueries(['user']);
                        history.goBack();
                    } else {
                        await queryClient.invalidateQueries(['user', id]);
                        history.push(UserRoutesPath.View.replace(':id', id!));
                    }
                },
                color: '#8E9FA7',
            },
        ];
        return items;
    })();

    return (
        <MainContainer>
            <PageTitle text={entity ? entity.fullName : ''} backLink={UserRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={saveActions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <LoaderTab activeId={activeTab} />
                {!isFetching && (
                    <>
                        <MainEditTab activeId={activeTab} id="main" data={data} updateData={updateData} />
                        {entity && (checkAccess(Rules.User.ListRoles)) && (
                            <UserCompWrapper activeId={activeTab} id="roles">
                                <Suspense fallback={<Loader />}>
                                    <div>
                                        <UserRoles userId={entity.id} />
                                    </div>
                                </Suspense>
                            </UserCompWrapper>
                        )}
                        {entity && (entity.id === currentUser!.user?.id || checkAccess(Rules.User.ViewNotifications)) && (
                            <UserCompWrapper activeId={activeTab} id="notification">
                                <div>
                                    <NotificationSubscriptions userId={entity.id} />
                                </div>
                            </UserCompWrapper>
                        )}
                        {activeTab === "main" && (
                            <BottomActionsContainer>
                                <BottomActions actions={actions} />
                            </BottomActionsContainer>
                        )}
                    </>
                )}
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
}
