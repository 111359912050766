import ModelValidator from '../model.validator';
import NewsEditDto from '../../../eenApi/news/dto/news.edit.dto';
import NewsDto from '../../../eenApi/news/dto/news.dto';

class EditValidator extends ModelValidator<NewsEditDto, NewsDto> {
    validate(dto: NewsEditDto, tabs: string[]) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.title, erroredTabs);
        this.checkLength(dto.title, erroredTabs, 250);
        this.checkRequired(dto.status, erroredTabs);

        this.checkLength(dto.announcementDescription, erroredTabs, 500);
        this.checkLength(dto.detailDescription, erroredTabs, 5000);

        return erroredTabs;
    }
}

export default new EditValidator();
