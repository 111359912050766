import { Box, Typography } from "@mui/material";
import { FC, useState } from "react";
import { useQuery } from "react-query";
import CommentForm from "../CommentFormComp";
import { useTranslation } from "react-i18next";
import ChatEditDto from "../../../eenApi/chat/dto/chat.edit.dto";
import CommentTextComp from "../CommentTextComp";
import { ChatService } from "../../../eenApi/chat/service";
import DriveFileDto from "../../../eenApi/drive/dto/drive.file.dto";

interface CommentSectionProps {
    objectId: number;
    objectType: string;
}

const CommentSection: FC<CommentSectionProps> = ({ objectId, objectType }) => {
    const { t, i18n } = useTranslation();

    const { data: comments, isLoading } = useQuery<ChatEditDto[]>(
        ["chat", objectType, objectId],
        async () => {
            const requestData = {
                objectId: objectId,
                objectType: objectType,
                language: i18n.language,
            };
            const data = await ChatService.fetchCommentList(requestData)

            return data;
        }
    );

    const [commentsData, setCommentsData] = useState<ChatEditDto[]>(comments || []);

    const handleDeleteFile = (updatedFiles: DriveFileDto[] | undefined, commentId: number) => {
        if (updatedFiles) {
            const updatedCommentsData = commentsData.map((comment) => {
                if (comment.id === commentId) {
                    return { ...comment, files: updatedFiles };
                }
                return comment;
            });

            setCommentsData(updatedCommentsData);
        }
    };

    return (
        <Box className="chat-commentSection">
            {isLoading ? (
                <Typography>Loading comments...</Typography>
            ) : (
                <>
                    {comments &&
                        comments.map((comment: ChatEditDto) => (
                            <CommentTextComp
                                key={comment.id}
                                comment={comment}
                                objectId={objectId}
                                objectType={objectType}
                                parentId={comment.parentId}
                                onDeleteFile={handleDeleteFile}
                            />
                        ))}
                    <CommentForm objectId={objectId} objectType={objectType} />
                </>
            )}
        </Box>
    );
};

export default CommentSection;
