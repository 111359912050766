import LanguageEnum from '../../../enums/language.enum';
import { DictionaryEntity } from '../../../models/dictionaryEntity';
import { SelectItemDto } from '../../common/select.item.dto';
import DriveFileDto from '../../drive/dto/drive.file.dto';
import { User } from '../user';

export default class UserEditDto {
    id: number = 0;
    active: boolean = true;
    userId: string = '';
    fullName: string = '';
    email: string = '';
    phone: string = '';
    roles: SelectItemDto[] = [];
    linkedIn: string = '';
    telegram: string = '';
    dateOfBirth: string = '';
    gender: boolean = true;
    memberCountryId: string | null = null;
    memberCountry?: DictionaryEntity;
    photoId?: string = '';
    photo?: DriveFileDto;
    isEmailVerified: boolean = true;
    language: LanguageEnum;
    createdBy?: User;

    constructor(entity: UserEditDto | undefined, language: LanguageEnum) {
        Object.entries(entity ?? {}).forEach(([key, val]) => {
            if (this.hasOwnProperty(key)) {
                this[key as keyof UserEditDto] = val as never;
            }
        });
        this.language = entity?.language ?? language;
        this.memberCountryId = entity?.memberCountryId ?? entity?.memberCountry?.id ?? '';
        this.photoId = entity?.photo?.id;
    }
}
