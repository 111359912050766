import { Link as RouterLink } from "react-router-dom";
import { HtmlString, LongString } from "../../../../types/string.types";
import { PropsWithChildren } from "react";
import Field from "../Field";
import { Link as ExternalLink } from "@mui/material";
import { ObjectType } from '../../../../enums/objectType';
import { ValidationComment } from "../../ui";

interface IProps {
   labelKey: string,
   value?: string | LongString | HtmlString,
   route?: string,
   href?: string,
   html?: boolean,
   optional?: boolean,
   hasAccess?: boolean,
   editable?: boolean,
   isValidate?: boolean;
}

interface IPropsView extends IProps {
   isValidate?: false;
}

interface IPropsValidation extends IProps {
   isValidate: true;
   objectId: number;
   objectType: ObjectType;
   fieldName: string;
   editable: boolean;
}

export default function LinkField(props: PropsWithChildren<IPropsValidation | IPropsView>) {
   if (props.optional && !props.value && !props.children) {
      return <></>
   }

   const content = <>
      {props.html
         ? <span dangerouslySetInnerHTML={{ __html: props.value ?? '' }} />
         : props.value ?? ''}
      {props.children}
   </>

   let href = props.href ?? String(props.children);
   if (!href.startsWith('http')) {
      href = `https://${href}`
   }

   return (props.optional && !props.value && !props.children ? <></> :
      <>
         <Field labelKey={props.labelKey}>
            {props.route
               ? (props.hasAccess ?? true) ? <RouterLink to={props.route}>{content}</RouterLink> : <>{content}</>
               : <ExternalLink href={href} target="_blank">{content}</ExternalLink>
            }
         </Field >
         {props.isValidate && (
            <ValidationComment objectId={props.objectId} objectType={props.objectType} fieldName={props.fieldName!} editable={props.editable} />
         )}
      </>
   )
}