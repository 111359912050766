export default {
    List: 'event.list', // Просмотр раздела
    Add: 'event.add', //Добавление
    Edit: 'event.edit', // Создание/редактирование
    EditActive: 'event.editactive', //  редактирование своего ивента
    Delete: 'event.delete', // Удаление
    View: 'event.view',
    Validate: 'event.validate',
    ListValidate: 'event.listvalidate',
    Publish: 'event.publish',
    Remark: 'event.remark',
    EditReview: 'event.editreview',
    ViewReview: 'event.viewreview',
};
