import { CustomFilterItemDto } from "./custom.filter.item.dto";

export class CustomFilterDto {
  id?: number;
  title = '';
  filterId = '';
  customFilterItems: CustomFilterItemDto[] = [];

  constructor(entity?: CustomFilterDto) {
    Object.assign(this, entity ?? {})
  }
}