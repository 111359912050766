import TabPanel from '../../tabs/tabPanel';
import { AutocompleteControl, InputControl, CheckboxControl } from '../../common/ui';
import IEditPanelProps from '../edit.panel.props';
import { RoleService } from '../../../eenApi/role/service';
import useTranslation from '../../../hooks/translation.hook';
import { DictionaryType } from '../../../models/dictionaryType';
import { Length } from '../../../hooks/UseValidation/validators';

export default function RecepientEditTab({ data, entity, updateData, ...rest }: IEditPanelProps) {
    const { t } = useTranslation();

    return (
        <TabPanel {...rest}>
            {
                <AutocompleteControl labelKey={'notification:field-roles'}
                    value={data.roleIds}
                    multiple
                    dictionary={DictionaryType.Role}
                    options={RoleService.selectLoadOptions}
                    onChange={val => {
                        updateData('roleIds', val?.map(s => s.value) ?? [],)
                        updateData('roles', val?.map(s => s.value) ?? [])
                    }} />
            }
            <CheckboxControl
                value={data.isAllClients}
                labelKey={'mailing:mailings.fields.isAllClients'}
                onChange={(data) => updateData('isAllClients', data)}
                tab={'recepient'}
            />

            {/* Временно скрыты
            <CheckboxControl
                value={data.isAllEenClients}
                labelKey={'mailing:mailings.fields.isAllEenClients'}
                onChange={(data) => updateData('isAllEenClients', data)}
                tab={'recepient'}
            />
            <CheckboxControl
                value={data.isEventInterestedClients}
                labelKey={'mailing:mailings.fields.isEventInterestedClients'}
                onChange={(data) => updateData('isEventInterestedClients', data)}
                tab={'recepient'}
            /> */}
            <InputControl
                labelKey={'mailing:mailings.fields.additionalEmails'}
                onChange={(data) => updateData('additionalEmails', data)}
                value={data.additionalEmails}
                tab={'recepient'}
                multiline
                validators={[Length(400)]}
            />
        </TabPanel >
    );
}
