import { EenApi } from "..";
import { CustomFilterDto } from "./dto/custom.filter.dto";

class CFilterService {
    list(id: string): Promise<CustomFilterDto[]> {
        return EenApi.post<any>(`customfilter/list`, {
            filterId: id
        });
    }

    async delete(ids: string[]) {
        await EenApi.delete('customfilter/delete', { ids });
    }

    save(data: CustomFilterDto): Promise<number> {
        return !data.id
          ? EenApi.post('customfilter/add', data)
          : EenApi.put('customfilter/edit', data)
    }
}

export const FilterService = new CFilterService();
