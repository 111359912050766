import React, { FC, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../../eenApi/user/user';
import { UserService } from '../../../eenApi/user/service';
import useTranslation from '../../../hooks/translation.hook';
import { TableColumn } from '../../../component/common/layout/DataTable/table.model';
import { useIdColumn, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';

interface IProps {
    filterValues?: { [key: string]: any };
}

const UserTable: FC<IProps> = (props) => {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);

    const idColumn = useIdColumn<User>();
    const columns = useMemo<TableColumn<User>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('main:field-email'),
                field: 'email',
                renderCell: ({ row }) => <Link to={UserService.getDetailLink(row.id)}>{UserService.getUserName(row)}</Link>,
            }),
            new TableColumn({
                headerName: t('user:field-roles'),
                field: 'roles',
                valueFormatter: ({ value }) => value.join(', '),
            }),
        ],
        [i18n.language],
    );

    const rowActions = useRowActions<User>({
        edit: checkAccess(Rules.User.Edit) ? (row) => UserService.getEditLink(row.id) : null
    });

    return (
        <DataTable
            id="user-table-component"
            queryKey={EntityQueryKey.User}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(requestData) => UserService.list(requestData)}
            rowActions={rowActions}
        />
    );
};

export default UserTable;
