import TabPanel from '../../tabs/tabPanel';
import React, { useState } from 'react';
import { InputControl, CheckboxControl, TagControl } from '../../common/ui';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';
import IEditPanelProps from '../edit.panel.props';
import InputContainer from '../../inputContainer';
import ViewField from '../../fields/viewField';
import FileList from '../../fields/fileList';
import FileUploader from '../../fileUploader';
import { DictionaryType } from '../../../models/dictionaryType';
import { DictionaryControl } from '../../common/ui';
import useTranslation from '../../../hooks/translation.hook';
import LanguageEnum from '../../../enums/language.enum';


export default function MainEditTab({ entity, data, updateData, ...rest }: IEditPanelProps) {

    const { i18n } = useTranslation();
    const [file, setFile] = useState(entity?.agreement ? [entity.agreement] : []);
    const onUploaded = (file: { id: string; name: string }) => {
      setFile([file]);
      updateData('agreementId', file.id)
    };
    const onRemoveFile = () => {
      setFile([]);
      updateData('agreementId', null)
    };

    return (
        <TabPanel {...rest}>
            <InputControl labelKey={'client:field.name'}
                          onChange={value => updateData('name', value)}
                          value={data.name}
                          required
                          validators={[NotEmpty, Length(300)]} tab='main'/>
            <InputControl labelKey={'client:field.short-name'}
                          onChange={value => updateData('shortName', value)}
                          value={data.shortName}
                          required
                          validators={[NotEmpty, Length(300)]} tab='main'/>
            <DictionaryControl value={data.type ?? null}
                               onChange={value => updateData('type', value)}
                               dictionary={DictionaryType.ClientType}
                               language={i18n.language as LanguageEnum}
                               labelKey="client:field.type"
                               required validators={[NotEmpty]} minInput={0}/>
            <InputControl labelKey={'client:field.foundation-year'}
                          onChange={value => (Number(value) || !value) ? updateData('foundationYear', value) : null}
                          value={data.foundationYear}
                          type={!data.foundationYear ? 'text' : 'number'}
                          tab='main'/>
            <DictionaryControl value={data.annualTurnover ?? null}
                               onChange={value => updateData('annualTurnover', value)}
                               dictionary={DictionaryType.AnnualTurnover}
                               language={i18n.language as LanguageEnum}
                               labelKey="client:field.annual-turnover"
                               required validators={[NotEmpty]} minInput={0}/>
            <DictionaryControl value={data.certificationStandarts ?? []} multiple
                               onChange={value => updateData('certificationStandarts', value)}
                               dictionary={DictionaryType.CertificationStandart}
                               language={i18n.language as LanguageEnum}
                               labelKey="client:field.certificaion-standart"
                               minInput={0}/>
            <DictionaryControl value={data.languageProficiency ?? []} multiple
                               onChange={value => updateData('languageProficiency', value)}
                               dictionary={DictionaryType.Language}
                               language={i18n.language as LanguageEnum}
                               labelKey="client:field.language-proficiency"
                               required validators={[NotEmpty]} minInput={0}/>
            <CheckboxControl value={data.hasInternationalExperience}
                             labelKey="client:field.has-international-experience"
                             onChange={value => updateData('hasInternationalExperience', value)}/>
            <InputControl labelKey={'client:field.competency-assessment'}
                          onChange={value => updateData('competencyAssessment', value)}
                          value={data.competencyAssessment}
                          validators={[Length(1000)]} tab='main'/>
            <InputControl labelKey={'client:field.inn'}
                          onChange={value => updateData('inn', value)}
                          value={data.inn}
                          required
                          validators={[NotEmpty, Length(100)]} tab='main'/>
            <TagControl labelKey={'client:field.keyWords'} multiple
                                 onChange={value => updateData('keyWords', value)}
                                 value={data.keyWords} />
            <InputContainer>
                <ViewField title='client:field.agreement'>
                    <FileList files={file} onRemove={onRemoveFile} />
                </ViewField>
                {file.length < 1 && <FileUploader onFileUploaded={onUploaded} />}
            </InputContainer>
        </TabPanel>
    );
}