import { EenApi } from '../../eenApi';
import { HubConnection } from '@microsoft/signalr';
import { setupSignalRConnection } from "../../service/socket";

let connection: HubConnection;

interface IProps {
    method: string,
    callback: (...args: any) => void
}
export function useHub({ method, callback }: IProps) {
    if (!connection) {
        connection = setupSignalRConnection('/hub', EenApi.getAccessToken);
    }

    connection.off(method);
    connection.on(method, (...data) => {
        callback(...data);
    });
}
