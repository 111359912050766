import UserEditDto from '../../../eenApi/user/dto/user.edit.dto';
import ModelValidator from '../model.validator';

class EditValidator extends ModelValidator<UserEditDto, UserEditDto> {
    validate(dto: UserEditDto, tabs: string[], entity: UserEditDto) {
        const erroredTabs: string[] = [];

        this.checkRequired(dto.email, erroredTabs, 'main');
        this.checkRequired(dto.fullName, erroredTabs, 'main');
        this.checkRequired(dto.phone, erroredTabs, 'main');
        this.checkRequired(dto.gender, erroredTabs, 'main');
        this.checkRequired(dto.memberCountryId, erroredTabs, 'main');
        this.checkRequired(dto.dateOfBirth, erroredTabs, 'main');

        return erroredTabs;
    }
}
export const UserEditValidator = new EditValidator();
