import React, { useContext, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from '../../../context/user-context';
import EventDto from '../../../eenApi/event/dto/event.dto';
import { EventService } from '../../../eenApi/event/service';
import { formatDate } from '../../../utils/date.utils';
import { EventRoutesPath } from '../routes';
import useTranslation from '../../../hooks/translation.hook';
import EventStatusEnum from '../../../eenApi/event/enum/event.status.enum';
import LanguageEnum from '../../../enums/language.enum';
import { TableColumn, TableRowAction } from '../../../component/common/layout/DataTable/table.model';
import { DataTable } from '../../../component/common/layout';
import { EntityQueryKey } from '../../../query/query.keys';
import { useGroupDeleteAction, useIdColumn, useRowActions } from '../../../component/common/layout/DataTable/table.utils';
import { GridSelectionModel } from '@mui/x-data-grid';
import Rules from '../../../rules';
import { useCurrentUser } from '../../../hooks/useCurrentUser';

interface IProps {
    filterValues?: { [key: string]: any };
    validation: boolean;
}

export function EventTable(props: IProps) {
    const { checkAccess } = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const currentUser = useCurrentUser();
    const idColumn = useIdColumn<EventDto>();

    const columns = useMemo<TableColumn<EventDto>[]>(
        () => [
            idColumn,
            new TableColumn({
                headerName: t('event:fields.title'),
                field: 'title',
                sortable: true,
                renderCell: ({ id, value }) => checkAccess(Rules.Event.View) ? <Link to={EventService.getViewLink(String(id))}>{value}</Link> : value,
            }),
            new TableColumn({
                headerName: t('event:fields.type.title'),
                field: 'type',
                valueFormatter: ({ value }) => t(`event:fields.type.${value}`),
            }),
            new TableColumn({
                headerName: t('event:fields.startDate'),
                field: 'startDate',
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('event:fields.endDate'),
                field: 'endDate',
                valueFormatter: ({ value }) => formatDate(value),
            }),
            new TableColumn({
                headerName: t('event:fields.country'),
                field: 'country',
                renderCell: (params) =>
                    params.row.memberCountry?.name
            }),
        ],
        [i18n.language],
    );

    const clickDelete = (ids: GridSelectionModel) => EventService.remove(ids.map((x) => Number(x)));
    const clickStatusChange = (id: number, language: LanguageEnum, status: EventStatusEnum) => EventService.status(id, language, status);

    const rowActions = useRowActions<EventDto>({
        edit: (row) => (checkAccess(Rules.Event.Edit) || (checkAccess(Rules.Event.EditActive) && currentUser.data?.user?.id === row?.createdBy?.id)) && row
            ? EventRoutesPath.Edit.replace(':id', String(row.id)) : '',
        hideEdit: row => !checkAccess(Rules.Event.Edit) && !(currentUser.data?.user?.id === row?.createdBy?.id && checkAccess(Rules.Event.EditActive)),

        remove: checkAccess(Rules.Event.Delete) ? (row) => clickDelete([row.id!]) : null,

    },
        useMemo<TableRowAction<EventDto>[]>(() => {
            const items: TableRowAction<EventDto>[] = [];
            if (checkAccess(Rules.Event.EditReview)) {
                items.push({
                    id: 'send',
                    label: t('main:btn-sendValidation'),
                    action: (item: EventDto) => clickStatusChange(item.id!, item.language, EventStatusEnum.Validation),
                    isHide: (item: EventDto) => item.status !== EventStatusEnum.Draft && item.status !== EventStatusEnum.CommentsReceived,
                });
            }
            if (props.validation && checkAccess(Rules.Event.Validate)) {
                items.push({
                    id: 'remarks',
                    label: t('main:btn-remarks'),
                    action: (item: EventDto) => clickStatusChange(item.id!, item.language, EventStatusEnum.CommentsReceived),
                    isHide: (item: EventDto) => item.status !== EventStatusEnum.Validation,
                });
                items.push({
                    id: 'publish',
                    label: t('main:btn-publish'),
                    action: (item: EventDto) => clickStatusChange(item.id!, item.language, EventStatusEnum.Active),
                    isHide: (item: EventDto) => item.status !== EventStatusEnum.Validation,
                });
            }
            return items;
        }, [props.validation, i18n.language]),
        [props.validation],
    );

    const groupActions = useGroupDeleteAction({
        remove: checkAccess(Rules.Event.Delete) ? clickDelete : null,
    });
    return (
        <DataTable
            id={(props.validation) ? "event-validation-component" : "event-table-component"}
            queryKey={[EntityQueryKey.Event, props.validation]}
            filterValues={props.filterValues}
            columns={columns}
            fetchData={(props.validation) ? (data) => EventService.listValidation(data) : (data) => EventService.list(data)}
            rowActions={rowActions}
            groupActions={groupActions}
        />
    );
}
