import { useMemo, useState } from 'react';
import Filter from '../../../component/filter';
import FilterItemAsyncSelect from '../../../component/filter/types/filterItem/items/asyncSelect';
import FilterItemDateRange from '../../../component/filter/types/filterItem/items/date';
import FilterItemSelect from '../../../component/filter/types/filterItem/items/select';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { UserService } from '../../../eenApi/user/service';
import { useOptions } from '../../../hooks/select.options.hook';
import useTranslation from '../../../hooks/translation.hook';
import { ChatTable } from './table';
import ChatEntityEnum from '../../../eenApi/chat/enum/chat.objecttype.enum';

export function ChatList() {
    const { t, i18n } = useTranslation();
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();

    const typeOptions = useOptions<ChatEntityEnum>(ChatEntityEnum, 'chat:entity.');

    const filterItems = useMemo(() => {
        const items = [
            new FilterItemSelect('objectType', t('chat:entity.title'), {
                isDefault: 'Y',
                options: typeOptions,
            }),
            new FilterItemDateRange('created', t('chat:filter.created'), { isDefault: 'Y' }),
            new FilterItemAsyncSelect('createdBy', t('main:field-full-name'), {
                isDefault: 'Y',
                loadOptions: UserService.selectUserOptions
            }),
        ];
        return items;
    }, [i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`chat:list.title`)} backLink="/" />
            <div className="d-xl-flex mb-1 mb-xl-3">
                <div className="app-filter-actions mb-2 mb-xl-0">
                </div>
                <Filter id="filter-chat" items={filterItems} onFind={setFilterValues} query={{}} />
            </div>
            <ChatTable filterValues={filterValues} />
        </MainContainer>
    );
}
