import { AddToPhotos, Send, Clear } from "@mui/icons-material";
import { Box, Button, IconButton, InputBase } from "@mui/material";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import ViewField from "../../fields/viewField";
import FileUploader from "../../fileUploader";
import DriveFileDto from "../../../eenApi/drive/dto/drive.file.dto";
import FileList from "../../fields/fileList";
import { ChatService } from "../../../eenApi/chat/service";
import { ToastManager } from "../../../service/toastManager";
import useTranslation from "../../../hooks/translation.hook";

interface CommentFormProps {
    objectId: number;
    objectType: string;
    parentId?: number;
    handleCloseForm?: () => void;
}

const CommentForm: FC<CommentFormProps> = ({ parentId = null, objectId, objectType, handleCloseForm }) => {
    const [comment, setComment] = useState("");
    const [file, setFile] = useState<DriveFileDto | DriveFileDto[] | null>(null);
    const [fileIds, setFileIds] = useState<string[]>([]);
    const [isOpenAdd, setIsOpenAdd] = useState(false);
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const createCommentMutation = useMutation(async (comment: string) => {
        await ChatService.addComment(parentId, objectId, objectType, comment, fileIds);
    }, {
        onSuccess: () => {
            queryClient.invalidateQueries(["chat", objectType, objectId]);
            setComment("");
            setFile(null);
            handleCloseForm ? handleCloseForm() : handleCancelEditComment();
            ToastManager.success({ title: 'main:msg-success', message: 'main:msg-comment-success-add' });
        },
        onError: (error: Error) => {
            ToastManager.error({ title: 'main:msg-error', message: 'main:unhandled-exception-error-message' });
        }
    });

    const handleToggleAddFile = () => {
        setIsOpenAdd(!isOpenAdd);
    };

    const handleFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        createCommentMutation.mutate(comment);
    };

    const handleCancelEditComment = () => {
        setComment("");
        setFile(null);
        setFileIds([]);
        setIsOpenAdd(false);
    }

    const onUploaded = (file: DriveFileDto) => {
        setFile([file]);
        setFileIds([file.id]);
    };

    const onRemoveFile = () => {
        setFile(null);
    };

    return (
        <Box className="chat-commentInput">
            <form onSubmit={handleFormSubmit}>
                <InputBase
                    placeholder={t(`chat:fields.addComment`)}
                    value={comment}
                    onChange={(event) => setComment(event.target.value)}
                    fullWidth
                    multiline
                />
                <IconButton className="chat-commentSpoiler" onClick={handleToggleAddFile}>
                    <AddToPhotos />
                </IconButton>
                {
                    isOpenAdd && (
                        <Box className="chat-commentUpload">
                            <ViewField title={t(`chat:fields.addFiles`)}>
                                <FileList
                                    files={Array.isArray(file) ? file : file ? [file] : []}
                                    onRemove={onRemoveFile}
                                />
                            </ViewField>
                            <FileUploader onFileUploaded={onUploaded} />
                        </Box>)
                }
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={!comment}
                >
                    <Send />
                </Button>
                <IconButton onClick={handleCloseForm ? handleCloseForm : handleCancelEditComment}>
                    <Clear />
                </IconButton>
            </form>
        </Box>
    );
};

export default CommentForm;
