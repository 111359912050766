import TabPanel from '../../tabs/tabPanel';
import { AutocompleteControl, InputControl, SelectControl } from '../../common/ui';
import IEditPanelProps from '../edit.panel.props';
import { useOptions } from '../../../hooks/select.options.hook';
import EventTypeEnum from '../../../eenApi/event/enum/event.type.enum';
import { DictionaryType } from '../../../models/dictionaryType';
import { UserService } from '../../../eenApi/user/service';
import { Length, NotEmpty } from '../../../hooks/UseValidation/validators';

export default function MainEditTab({ data, entity, updateData, ...rest }: IEditPanelProps) {
    const eventTypeOptions = useOptions(EventTypeEnum, 'event:fields.type.');

    return (
        <TabPanel {...rest}>
            <InputControl labelKey={'event:fields.title'} onChange={(data) => updateData('title', data)}
                value={data.title} tab="main" multiline required validators={[NotEmpty, Length(100)]} />
            <SelectControl
                labelKey="event:fields.type.title"
                value={data.type}
                onChange={(value) => {
                    updateData('type', value);
                }}
                options={eventTypeOptions}
                required
                tab={'main'}
                validators={[NotEmpty, Length(100)]}
            />
            <InputControl
                labelKey={'event:fields.deadlineRegistration'}
                onChange={(data) => updateData('deadlineRegistration', data)}
                value={data.deadlineRegistration}
                type="datetime-local"
                tab="main"
                focused={true}
                color={'grey'}
            />
            <InputControl
                labelKey={'event:fields.startDate'}
                onChange={(data) => updateData('startDate', data)}
                value={data.startDate}
                tab="main"
                type="datetime-local"
                required
                validators={[NotEmpty]}
                focused={true}
                color={'grey'}
            />
            <InputControl
                labelKey={'event:fields.endDate'}
                onChange={(data) => updateData('endDate', data)}
                value={data.endDate}
                tab="main"
                type="datetime-local"
                required
                validators={[NotEmpty]}
                focused={true}
                color={'grey'}
            />
            <InputControl
                labelKey={'event:fields.closedDate'}
                onChange={(data) => updateData('closedDate', data)}
                value={data.closedDate}
                tab="main"
                type="datetime-local"
                required
                validators={[NotEmpty]}
                focused={true}
                color={'grey'}
            />

            <InputControl
                labelKey={'event:fields.description'}
                onChange={(data) => updateData('description', data)}
                value={data.description}
                tab="main"
                multiline
                required
                validators={[NotEmpty, Length(255)]}
            />
            <InputControl
                labelKey={'event:fields.organizationHosting'}
                onChange={(data) => updateData('organizationHosting', data)}
                value={data.organizationHosting}
                tab="main"
                required
                validators={[NotEmpty, Length(100)]}
            />
            <InputControl
                labelKey={'event:fields.coOrganisers'}
                onChange={(data) => updateData('coOrganisers', data)}
                value={data.coOrganisers}
                tab="main"
                validators={[Length(100)]}
            />
            <InputControl
                labelKey={'event:fields.otherPartners'}
                onChange={(data) => updateData('otherPartners', data)}
                value={data.otherPartners}
                tab="main"
                validators={[Length(100)]}
            />
            <AutocompleteControl
                labelKey="event:fields.contactUser"
                dictionary={DictionaryType.User}
                onChange={(value) => {
                    updateData('contactUserId', value?.value || null);
                }}
                options={(term, language, skip) => UserService.selectLoadUserOptions(term, language, skip)}
                value={data.contactUserId}
                required
            />
            <InputControl
                labelKey={'event:fields.website'}
                onChange={(data) => updateData('website', data)}
                value={data.website}
                tab="main"
                validators={[Length(100)]}
            />
        </TabPanel>
    );
}
