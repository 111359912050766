import { Pagination } from '@mui/material';
import {
    GridPagination,
    gridPaginationSelector,
    useGridApiContext,
    useGridSelector,
} from '@mui/x-data-grid';

export function TablePagination() {
    const apiRef = useGridApiContext();
    const pagination = useGridSelector(apiRef, gridPaginationSelector);

    return (
        <>
          {pagination.pageCount > 1 && <Pagination
                color="primary"
                count={pagination.pageCount}
                page={pagination.page + 1}
                onChange={(event, value) => apiRef.current.setPage(value - 1)}
                className="table-pagination"
                siblingCount={1} boundaryCount={1}
            />}
            <GridPagination
                count={pagination.rowCount}
                onPageChange={(event, value) => apiRef.current.setPage(value)}
                page={pagination.page}
                rowsPerPage={pagination.pageSize}
                labelDisplayedRows={() => ''}
                className="ms-auto"
                nextIconButtonProps={{
                  hidden: true
                }}
                backIconButtonProps={{
                  hidden: true
                }}
                sx={{
                  '& .MuiInputBase-root': { marginRight: 0 },
                  '& .MuiTablePagination-actions': { marginLeft: 0 },
                }}
            />
        </>
    );
}
