import React, { Suspense, ReactNode } from 'react';
import Loader from '../../loader';

interface UserCompWrapperProps {
    activeId: string;
    id: string;
    children: ReactNode;
}

const UserCompWrapper: React.FC<UserCompWrapperProps> = ({
    activeId,
    id,
    children,
}) => {
    if (activeId !== id) return null;

    return (
        <Suspense fallback={<Loader />}>
            {React.Children.map(children, (child: ReactNode) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child);
                }
                return child;
            })}
        </Suspense>
    );
};

export default UserCompWrapper;
