import React, { useContext, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { UserContext } from '../../../context/user-context';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import Rules from '../../../rules';
import { useHistory, useLocation } from "react-router-dom";
import { ProfileTable } from './table';
import FilterItemText from '../../../component/filter/types/filterItem/items/text';
import FilterItemAsyncSelect from '../../../component/filter/types/filterItem/items/asyncSelect';
import Filter from '../../../component/filter';
import FilterItemSelect from '../../../component/filter/types/filterItem/items/select';
import ProfileStatusEnum from '../../../eenApi/profile/enum/profile.status.enum';
import { ClientService } from '../../../eenApi/client/service';
import ProfileTypeEnum from '../../../eenApi/profile/enum/profile.type.enum';
import { useOptions } from '../../../hooks/select.options.hook';
import { ProfileRoutesPath } from '../routes';
import { useParams } from 'react-router';
import { BackButton } from '../../../component/common/ui';
import { CenterService } from '../../../eenApi/center/service';
import { MemberCountryService } from "../../../eenApi/membercountry/member.country.service";
import { RequestDataValue } from "../../../component/filter/types/requestData";
import { InnovationProjectService } from "../../../eenApi/innovationproject/service";
import { useBoolFilter } from "../../../hooks/UseBoolFilter";

interface IProps {
    validation?: boolean,
    prefix?: string
}

export function ProfileList({ validation = false, prefix }: IProps) {
    const { t, i18n } = useTranslation();
    const { checkAccess } = useContext(UserContext);
    const [filterValues, setFilterValues] = useState<{ [key: string]: any }>();
    const { centerId } = useParams<{ centerId?: string }>();
    const history = useHistory();
    const { state } = useLocation<{ query: Record<string, RequestDataValue[]> }>();

    const statusOptions = useOptions<ProfileStatusEnum>(ProfileStatusEnum, 'profile:status.')
        .filter(x => x.value === ProfileStatusEnum.Validation || x.value === ProfileStatusEnum.CommentsReceived);
    const typeOptions = useOptions<ProfileTypeEnum>(ProfileTypeEnum, 'profile:type.');
    const viewedFilter = useBoolFilter('isNotViewed', t('main:field.notViewed'))

    const filterItems = useMemo(() => {
        const items = [
            new FilterItemText('title', t('profile:fields.title'), { isDefault: "Y" }),
            new FilterItemText('id', t('profile:fields.id'), { isDefault: "Y" }),
            new FilterItemAsyncSelect('clientId', t('profile:fields.clientId'), { isDefault: "Y", loadOptions: ClientService.selectLoadOptions }),
            new FilterItemSelect('type', t('profile:fields.profileType'), { isDefault: "Y", options: typeOptions }),
            new FilterItemAsyncSelect('memberCountryId', t('profile:fields.memberCountryId'), { isDefault: "Y", loadOptions: MemberCountryService.selectLoadOptions }),
            new FilterItemAsyncSelect('innovationProjectId', t('profile:fields.innovationProjectId'), { isDefault: "Y", loadOptions: InnovationProjectService.selectLoadOptions }),
        ];
        if (validation) {
            items.push(
                new FilterItemSelect('status', t('profile:fields.status'), { options: statusOptions, isDefault: "Y" }),
            )
            items.push(viewedFilter)
        }
        return items
    }, [validation, i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t(`profile:title.list.${prefix}`)} backLink='/' />
            <div className='d-xl-flex mb-1 mb-xl-3'>
                <div className='app-filter-actions mb-2 mb-xl-0' style={{ whiteSpace: 'nowrap' }}>
                    {centerId && <BackButton to={CenterService.getDetailsRoute(centerId)}/>}
                    {checkAccess(Rules.Profile.Add) &&
                        <button className='btn btn-success d-none d-sm-inline-block me-2' onClick={() => history.push(ProfileRoutesPath.Create)}>
                            {t('main:btn-add')}
                        </button>
                    }
                </div>
                <Filter id={`filter-${prefix}-profile`}
                        items={filterItems}
                        onFind={setFilterValues}
                        query={state?.query}
                />
            </div>
            <ProfileTable prefix={prefix} validation={validation} filterValues={filterValues}/>
        </MainContainer>
    );
}