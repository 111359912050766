import React, { FC, Suspense, useContext, useMemo, useState } from 'react';
import useTranslation from '../../../hooks/translation.hook';
import { useHistory, useParams } from 'react-router-dom';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import Loader from '../../../component/loader';
import MainContainer from '../../../component/mainContainer';
import PageTitle from '../../../component/pageTitle';
import { ToastManager } from '../../../service/toastManager';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TopActions from '../../../component/actions/topActions';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import ValidationContext from '../../../hooks/UseValidation/validation.context';
import { useCurrentUser } from '../../../hooks/useCurrentUser';
import TabItem from '../../../component/tabs/tabItem';
import TabPanel from '../../../component/tabs/tabPanel';
import EventDto from '../../../eenApi/event/dto/event.dto';
import { isRoutePathCreate } from '../../../utils/check.route.path';
import { EventRoutesPath } from '../routes';
import EventEditValidator from '../../../utils/validators/event/edit.validator';
import { EventService } from '../../../eenApi/event/service';
import MainEditTab from '../../../component/event/MainEditTab';
import LocationEditTab from '../../../component/event/LocationEditTab';
import { UserContext } from '../../../context/user-context';
import { ActionItem } from '../../../models/actionItem';
import Rules from '../../../rules';
import { DeleteModal } from '../../../component/common/ui';

const EditEvent: FC = () => {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const queryClient = useQueryClient();
    const { id } = useParams<{ id?: string }>();
    const currentUser = useCurrentUser();

    const [validateOn, setValidateOn] = useState(false);
    const [tabs, setTabs] = useState<string[]>([]);
    const { checkAccess } = useContext(UserContext);

    const { data: entity, isFetching } = useQuery<EventDto>(['event', id, currentUser.data!.language], {
        onSuccess: async (data) => {
            setData(new EventDto(data, currentUser.data!.language));
            setActiveTab('main');
        },
        enabled: !!id,
    });

    const [data, setData] = useState<EventDto>(new EventDto(entity, currentUser.data!.language));
    const [activeTab, setActiveTab] = useState(!id || data?.id ? 'main' : 'loading');

    const tabItems = useMemo(() => {
        return [new TabItem('main', t('event:edit.main')), new TabItem('location', t('event:edit.location'))];
    }, [i18n.language]);

    const deleteMutation = useMutation((_: string) => EventService.remove(id!), {
        onSuccess: async () => {
            ToastManager.success({
                title: 'main:msg-success',
                message: 'main:msg-entity-deleted',
            });
            await queryClient.removeQueries(['event', id]);
            await queryClient.resetQueries({
                predicate: (query) => query.queryKey.includes('event'),
            });
            history.push(EventRoutesPath.List);
        },
        onSettled: (data, error, tab) => {
            if (error) {
                setActiveTab(tab);
            }
        },
    });

    const createPath = EventRoutesPath.Add;

    const updateMutation = useMutation((_: string) => EventService.save({
        ...data!,
        mapFiles: data.mapFileIds as any
    }), {
        onSuccess: async (result, tab) => {
            ToastManager.success({
                title: 'main:msg-success',
                message: 'main:msg-changes-saved',
            });
            data.id = result;
            await queryClient.resetQueries({
                predicate: (query) => query.queryKey.includes('event'),
            });
            history.replace(EventRoutesPath.View.replace(':id', result));
            setActiveTab(tab);
        },
        onSettled: (data, error, tab) => {
            if (error) {
                setActiveTab(tab);
            }
        },
    });

    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const closeDelete = () => setIsDeleteOpen(false);
    const remove = () => {
        closeDelete();
        window.scrollTo(0, 0);
        deleteMutation.mutate(activeTab);
        setActiveTab('loading');
    };

    const save = (stay: boolean) => {
        setValidateOn(true);
        window.scrollTo(0, 0);
        const erroredTabs = EventEditValidator.validate(data!, tabs, entity!);
        setTabs(erroredTabs);

        if (erroredTabs.length === 0) {
            updateMutation.mutate(activeTab);
            setActiveTab('loading');
        }
    };
    const actions = (() => {
        const items: ActionItem[] = [

            {
                name: t('main:btn-save'),
                onClick: () => save(false),
            },
            {
                name: t('main:btn-apply'),
                onClick: () => save(true),
            },
            {
                name: t('main:btn-cancel'),
                onClick: async () => {
                    if (isRoutePathCreate(history.location.pathname, createPath)) {
                        await queryClient.invalidateQueries(['event']);
                        history.goBack();
                    } else {
                        await queryClient.invalidateQueries(['event', id]);
                        history.push(EventRoutesPath.View.replace(':id', id!));
                    }
                },
                color: '#8E9FA7',
            },

        ];

        if (id && checkAccess(Rules.Event.Delete)) {
            items.push({
                name: t('main:btn-delete'),
                onClick: async () => setIsDeleteOpen(true),
            });
        }

        return items;
    })();

    const updateData = (field: keyof EventDto, value: any) => {
        let newValue = value?.value ?? value;
        setData((prev) => ({
            ...prev,
            [field]: Array.isArray(newValue) ? [...newValue] : newValue,
        }));
    };

    return (
        <MainContainer>
            <PageTitle text={t(data.id ? 'event:edit.title' : 'event:create.title')} backLink={EventRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <ValidationContext.Provider value={{ validateOn, tabs, setTabs }}>
                <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab} errored={validateOn ? tabs : []} />
                <TabPanel activeId={activeTab} id="loading" />
                {data?.language && isFetching ? (
                    <Loader />
                ) : (
                    <>
                        <MainEditTab activeId={activeTab} id="main" entity={entity} data={data} updateData={updateData} />
                        <Suspense fallback={<Loader />}>
                            <LocationEditTab activeId={activeTab} id="location" entity={entity} data={data} updateData={updateData} />
                        </Suspense>
                        <BottomActionsContainer>
                            <BottomActions actions={actions} />
                        </BottomActionsContainer>
                    </>
                )}
            </ValidationContext.Provider>
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove} />
        </MainContainer>
    );
};

export default EditEvent;
