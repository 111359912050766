import PageTitle from '../../../component/pageTitle';
import TopActionsContainer from '../../../component/actions/topActionsContainer';
import TopActions from '../../../component/actions/topActions';
import React, { Suspense, useContext, useMemo, useState } from "react";
import MainContainer from '../../../component/mainContainer';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useQuery } from 'react-query';
import TabItem from '../../../component/tabs/tabItem';
import BottomActions from '../../../component/actions/bottomActions';
import BottomActionsContainer from '../../../component/actions/bottomActionsContainer';
import TabControls from '../../../component/tabs/tabControls';
import { SpecialistService } from '../../../eenApi/specialist/service';
import Loader from '../../../component/loader';
import useTranslation from '../../../hooks/translation.hook';
import { SpecialistDto } from '../../../eenApi/specialist/dto/specialist.dto';
import { SpecialistRoutesPath } from '../routes';
import { ActionItem } from '../../../models/actionItem';
import { UserContext } from '../../../context/user-context';
import Rules from '../../../rules';
import MainDetailsTab from '../../../component/specialist/MainDetailsTab';
import { DictionaryService } from '../../../eenApi/dictionary/service';
import { DictionaryType } from '../../../models/dictionaryType';
import { SpecialistStatusEnum } from '../../../eenApi/specialist/enum/specialis.status.enum';
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { useChangeStatusMutation } from "../../../hooks/UseChangeStatusMutation";
import { useDeleteModal } from "../../../hooks/UseDeleteModal";
import { CenterService } from "../../../eenApi/center/service";
import { CenterRoutesPath } from "../../center/routes";
import { DeleteModal } from "../../../component/common/ui";
import { LoaderTab } from "../../../component/common/layout";


const ContactsDetailsTab = React.lazy(() => import('../../../component/specialist/ContactsDetailsTab'));
const StatusTab = React.lazy(() => import('../../../component/specialist/StatusTab'));

export default function SpecialistDetails() {
    const {
        isDeleteOpen, closeDelete,
        remove,
        deleteButton
    } = useDeleteModal({ queryKey: 'center', handler: id => CenterService.remove(id), successPath: CenterRoutesPath.List });

    const { checkAccess, checkAccessUser } = useContext(UserContext);
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const { id } = useParams<{ id: string }>();
    const { data: currentUser } = useCurrentUser();

    const { data: entity, isFetching } = useQuery<SpecialistDto>(['specialist', id, i18n.language], {
        onSuccess: async (data) => {
            if (data.languageSkills?.length) {
                data.languageSkillsEntities =
                    await DictionaryService.getByCodes(DictionaryType.Language, i18n.language, data.languageSkills);
            }
            setActiveTab(tabItems[0]?.id);
        }
    });
    const [activeTab, setActiveTab] = useState('loading');
    const tabItems = useMemo(() => {
        const items: TabItem[] = []
        if (checkAccessUser(entity?.createdBy, Rules.Specialist,'ViewBasic')) {
            items.push(new TabItem('main', t('specialist:tabs.main')))
        }
        if (checkAccessUser(entity?.createdBy, Rules.Specialist, 'ViewContacts')) {
            items.push(new TabItem('contacts', t('specialist:tabs.contacts')))
        }
        if (entity?.statusHistory?.length && checkAccessUser(entity?.createdBy, Rules.Specialist, 'ViewValidations')) {
            items.push(new TabItem('status', t('specialist:tabs.validateJournal')))
        }

        if (!isFetching) {
            setActiveTab(items[0]?.id)
        }

        return items;
    }, [i18n.language, entity?.createdBy, entity?.statusHistory]);

    const changeStatusMutation = useChangeStatusMutation<SpecialistStatusEnum>(
      status => SpecialistService.updateStatus(entity!.id!, status), 'specialist');

    const actions = useMemo(() => {
        const items: ActionItem[] = [];
        if (checkAccessUser(entity?.createdBy, Rules.Specialist, 'Edit')) {
            items.push({
                name: t('main:btn-edit'), onClick: async () => {
                    history.push(SpecialistRoutesPath.Edit.replace(':id', id));
                },
            });
        }

        if ((entity?.status === SpecialistStatusEnum.Draft || entity?.status === SpecialistStatusEnum.CommentsReceived)
          && (checkAccess(Rules.Specialist.Status) || currentUser?.user?.userId === entity?.createdBy)) {
            items.push({
                name: t('main:btn-sendValidation'), onClick: async () => changeStatusMutation.mutate(SpecialistStatusEnum.Validation),
            });
        }

        if (checkAccess(Rules.Specialist.Status) && entity?.status === SpecialistStatusEnum.Validation) {
            items.push({
                name: t('main:btn-remarks'),
                onClick: async () => changeStatusMutation.mutate(SpecialistStatusEnum.CommentsReceived),
            });
            items.push({
                name: t('main:btn-active'),
                onClick: async () => changeStatusMutation.mutate(SpecialistStatusEnum.Active),
            });
        }

        if (checkAccessUser(entity?.createdBy, Rules.Specialist, 'Delete')) {
            items.push(deleteButton);
        }

        return items
    }, [id, entity?.status, i18n.language]);

    return (
        <MainContainer>
            <PageTitle text={t('specialist:title.details')} backLink={SpecialistRoutesPath.List} />
            <TopActionsContainer>
                <TopActions actions={actions} />
            </TopActionsContainer>
            <TabControls selectedId={activeTab} items={tabItems} onChange={setActiveTab}/>
            <LoaderTab activeId={activeTab}/>
            {entity?.id && <>
                <MainDetailsTab activeId={activeTab} id='main' entity={entity}/>
                <Suspense fallback={<Loader />}>
                    <ContactsDetailsTab activeId={activeTab} id='contacts' entity={entity}/>
                    <StatusTab activeId={activeTab} id='status' entity={entity}/>
                </Suspense>
                <BottomActionsContainer>
                    <BottomActions actions={actions} />
                </BottomActionsContainer>
            </>}
            <DeleteModal isDeleteOpen={isDeleteOpen} close={closeDelete} remove={remove}/>
        </MainContainer>
    );
}