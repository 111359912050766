export enum DictionaryType {
    AnnualTurnover = 'annualturnover',
    CertificationStandart = 'certificationstandart',
    ClientType = 'clienttype',
    Country = 'country',
    Language = 'language',
    MarketCode = 'marketcode',
    NaceCode = 'nacecode',
    TechCode = 'techcode',
    SubjectRF = 'subjectrf',
    FederalDistrict = 'federaldistrict',
    ProfileOrigin = 'profileorigin',
    SectoralGroup = 'sectoralgroup',
    FrameworkProgramme = 'frameworkprogramme',
    Client = 'client',
    MemberCountry = 'membercountry',
    KeyWords = 'keyword',
    InnovationProject = 'innovationproject',
    Center = 'center',
    Profile = 'profile',
    NotificationType = 'notificationtype',
    Role = 'role',
    User = "user",
    ExternalProvider = 'externalprovider'
}
