import TabPanel from '../../tabs/tabPanel';
import { Field, LinkField } from '../../common/layout';
import IDetailsPanelProps from '../details.panel.props';
import { formatDate } from '../../../utils/date.utils';
import useTranslation from '../../../hooks/translation.hook';
import EventStatusEnum from '../../../eenApi/event/enum/event.status.enum';
import { UserContext } from '../../../context/user-context';
import { ObjectType } from '../../../enums/objectType';
import { useContext } from 'react';
import Rules from '../../../rules';

export default function MainDetailsTab({ entity, ...rest }: IDetailsPanelProps) {
    const { checkAccess } = useContext(UserContext);
    const canEditValidation = EventStatusEnum.Validation === entity.status && checkAccess(Rules.Event.Validate);
    const isValidate =
        canEditValidation ||
        (checkAccess(Rules.Event.Validate) && (EventStatusEnum.Validation === entity.status || EventStatusEnum.CommentsReceived === entity.status));

    const { t } = useTranslation();

    return (
        <TabPanel {...rest}>
            <Field
                labelKey="event:fields.title"
                optional
                isValidate={isValidate}
                fieldName="title"
                objectType={ObjectType.Event}
                objectId={entity.id!}
                editable={canEditValidation}
            >
                {entity.title}
            </Field>

            <Field labelKey="event:fields.status.title">{t(`event:fields.status.${entity.status}`)}</Field>
            <Field labelKey="event:fields.type.title">{t(`event:fields.type.${entity.type}`)}</Field>
            <Field labelKey="event:fields.deadlineRegistration" optional>{formatDate(entity.deadlineRegistration)}</Field>
            <Field labelKey="event:fields.startDate" value={formatDate(entity.startDate)} />
            <Field labelKey="event:fields.endDate">{formatDate(entity.endDate)}</Field>
            <Field labelKey="event:fields.closedDate">{formatDate(entity.closedDate)}</Field>

            <Field
                labelKey="event:fields.description"
                optional
                isValidate={isValidate}
                fieldName="description"
                objectType={ObjectType.Event}
                objectId={entity.id!}
                editable={canEditValidation}
            >
                {entity.description}
            </Field>
            <Field
                labelKey="event:fields.organizationHosting"
                optional
                isValidate={isValidate}
                fieldName="organizationHosting"
                objectType={ObjectType.Event}
                objectId={entity.id!}
                editable={canEditValidation}
            >
                {entity.organizationHosting}
            </Field>
            <Field labelKey="event:fields.coOrganisers" optional>{entity.coOrganisers}</Field>
            <Field
                labelKey="event:fields.otherPartners"
                optional
                isValidate={isValidate}
                fieldName="otherPartners"
                objectType={ObjectType.Event}
                objectId={entity.id!}
                editable={canEditValidation}
            >
                {entity.otherPartners}
            </Field>
            <Field labelKey="event:fields.contactUser" optional>{entity.contactUser?.fullName}</Field>
            <LinkField labelKey="event:fields.website" optional fieldName="website"
                editable={canEditValidation}
                isValidate={isValidate}
                objectType={ObjectType.Event}
                objectId={entity.id!}
            >{entity.website}</LinkField>
        </TabPanel>
    );
}
